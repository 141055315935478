import React from "react";
import { GenerativeModel } from "../models/image_generator";
import "./image_details.css";
import { PencilIcon, IconCheckMark } from "../../../common/icons/icons";

interface ImageCardProps {
    imageModel: GenerativeModel;
    isCompactView?: boolean;
    selected?: boolean;
    imageAction?: () => void;
    icon?: React.ReactNode;
}

const ImageCard: React.FC<ImageCardProps> = ({ imageModel, isCompactView, selected, icon, imageAction }) => {
    const cardHeight = isCompactView ? "h-[460px]" : "h-[472px]";
    const borderRadius = isCompactView ? "rounded-xl" : "";
    return (
        <div className={`image-card ${cardHeight} ${borderRadius}`} onClick={imageAction}>
            <div className="image-card-inner"
                style={{
                    backgroundImage: imageModel?.thumbnail_image
                        ? `linear-gradient(180deg, rgba(20, 20, 20, 0.00) 40.22%, #141414 100%), url(${imageModel.thumbnail_image})`
                        : "none",
                    backgroundColor: imageModel?.thumbnail_image ? "transparent" : "#161618",
                }}>
                <div className={`action-icon ${selected ? "border-none" : "border-2 border-white"}`}>
                    <div className={`h-full rounded-full flex items-center justify-center ${selected ? "bg-brand-yellow" : ""}`}>
                        {icon}
                    </div>
                    {/* {forImageDetailsPage ?
                        <div className="bg-brand-yellow h-full rounded-full flex items-center justify-center">
                            <PencilIcon /></div>
                        :
                        <div
                            className={`h-full rounded-full flex items-center justify-center ${selected ? "bg-brand-yellow" : ""
                                }`}
                        >
                            {selected && <IconCheckMark />}
                        </div>} */}
                </div>
                <div className="info-block">
                    <div className="category">{imageModel.group_name}</div>
                    <div className="name">{imageModel.name}</div>
                </div>
            </div>
        </div>
    )

}

export default ImageCard;