import React, { useEffect, useState } from "react";
import Heading from "../../common/components/heading";
import { Project, useProjectAPI } from "../../api/project_api";
import { useParams } from "react-router-dom";
import Card from "../../common/components/card";
import { User } from "../../model/user";
import ProjectAssets from "./components/project_assets";
import ProjectMessages from "./components/project_messages";
import { useToast } from "../../common/components/ui/use_toast";
import ProjectActionsRightsHolder from "./components/project_actions_rights_holder";
import ProjectActionsCreator from "./components/project_actions_creator";
import { formatCurrency } from "../../lib/utils";
import { Price } from "../../model/licensable_property";
import { PROJECT_STATUS } from "../../lib/constants";
import { useAppSelector } from "../../hooks";
import { useDispatch } from "react-redux";
import { updateProjectsSubmittedForApprovalCount } from "./projectsSlice";
import { Button } from "../../common/components/ui/button";
import { ArrowLeftIcon } from "@radix-ui/react-icons";

export const projectLicensePrice = (project: Project) => {
  // duration / term_size = multiplier
  // 60 / 15 = 4
  const duration = project?.duration ? project.duration / 30 : 1;
  if (project?.licensable_properties?.length) {
    // Just one LP per project for now
    const lp = project?.licensable_properties[0];
    const prices = lp.prices;
    if (prices?.length) {
      const lpPrice = prices.find((price: Price) => (price && price.license_type === project.license_type ? 1 : 0));
      if (lpPrice) {
        return <span className="project_price">{formatCurrency(lpPrice.price * duration)}</span>;
      } else {
        const lpPrice = prices.sort((left, right) => right.price - left.price)[0];
        return <div className="project_price">{formatCurrency(lpPrice.price * duration)}+<div
          className="project-price-hint text-xs-sans text-normal capitalize">*Lowest Price</div></div>;
      }
    } else {
      return <div>TBD</div>;
    }
  }
  return <div>TBD</div>;
};

function ProjectReview({ user }: { user: User }) {
  const [loading, setLoading] = useState<boolean>(true);

  const [project, setProject] = useState<Project | null>(null);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { toast } = useToast();
  const projectApi = useProjectAPI();

  const { projectsSubmittedForApprovalCount } = useAppSelector((state: any) => state.projects);

  const updateAssetStatus = async (projectId: string, fileId: string, status: string) => {
    await projectApi
      .updateProjectFileStatus(projectId, fileId, status)
      .then((res) => {
        setProject(res)
        return String(res);
      });
  }

  useEffect(() => {
    if (id) {
      projectApi.fetchProject({ id }).then((result) => {
        setProject(result);
        setLoading(false);
      });
    }
  }, [id]);

  const handleOnUpdate = (payload: { status: any }) => {
    console.log("handleOnUpdate", payload);
    if (payload?.status) {
      setProject({ ...project, status: payload.status });
    }
    // Hacky
    dispatch(updateProjectsSubmittedForApprovalCount(projectsSubmittedForApprovalCount - 1));
  };

  const talentImage =
    project?.licensable_properties && project.licensable_properties.length > 0
      ? project.licensable_properties[0].profile_image
      : "/picture-icon.svg";
  const talentName = project?.licensable_properties ? project.licensable_properties[0].name : "Talent Name";

  if (loading || !project) return <div>Loading</div>;

  return (
    <div className="flex flex-col sm:gap-20 gap-10 text-white mx-auto max-w-6xl pt-48">
      <div className="space-y-10 lg:px-0 md:px-4 px-4">
        <Button className="gap-5" size="xs" variant="outline" href={"/project"}>
          <>
            <ArrowLeftIcon />
            Back to projects
          </>
        </Button>
        <Card>
          <div className="flex flex-grow sm:flex-row flex-col">
            <div className="space-y-6 w-full">
              <div className="flex flex-wrap items-center gap-4">
                <img className="h-[100px] w-[100px] rounded-full object-cover object-top" src={talentImage} alt="" />
                <Heading as="h3" className="m-0 font-sans not-italic font-semibold">
                  {talentName}
                </Heading>
              </div>
              <Heading as="h2">{project.name}</Heading>
              <div className="sm:pt-10 pt-4">
                <p className="font-semibold">License: {project.duration} day term</p>
                {/* {project.start_date && project.end_date && (
                  <p className="text-sm text-gray-600">
                    Start: {String(project.start_date)} Ends: {String(project.end_date)}
                  </p>
                )} */}
              </div>
            </div>
            <div className="content-center min-w-[383px] ">
              <div className="flex flex-col flex-grow gap-4 py-6">
                <ProjectActionsRightsHolder
                  project={project}
                  user={user}
                  onUpdate={(payload?: any) => handleOnUpdate(payload)}
                />
                <ProjectActionsCreator
                  project={project}
                  user={user}
                  onUpdate={(payload?: any) => handleOnUpdate(payload)}
                />
              </div>
              {project.status !== PROJECT_STATUS.REJECTED.key && (
                <div className="flex flex-col justify-center pt-10 text-center">
                  <div className="mb-2 tracking-normal text-xxxl-serif">
                    {projectLicensePrice(project)}
                  </div>
                  <div className="font-semibold">Estimated payment</div>
                </div>
              )}
            </div>
          </div>
        </Card>
        <div>
          <div className="flex flex-row justify-between mb-4">
            <Heading as="h3">Project description</Heading>
            <Button variant="outline" href={`/project/${id}/description`} target="_blank">
              See all
            </Button>
          </div>
          <Card>
            <p className="line-clamp-4">{project.description}</p>
          </Card>
        </div>
        <ProjectAssets project={project} user={user} updateAssetStatus={updateAssetStatus} />
        <ProjectMessages project={project} user={user} />
      </div>
    </div>
  );
}

export default ProjectReview;
