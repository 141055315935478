import React, { useEffect, useState } from "react";
import { Project, ProjectFile, useProjectAPI } from "../../api/project_api";
import { DialogModal } from "./ui/dialog_modal";
import { Button } from "./ui/button";
import { useFileAPI } from "../../api/file_api";
// import { useImageSize } from "react-image-size";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { VideoPlayer } from "./ui/video_player";
import { PROJECT_STATUS } from "../../lib/constants";
import { useToast } from "./ui/use_toast";

interface FileViewerProps {
  project: Project;
  file: ProjectFile;
  isOpen?: boolean;
  setIsOpen?: (open: boolean) => void;
  childrenButtonOnClick?: () => void;
  children?: any | undefined;
  showActions?: boolean;
}

export function FileViewer({
  project,
  file,
  isOpen,
  setIsOpen,
  childrenButtonOnClick,
  children,
  showActions,
}: FileViewerProps) {
  const fileApi = useFileAPI();
  const projectApi = useProjectAPI();
  const { toast } = useToast();

  const [updatingStatus, setUpdatingStatus] = useState<string>("");
  const [fileStatus, setFileStatus] = useState<string | undefined>(file.status);
  const [loading, setLoading] = useState<boolean>(true);
  const [presigndUrl, setPresignedUrl] = useState<string>("");
  const [fileViewerFile, setFileViewerFile] = useState<ProjectFile | undefined>(
    file
  );
  // const [dimensions, { loading: imageSizeLoading, error }] = useImageSize(presigndUrl);

  const getPresignedUrl = async (file: ProjectFile) => {
    const url = await fileApi.getPresignedUrl(file).then((res) => {
      return String(res);
    });
    return `${url}`;
  };

  const updateAssetStatus = async (file: ProjectFile, status: string) => {
    if (!file.project_file_id || !project.id) return;
    setUpdatingStatus(status);
    await projectApi
      .updateProjectFileStatus(project.id, file.project_file_id, status)
      .then((res) => {
        return String(res);
      });
    setUpdatingStatus("");
    setFileStatus(status);
    toast({
      title: "Asset status updated!",
      description: `The file status has been changed: ${PROJECT_STATUS.APPROVED.key === status
        ? PROJECT_STATUS.APPROVED.label
        : PROJECT_STATUS.CHANGES_REQUESTED.label
        }`,
    });
  };

  useEffect(() => {
    const fetchPresignedUrl = async () => {
      if (fileViewerFile) {
        const url = await getPresignedUrl(fileViewerFile);
          setPresignedUrl(url);
          setLoading(false);
      }
    };
    if (fileViewerFile) {
      fetchPresignedUrl();
    }
    return () => setPresignedUrl("");
  }, [fileViewerFile]);

  return (
    <div>
      <Button variant="link" className="p-0"  {...(childrenButtonOnClick ? { onClick: childrenButtonOnClick } : {})} >
        {children}
      </Button>
      <DialogModal
        onOpenChange={setIsOpen ? (open: boolean) => setIsOpen(open) : undefined}
        isOpen={isOpen}
        onClose={() => setIsOpen && setIsOpen(false)}
        className="bg-gray-900 border-none text-white p-0"
        variant="screen"
      >
        {loading ? (
          <div className="flex flex-col gap-2 items-center justify-center text-center max-auto max-w-96 pt-10">
            {/*<img*/}
            {/*  className="h-8 max-h-8 opacity-10"*/}
            {/*  src="/officialai_logo_white.svg"*/}
            {/*  alt="The Official AI"*/}
            {/*/>*/}
            <div className="text-xs text-gray-600">Loading...</div>
          </div>
        ) : (
          <div className="flex flex-col min-w-[450px]">
            {file?.file_type === "image" ? (
              <img src={presigndUrl} alt={file?.name} className="h-[600px]" />
            ) : file?.file_type === "document" ? (
                <embed className="pdf h-[600px]"
                  src={presigndUrl}
                  title={file?.name}/>
                ) : (
              <VideoPlayer url={file?.permalink} fileType={file?.file_type} />
            )}
            <div className="flex flex-row pt-8">
              {fileStatus === PROJECT_STATUS.APPROVED.key && (
                <div className="flex gap-2 w-80 items-center">
                  Asset approved{" "}
                  <CheckCircleIcon height={32} className="fill-brand-yellow" />
                </div>
              )}
              {fileStatus === PROJECT_STATUS.CHANGES_REQUESTED.key && (
                <div className="flex gap-2 w-80 items-center">
                  Asset denied{" "}
                  <XMarkIcon height={32} className="fill-brand-red" />
                </div>
              )}

              <div className="flex justify-end gap-4 w-full">
                {showActions && fileStatus && ![PROJECT_STATUS.APPROVED.key, PROJECT_STATUS.CHANGES_REQUESTED.key].includes(fileStatus) && (
                  <>
                    <Button
                      variant="outline"
                      size="lg"
                      className="w-full font-semibold"
                      onClick={() =>
                        updateAssetStatus(file, PROJECT_STATUS.APPROVED.key)
                      }
                      loading={updatingStatus === PROJECT_STATUS.APPROVED.key}
                      disabled={fileStatus === PROJECT_STATUS.APPROVED.key}
                    >
                      <div className="flex flex-row items-center w-full relative">
                        <div className="w-full pr-4">Accept asset</div>
                        <CheckCircleIcon
                          height={28}
                          className="absolute -right-5 fill-yellow-500"
                        />
                      </div>
                    </Button>
                    <Button
                      variant="outline"
                      size="lg"
                      className="w-full font-semibold"
                      onClick={() =>
                        updateAssetStatus(file, PROJECT_STATUS.CHANGES_REQUESTED.key)
                      }
                      loading={updatingStatus === PROJECT_STATUS.CHANGES_REQUESTED.key}
                      disabled={
                        fileStatus === PROJECT_STATUS.CHANGES_REQUESTED.key
                      }
                    >
                      <div className="flex justify-center items-center w-full relative">
                        <div className="w-full pr-4">Deny asset</div>
                        <XCircleIcon
                          height={28}
                          className="absolute -right-4 fill-red-500"
                        />
                      </div>
                    </Button>
                  </>
                )}
                <div>
                  <Button href={file?.permalink} variant="outline" size="icon">
                    <ArrowDownTrayIcon height={28} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </DialogModal>
    </div>
  );
}

export default FileViewer;
