import React from "react";
import { SocialIcon } from "../icons/social_icon";
import { numberToHuman } from "../../lib/utils";
import "../licensable_property.css"
interface SocialAccountProps {
  provider: string;
  followers?: number;
  usage: "card"| "profile";
}

export const SocialAccount = ({ provider, followers, usage }: SocialAccountProps) => {
  return (
    <div className={"social-follower " + usage}>
      <SocialIcon provider={provider} size={usage==="card"?"sm":"lg"} />
      <div className="follower-count">{followers ? numberToHuman(followers) : ""}</div>
    </div>
  );
};
