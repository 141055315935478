import React from "react";
import ReactPlayer from "react-player";
import circleWave from "./circleWave.gif"

interface VideoPlayerProps {
  url?: string;
  fileType?: string;
}

export const VideoPlayer = ({ url, fileType }: VideoPlayerProps) => {
  if (!url) return null;
  return (
    <div className="flex flex-col justify-center">
      {fileType === "audio" && (
        <div className="w-58 h-32 rounded-xl flex justify-center items-center">
          <img src={circleWave} className="h-40" />
        </div>
      )}
      <ReactPlayer url={url} controls={true} height={fileType === "audio" ? "100px" : "600px"} width="100%" />
    </div>
  );
};


