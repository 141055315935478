import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Project, useProjectAPI } from "../../../api/project_api";
import Heading from "../../../common/components/heading";
import { User } from "../../../model/user";
import { PROJECT_STATUS, USER_TYPES } from "../../../lib/constants";
import { Button } from "../../../common/components/ui/button";
import { DialogModal } from "../../../common/components/ui/dialog_modal";
import { useToast } from "../../../common/components/ui/use_toast";
import { Textarea } from "../../../common/components/ui/textarea";
import { CLIENT_ROUTES } from "../../../routes/client_routes";
import { BitBanner } from "../../../common/components/bit_banner";
import { CheckCircleIcon, HandRaisedIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

const ProjectActionsRightsHolder = ({ project, user, onUpdate }: { project: Project; user: User; onUpdate: any }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string | null>(null);
  const [messageText, setMessageText] = useState<string | null>(null);
  const { toast } = useToast();
  const navigate = useNavigate();
  const projectApi = useProjectAPI();

  const isRightsHolder = user.user_type === USER_TYPES.RIGHTS_HOLDER.key;
  if (!isRightsHolder) return null;

  useEffect(() => {
    if (isRightsHolder && project.status === PROJECT_STATUS.DRAFT.key) {
      navigate(CLIENT_ROUTES.PROJECTS);
    }
  }, []);

  const handleLicenseRequest = async (status: string) => {
    setSubmitting(true);
    let systemMessage = "";
    switch (status) {
      case PROJECT_STATUS.REJECTED.key:
        systemMessage = "The project license request was denied";
        break;
      case PROJECT_STATUS.CHANGES_REQUESTED.key:
        systemMessage = "Changes have been requested for this proejct";
        break;
      case PROJECT_STATUS.APPROVED.key:
        systemMessage = "Project license approved";
        break;
      default:
        break;
    }
    const res = await projectApi.updateProjectStatus(
      project,
      status,
      `${systemMessage}${messageText ? `: ${messageText}` : ""}`
    );
    if (res) {
      setMessageText("");
      setSubmitting(false);
      onUpdate({ status: status });
      toast({
        title: "Request submitted",
        description: systemMessage,
      });
      setModalOpen(false);
    }
  };

  const modalContent: any = {
    approve: {
      body: (
        <div className="space-y-6">
          <div className="mt-4">
            <BitBanner />
          </div>
          <div>
            <Heading as="h6">{project.name}</Heading>
            <Heading as="h3">Approve license request</Heading>
            <p>You are about to approve the license request from {project.created_by_user}</p>
          </div>
        </div>
      ),
      actions: [
        <Button key="cancel-button" variant="outline-negative" loading={submitting} onClick={() => setModalOpen(false)}>
          Cancel
        </Button>,
        <Button
          key="continue-button"
          variant="primary-negative"
          disabled={submitting}
          onClick={() => handleLicenseRequest(PROJECT_STATUS.APPROVED.key)}
        >
          Approve request
        </Button>,
      ],
    },
    request_changes: {
      body: (
        <div className="space-y-6">
          <div className="mt-4">
            <BitBanner />
          </div>
          <div>
            <Heading as="h6">{project.name}</Heading>
            <Heading as="h3">Request changes</Heading>
            <p>This will unlock the project and allow the creator to make changes.
              A license will not be issued until you approve.
              Please let the creator know what changes you would like to see.</p>

            <Textarea
              onChange={(e) => setMessageText(e.target.value)}
              placeholder="Let the creator know why"
              className="bg-white my-4"
            ></Textarea>
          </div>
        </div>
      ),
      actions: [
        <Button
          key="continue-button"
          variant="outline-negative"
          disabled={submitting}
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </Button>,
        <Button
          key="request-changes-button"
          variant="primary-negative"
          loading={submitting}
          onClick={() => handleLicenseRequest(PROJECT_STATUS.CHANGES_REQUESTED.key)}
        >
          Request changes
        </Button>,
      ],
    },
    deny: {
      body: (
        <div className="space-y-6">
          <div className="mt-4">
            <BitBanner />
          </div>
          <div>
            <Heading as="h6">{project.name}</Heading>
            <Heading as="h3">Are you sure you want to deny this license request?</Heading>
            <p>You have not been charged because your license is still pending</p>
            <Textarea
              onChange={(e) => setMessageText(e.target.value)}
              placeholder="Let the creator know why"
              className="bg-white my-4"
            ></Textarea>
          </div>
        </div>
      ),
      actions: [
        <Button
          key="continue-button"
          variant="outline-negative"
          size="lg"
          disabled={submitting}
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </Button>,
        <Button
          key="request-changes-button"
          variant="primary-negative"
          loading={submitting}
          onClick={() => handleLicenseRequest(PROJECT_STATUS.REJECTED.key)}
        >
          Deny request
        </Button>,
      ],
    },
  };

  return (
    <>
      {project.status === PROJECT_STATUS.REJECTED.key && <div>Project declined</div>}

      {(project.status === PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key ||
        project.status === PROJECT_STATUS.CHANGES_REQUESTED.key) && (
        <>
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              setContent("approve");
              setModalOpen(!modalOpen);
            }}
            suffix={<CheckCircleIcon height="24" className="absolute right-6 top-3.5" />}
          >
            Approve license
          </Button>
          <Button
              variant="secondary"
              size="lg"
              onClick={() => {
                setContent("request_changes");
                setModalOpen(!modalOpen);
              }}
              suffix={<CheckCircleIcon height="24" className="absolute right-6 top-3.5" />}
          >
            Request Changes
          </Button>
          <Button
            variant="outline"
            size="lg"
            onClick={() => {
              setContent("deny");
              setModalOpen(!modalOpen);
            }}
            suffix={<XCircleIcon height="24" className="absolute right-6 top-3.5" />}
          >
            Deny license
          </Button>
        </>
      )}

      {content && modalContent[content] && (
        <DialogModal
          isOpen={modalOpen}
          onOpenChange={setModalOpen}
          onClose={() => setModalOpen(false)}
          actions={modalContent[content].actions}
        >
          {modalContent[content].body}
        </DialogModal>
      )}
    </>
  );
};

export default ProjectActionsRightsHolder;
