import React from "react";
import Header from "../../common/components/heading"
import AppHeader from "../layouts/header";
import AppFooter from "../layouts/app_footer";
const Privacy = () => {
    return <div className="flex flex-col min-h-screen">
        <AppHeader />
        <main style={{maxWidth:"1440px",marginLeft:"auto",marginRight:"auto",marginTop:"90px",padding:"0px 172px 24px 172px"}}>
            <div className="text-white">
                <h1 style={{color:"white",fontFamily:"Lora",fontSize:"60px",fontStyle:"italic",fontWeight:"400",lineHeight:"100px"}}>Privacy Policy</h1>

                <p style={{color:"white",fontFamily:"Fann Grotesque Trial",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",marginTop:"2em"}}>This Privacy Policy describes how OFFICIAL TECHNOLOGIES INC. dba OFFICIAL AI (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;) collects, uses, and shares information when you use our website [www.theofficial.ai] (the &quot;Site&quot;).</p>

                <Header as="h3" style={{marginTop:"2em"}}>Information We Collect</Header>
                <ul>
                    <li>Personal Information: We may collect personal information such as your name, email address, and phone number when you voluntarily submit it through our contact forms or when you subscribe to our newsletter.</li>

                    <li>Usage Information: We may collect non-personal information about your visit to the Site, including the pages you viewed, the links you clicked, and the time you spent on each page.</li>
                </ul>

                <Header as="h3" style={{marginTop:"2em"}}>How We Use Your Information</Header>

                <p style={{color:"white",fontFamily:"Fann Grotesque Trial",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",marginTop:"2em"}}>We may use the information we collect for the following purposes:</p>
                <ul className="list-disc ml-10">
                    <li>To respond to your inquiries and provide customer support.</li>
                    <li>To send you newsletters or promotional emails.</li>
                    <li>To improve our website and enhance user experience.</li>
                </ul>
                <Header as="h3" style={{marginTop:"60px"}}>Information Sharing</Header>

                <p style={{color:"white",fontFamily:"Fann Grotesque Trial",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",marginTop:"2em"}}>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with trusted third parties who assist us in operating our website, conducting our business, or servicing you.</p>

                <Header as="h3" style={{marginTop:"60px"}}>Data Security</Header>

                <p style={{color:"white",fontFamily:"Fann Grotesque Trial",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",marginTop:"2em"}}>We take reasonable measures to protect the security of your personal information, but we cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur.</p>

                <Header as="h3" style={{marginTop:"2em"}}>Changes to This Privacy Policy</Header>

                <p style={{color:"white",fontFamily:"Fann Grotesque Trial",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",marginTop:"2em"}}>We reserve the right to update or change our Privacy Policy at any time. Any changes will be posted on this page.</p>

                <p style={{color:"white",fontFamily:"Fann Grotesque Trial",fontSize:"16px",fontStyle:"normal",fontWeight:"400",lineHeight:"24px",marginTop:"2em"}}>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@theofficial.ai">support@theofficial.ai</a>.</p>
                <Header as="h3" style={{marginTop:"60px"}}>Changes to This Privacy Policy</Header>
            </div>
        </main>
        <AppFooter/>
    </div>
}
export default Privacy;