import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../hooks/store";
import { Project } from "../../api/project_api";

export interface ProjectState {
  projects: Project[];
  projectsSubmittedForApprovalCount: number;
}

const initialState: ProjectState = {
  projects: [],
  projectsSubmittedForApprovalCount: 0,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    updateProjects: (state, action: PayloadAction<Project[]>) => {
      state.projects = action.payload;
    },
    updateProjectsSubmittedForApprovalCount: (state, action: PayloadAction<number>) => {
      state.projectsSubmittedForApprovalCount = action.payload;
    },
  },
});

export const { updateProjects, updateProjectsSubmittedForApprovalCount } = projectSlice.actions;

export const projects = (state: RootState) => state.projects;

export default projectSlice.reducer;
