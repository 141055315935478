import React, {useEffect, useState} from "react";
import AppHeader from "../../pages/layouts/header";
import {LPCommercialTerms} from "../../pages/licensable-property/components/licensable_property_commecial_terms";
import {useParams} from "react-router-dom";
import {useLicensablePropertyAPI} from "../../api/licensable_property_api";
import {LicensableProperty} from "../../model/licensable_property";

export const LicenseTerms = () => {
    const { id, licenseType } = useParams<{ id: string, licenseType: "commercial"|"personal"|"expanded" }>();
    const LPApi = useLicensablePropertyAPI();
    const [licensableProperty, setLicensableProperty] = useState<LicensableProperty | undefined>(undefined);
    useEffect(() => {
        try {
            if (id && LPApi && !licensableProperty) {
                LPApi.getPublicById(id).then((lp) => {
                    setLicensableProperty(lp);
                });
            }
        } catch (error) {
            console.log("err", error);
        }
    }, [id, LPApi, licensableProperty, setLicensableProperty]);
    return <div className="flex flex-col min-h-screen">

        <main style={{
            maxWidth: "1440px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "90px",
            padding: "0px 172px 24px 172px"
        }}>
            <div className="text-white mb-5">
                {licensableProperty && <LPCommercialTerms licensableProperty={licensableProperty} licenseType={licenseType||"commercial"}/>}
            </div>
        </main>
    </div>
}
export default LicenseTerms