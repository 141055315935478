import React, { useState } from "react";
import bit_image from "../../../../common/components/ui/bit_image.png";
import Heading from "../../../../common/components/heading";
import { Button } from "../../../../common/components/ui/button"
import { useGenerativeAPI } from "../../../../api/generative_ai_api";
import { Sparkles } from "../../../../common/icons/icons";
import loading from "../../../../common/components/ui/loading.gif";


const EditPrompt = ({ prompt, selectedModels, onSave }: { prompt: string, selectedModels: any, onSave: (newPrompt: string) => void }) => {
    // const [newPrompt, setNewPrompt] = useState<string>(prompt);
    const [isLoading, setIsLoading] = useState(false);
    const [newPrompt, setNewPrompt] = useState<string>(prompt);

    const { enhancePrompt } = useGenerativeAPI();

    // TODO use model logic for enhance text prompt; include LORA dropdown here
    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const models = selectedModels.map((model: { id: string; }) => model.id);
        const response = await enhancePrompt(newPrompt, models);
        if (response && response.enhanced) {
            setNewPrompt(response.enhanced);
        }
        setIsLoading(false);
    }

    const handleSave = () => {
        onSave(newPrompt)
    };

    return (
        <div className="flex flex-row gap-16 pt-5">
            <img className="rounded-lg" src={bit_image} />
            {/* <div className="transform scale-125">
                <BitBanner />
            </div> */}
            <div className="flex flex-col justify-center gap-7">
                {/* TODO fix heading */}
                <Heading as="h3">Edit prompt</Heading>
                <textarea className="bg-gray-100 h-[180px] w-[585px] rounded-xl p-4 focus:outline-none focus:ring-0"
                    value={newPrompt}
                    onChange={(e) => setNewPrompt(e.target.value)}
                ></textarea>
                {isLoading ? <Button className="w-40" onClick={enhanceTextPrompt} type="button" variant="primary">
                    <img className="h-4" src={loading} alt="Loading..." />
                </Button> :
                    <Button onClick={enhanceTextPrompt} type="button" className="flex gap-2 border-none w-52 hover:bg-black hover:text-white hover:fill-white" variant="primary"><><Sparkles />Rewrite prompt</></Button>}
                <Button onClick={handleSave} variant="primary-negative" className="self-start w-auto">Genererate image</Button>
            </div>
        </div>
    )
}

export default EditPrompt;