import React, {useCallback} from "react";
import WizardStep from "../../../common/components/wizard_panel";
import FileManager from "../../../common/components/ui/file_manager";
import {UseFormReturn} from "react-hook-form";
import {UploadedFile} from "../../../api/file_api";

interface ImageUploadParams {
    form: UseFormReturn;
    field: string;
    errors: any;
    aspectRatio: "tall" | "wide" | "square" | undefined;
    usage?: string;
}

export const ImageUpload = ({form, field, errors, aspectRatio, usage}: ImageUploadParams) => {
    const uploadedFiles = form.watch("files");
    const objectId = form.watch("id");
    const currentFieldValue = form.watch(field) ?? null;

    const onUploadedFilesChange = useCallback(
        (files: UploadedFile[]) => {
            if (field && files[files.length - 1]?.permalink) {
                form.setValue(field, files[files.length - 1].permalink);
            }
        },
        [form, uploadedFiles]
    );

    const onFileChange = useCallback(
        (files: File[]) => {
            if (files) {
                form.setValue("files", files);
            }
        },
        [form, uploadedFiles]
    );

    const onFileRemove = (file: any) => {
        form.setValue(
            "files",
            uploadedFiles.filter((f: UploadedFile) => f.name !== file.name)
        );
    };
    const hintText = "For best results, upload assets in a " +
        (aspectRatio === "tall" ? "3:4" : aspectRatio === "wide" ? "3:1" : "1:1") +
        " aspect ratio, at least " +
        (aspectRatio === "tall" ? "360" : aspectRatio === "wide" ? "1280" : "1024") +
        "px wide";
    return (
        <WizardStep>
            {/*<div*/}
            {/*    style={{*/}
            {/*        backgroundImage: `url(${currentFieldValue})`,*/}
            {/*        backgroundPosition: "center",*/}
            {/*        backgroundSize: "cover"*/}
            {/*    }}*/}
            {/*    className="gap-10 text-center text-brand-black items-center  rounded-xl mb-10 min-h-[80px] lg:w-[876px] bg-gray-100"*/}
            {/*>*/}
            <FileManager
                key={`${objectId}-${currentFieldValue}`}
                // uploadedFiles={uploadedFiles}
                // files={uploadedFiles}
                multiple={false}
                objectType="licensable_property"
                objectId={objectId}
                uploadOnSelect={true}
                onUploadedFilesChange={onUploadedFilesChange}
                onFileChange={onFileChange}
                onFileRemove={onFileRemove}
                aspectRatio={aspectRatio}
                hintText={hintText}
                backgroundImage={currentFieldValue}
                usage={usage}
            />
            {/*</div>*/}
        </WizardStep>
    );
};
