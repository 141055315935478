import React from "react";
import WizardStep from "../../../common/components/wizard_panel";
import { FormControl, FormField, FormItem } from "../../../common/components/ui/form";
import { Errors } from "./errors";
import ButtonCheckboxGroup from "../../../common/components/button_checkbox_group";
import { FIELDS } from "../../../lib/fields";

export const ArtistContentTopicsStep = ({ form, errors }: { form: any; errors: any }) => {
  return (
    <WizardStep>
      <FormField
        control={form.control}
        name={FIELDS.CONTENT_TOPICS.key}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <ButtonCheckboxGroup field={field} options={FIELDS.CONTENT_TOPICS.options} />
            </FormControl>
            <Errors errors={errors} />
          </FormItem>
        )}
      />
    </WizardStep>
  );
};
