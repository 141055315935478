import React, { HTMLAttributes } from "react";
import { cn } from "../../lib/utils";

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  children?: React.ReactNode;
  as?: string;
}
const Heading = ({ children, as, className, ...props }: HeadingProps) => {
  switch (as) {
    case "body":
      return (
        <div className={cn("font-serif tracking-wide italic text-sm  ", className)} {...props}>
          {children}
        </div>
      );
    case "h6":
      return (
        <h6 className={cn("font-sans tracking-wide not-italic text-sm mb-2 font-semibold ", className)} {...props}>
          {children}
        </h6>
      );
    case "h5":
      return (
        <h5 className={cn("font-serif tracking-wide not-italic text-lg mb-2 font-semibold ", className)} {...props}>
          {children}
        </h5>
      );
    case "h4":
      return (
        <h3 className={cn("font-serif tracking-wide italic text-2xl sm:text-xl mb-4 ", className)} {...props}>
          {children}
        </h3>
      );
    case "h3":
      return (
        <h3 className={cn("font-serif tracking-wide italic text-xl sm:text-2xl mb-4 ", className)} {...props}>
          {children}
        </h3>
      );
    case "h2":
      return (
        <h2
          className={cn("font-serif tracking-wide italic text-3xl mb-6 ", className)}
          {...props}
        >
          {children}
        </h2>
      );
    case "small-h1":
      return (
        <h1 className={cn("font-serif tracking-wide font-light italic text-2xl sm:text-4xl mb-10", className)}
          {...props}>{children}</h1>
      )
    case "h1":
    default:
      return (
        <h1
          className={cn("font-serif tracking-wide font-light italic text-2xl sm:text-4xl lg:text-6xl mb-10", className)}
          {...props}
        >
          {children}
        </h1>
      );
  }
};
export default Heading;
