import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import WizardGroup from "../../common/components/wizard_group";
import WizardPanel from "../../common/components/wizard_panel";
import {SubmitHandler, useForm, UseFormReturn} from "react-hook-form";
import {useProjectAPI} from "../../api/project_api";
import CardRadioGroup from "../../common/components/card_radio_group";
import {z, ZodIssue} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormField, FormItem, FormLabel} from "../../common/components/ui/form";
import Heading from "../../common/components/heading";
import LicensablePropertySearch from "../licensable-property/licensable_property_search";
import Datepicker from "../../common/components/ui/datepicker";
import FileManager from "../../common/components/ui/file_manager";
import {LicensableProperty} from "../../model/licensable_property";
import "../../common/components/ui/form.css";
import {UploadedFile} from "../../api/file_api";
import FileListing from "../../common/components/ui/file_listing";
import {v4 as uuidv4} from "uuid";
import {useToast} from "../../common/components/ui/use_toast";
import {useNavigate, useParams} from "react-router-dom";
import {DialogModal} from "../../common/components/ui/dialog_modal";
import {Button} from "../../common/components/ui/button";
import {LPCommercialTerms} from "../licensable-property/components/licensable_property_commecial_terms";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {projectWizardSlice, ProjectWizardState} from "./projectWizardSlice";
import {formatCurrency} from "../../lib/utils";
import ExpandedLicenseCheckout from "./form/expanded_license_checkout";
import CreditCardCheckout from "./form/credit_card_checkout";

export const enum ProjectWizardPanels {
    PROJECT_NAME = 0,
    PROJECT_DESCRIPTION = 1,
    TALENT = 2,
    LICENSE_TERMS = 3,
    UPLOAD_CONTENT = 4,
    PROJECT_FILES = 5,
    PAYMENT = 6,
}

export const projectSchema = z.object({
    id: z.string().optional(),
    name: z.string().min(1, "Project name is required"),
    description: z.string().min(2, "Project description is required").max(1024),
    license_type: z.enum(["personal", "commercial", "expanded"]),
    start_date: z.date(),
    duration: z.any(),
    licensable_property_id: z.string(),
    files: z.array(z.any()),
    uploadedFiles: z.array(z.any()).optional(),
    include_watermark: z.boolean().optional(),
    status: z
        .enum(["draft", "submitted_for_approval", "approved", "changes_requested", "rejected", "expired"])
        .optional(),
    billing_name: z.string().min(1, "Name is required"),
    billing_address: z.string().min(1, "Address is required"),
    billing_address2: z.string().optional(),
    billing_city: z.string().min(1, "City is required"),
    billing_state: z.string().min(1, "State is required"),
    billing_zip: z.string().min(1, "Zip is required"),
    billing_card_name: z.string().min(1, "Name is required"),
    billing_card_number: z.string().regex(/^\d{16}$/, "Card number must be 16 digits"),
    billing_card_exp: z.string().regex(/^(0[1-9]|1[0-2])\/\d{2}$/, "Expiry date must be in MM/YY format"),
    billing_card_cvv: z.string().regex(/^\d{3,4}$/, "CVV must be 3 or 4 digits"),
    action: z.enum(["save_and_exit", "SUBMITTED_FOR_APPROVAL", "save_draft"]).optional(),
});
export const LICENSE_TYPES = [
    {
        key: "personal",
        value: "personal",
        label: "Personal license",
        description: "For creating and publishing videos or podcasts on personal web channels.",
    },
    {
        key: "commercial",
        value: "commercial",
        label: "Commercial license",
        description: "For small businesses and freelancers creating web content.",
    },
    {
        key: "expanded",
        value: "expanded",
        label: "Expanded rights",
        description: "For large businesses and agencies creating web content.",
    },
];
const ProjectWizard = () => {
    const { setActivePanel, updateProject, setTalent } = projectWizardSlice.actions;
    const dispatch = useAppDispatch();
    const { activePanel, project, talent } = useAppSelector<ProjectWizardState>((state) => state.projectWizard);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [showProjectNotEditable, setShowProjectNotEditable] = useState<boolean>(false);
    const [showLicenseTerms, setShowLicenseTerms] = useState<boolean>(false);
    const [errors, setErrors] = useState<ZodIssue[]>([]);
    const form = useForm<z.infer<typeof projectSchema>>({
        resolver: zodResolver(projectSchema),
        defaultValues: project,
    });
    const allFields = form.watch();
    const formRef = useRef<HTMLFormElement>(null);
    const { saveProject, fetchProject, fetchProjectEditState } = useProjectAPI();
    const { toast } = useToast();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        const validationResult = projectSchema.safeParse(allFields);
        if (!validationResult.success) {
            setErrors((prev) => {
                if (prev.length != validationResult.error.errors.length) {
                    return validationResult.error.errors
                } else {
                    return prev;
                }
            });
        } else {
            if (errors.length > 0) {
                setErrors((prev) => {
                    if (prev.length > 0) {
                        return [];
                    } else {
                        return prev;
                    }
                });
            }
        }
    }, [allFields]);

    useEffect(() => {
        dispatch(updateProject({ ...allFields }))
    }, [activePanel]);
    const eligibleToSave = useMemo<boolean>(() => {
        return !(
            !allFields["name"] ||
            !allFields["description"] ||
            !allFields["licensable_property_id"] ||
            !allFields["license_type"] ||
            !allFields["start_date"] ||
            allFields["duration"] <= 0
        );
    }, [allFields]);
    useEffect(() => {
        if (talent) {
            form.setValue("licensable_property_id", talent?.id || "");
        }
    }, [talent])

    const onSubmit: SubmitHandler<z.infer<typeof projectSchema>> = async (data, event) => {
        event?.preventDefault();
        if (submitting) return;
        setSubmitting(true);

        const projectSubmission = {
            id: data.id || undefined,
            name: data.name,
            description: data.description,
            license_type: data.license_type,
            start_date: data.start_date,
            duration: data.duration,
            status: data.status || "draft",
            licensable_property_id: data.licensable_property_id,
            licensable_properties: [{ id: data.licensable_property_id }],
            files: data.files?.map((it) => {
                return { fileId: it.id, usage: it.usage || "project" };
            }),
            include_watermark: data.include_watermark,
            billing_name: data.billing_name,
            billing_address: data.billing_address,
            billing_address2: data.billing_address2,
            billing_city: data.billing_city,
            billing_state: data.billing_state,
            billing_zip: data.billing_zip,
            billing_card_name: data.billing_card_name,
            billing_card_number: data.billing_card_number,
            billing_card_exp: data.billing_card_exp,
            billing_card_cvv: data.billing_card_cvv,
        };
        try {
            const project = await saveProject(projectSubmission);
            form.setValue("id", project.id);
            if (data.action !== "save_draft") {
                toast({
                    title: "Success!",
                    description: "Your project has been saved.",
                });
            }
            setSubmitting(false);
            if (data.action === "save_and_exit") {
                setTimeout(() => {
                    navigate("/project");
                }, 2000);
            } else if (data.action === "SUBMITTED_FOR_APPROVAL") {
                navigate(`/project?submittedProject=${project.id}`);
            }
        } catch (e: any) {
            toast({
                title: "Error!",
                description: "There was an error saving your project: " + e.message,
            });
        } finally {
            setSubmitting(false);
        }
    };
    // const confirmSubmission = () => {
    //   setShowConfirmationDialog(true);
    // };
    const submitForApproval = useCallback(() => {
        form.setValue("status", "submitted_for_approval");
        form.setValue("action", "SUBMITTED_FOR_APPROVAL");
        const validationResult = projectSchema.safeParse(allFields);
        if (allFields.license_type !== "expanded" && !validationResult.success) {
            setErrors(validationResult.error.errors);
            return;
        }
        allFields.action = "SUBMITTED_FOR_APPROVAL";
        allFields.status = "submitted_for_approval";
        onSubmit(allFields);
    }, [allFields]);
    const panels = useMemo(() => {
        return [
            {
                name: "Project Name",
                component: <ProjectNamePanel form={form} />,
                canAdvance: !!allFields["name"],
            },
            {
                name: "Description",
                component: <ProjectDescriptionPanel form={form} />,
                canAdvance: !!allFields["description"],
            },
            {
                name: "Talent",
                component: (
                    <TalentPanel
                        form={form}
                        talent={talent}
                        onSelected={(lp: LicensableProperty) => {
                            dispatch(setTalent(lp));
                            dispatch(setActivePanel(3));
                        }}
                    />
                ),
                canAdvance: !!allFields["licensable_property_id"] || !!project.licensable_property_id,
            },
            {
                name: "License Terms",
                component: (
                    <ProjectLicenseTermPanel
                        form={form}
                        licensableProperty={talent}
                        onShowLicenseTerms={() => setShowLicenseTerms(true)}
                    />
                ),
                canAdvance: !!allFields["license_type"] && !!allFields["start_date"] && allFields["duration"] > 0,
            },
            {
                name: "Upload your content",
                component: (
                    <ProjectUploadPanel
                        form={form}
                        onFileUpload={(uploadedFiles) => {
                            const existingFiles = form.getValues("files");
                            uploadedFiles.forEach((it) => (it.usage = "project"));
                            if (existingFiles != null) {
                                form.setValue("files", [...existingFiles, ...uploadedFiles]);
                            } else {
                                form.setValue("files", uploadedFiles);
                            }
                            dispatch(setActivePanel(5));
                        }}
                    />
                ),
                canAdvance: true,
            },
            {
                name: "Project Files",
                component: <ProjectFilesPanel form={form} addMoreFiles={() => dispatch(setActivePanel(4))} />,
                canAdvance: true,
            },
            {
                name: "Payment",
                component: (
                    <PaymentPanel
                        form={form}
                        licensableProperty={talent}
                        submitForApproval={submitForApproval}
                        errors={errors}
                        submitting={submitting}
                        navigateToPanel={(panelNum: number) => dispatch(setActivePanel(panelNum))}
                    />
                ),
                canAdvance: false,
            },
        ];
    }, [form, allFields, talent, errors, activePanel]);
    const acknowledgeProjectNotEditable = () => {
        setShowProjectNotEditable(false);
        navigate(`/project/${id}`);
    };
    useEffect(() => {
        if (id) {
            fetchProject({ id }).then((project) => {
                if (
                    project.status === "submitted_for_approval" ||
                    project.status === "approved" ||
                    project.status === "rejected" ||
                    project.status === "expired"
                ) {
                    form.reset({ status: project.status });
                    setShowProjectNotEditable(true);
                    return;
                }
                fetchProjectEditState({ id }).then((projectEditState) => {
                    form.reset({
                        ...projectEditState,
                        files: project.files ? [...project.files] : [],
                    });
                }).catch((e) => {
                    form.reset({
                        ...project,
                        files: project.files ? [...project.files] : [],
                        licensable_property_id:
                            project.licensable_properties && project.licensable_properties.length > 0
                                ? project.licensable_properties[0].id
                                : undefined,
                    });
                });

                dispatch(updateProject(project));
                if (project.licensable_properties && project.licensable_properties.length > 0) {
                    dispatch(setTalent(project.licensable_properties[0]));
                }
            });
        }
    }, [id]);

    const handleStepForward = (stepIdx: number) => {
        dispatch(setActivePanel(stepIdx));
    };

    const handleStepBackward = (stepIdx: number) => {
        dispatch(setActivePanel(stepIdx));
    };
    const saveDraft = useCallback(() => {
        allFields.action = "save_draft";
        onSubmit(allFields);
    }, [allFields]);
    const saveAndExit = useCallback(() => {
        allFields.action = "save_and_exit";
        onSubmit(allFields);
    }, [allFields]);
    const handlePanelChange = useCallback(
        (stepIdx: number) => {
            if (stepIdx === 4 || stepIdx === 5) {
                saveDraft();
            }
            dispatch(setActivePanel(stepIdx));
        },
        [allFields, saveDraft]
    );

    return (
        <div className="min-h-screen flex flex-col relative wizard_page ">
            <Button
                className="z-50 absolute top-5 right-20 wizard_action_button"
                onClick={eligibleToSave ? saveAndExit : () => navigate("/project")}
                variant="primary-negative"
                loading={submitting}
            >
                <span>
                    {eligibleToSave && <span>Save &amp; exit</span>}
                    {!eligibleToSave && <span>Exit</span>}
                </span>
            </Button>

            <div className="wizard_panel_holder max-w-8xl px-4 ">
                <Form {...form}>
                    <form id="project-edit-form" ref={formRef} onSubmit={form.handleSubmit(onSubmit)}>
                        <WizardGroup
                            style={{ minHeight: 500 }}
                            panels={panels}
                            activePanel={activePanel}
                            onPanelChange={handlePanelChange}
                            next={handleStepForward}
                            previous={handleStepBackward}
                            submitting={submitting}
                        />
                    </form>
                </Form>
            </div>

            <DialogModal
                isOpen={showConfirmationDialog}
                onClose={() => setShowConfirmationDialog(false)}
                onOpenChange={setShowConfirmationDialog}
                header="Confirm Submission"
                actions={[
                    <Button key="close" onClick={() => setShowConfirmationDialog(false)} variant="outline-negative">
                        No, continue editing
                    </Button>,
                    <Button
                        key="accept"
                        onClick={() => {
                            submitForApproval();
                            setShowConfirmationDialog(false);
                        }}
                        variant="primary-negative"
                    >
                        Yes, submit project
                    </Button>,
                ]}
            >
                <div className="space-y-6">
                    <div className="mt-4">
                        <img src="/bg-filler.png" className="h-80 w-full rounded-xl" alt="header" />
                    </div>
                    <div>
                        <Heading as="h3">Confirm Submission</Heading>
                        <p>
                            Are you sure you want to submit this project to the rights holder? You will be unable to
                            make changes
                            after submission unless the rights holder rejects the project or asks for changes. Your card
                            will be
                            charged for the cost of the license upon acceptance by the rights holder.
                        </p>
                    </div>
                </div>
            </DialogModal>
            <DialogModal
                isOpen={showProjectNotEditable}
                onClose={acknowledgeProjectNotEditable}
                onOpenChange={acknowledgeProjectNotEditable}
                header="Project not editable"
                actions={[
                    <Button key="close" onClick={acknowledgeProjectNotEditable} variant="outline-negative">
                        Close
                    </Button>,
                ]}
            >
                <div className="space-y-6">
                    <div className="mt-4">
                        <img src="/bg-filler.png" className="h-80 w-full rounded-xl" alt="header" />
                    </div>
                    <div>
                        <Heading as="h3">Project locked for editing</Heading>
                        {allFields.status === "approved" && (
                            <p>This project has been approved by the rights holder and can no longer be changed</p>
                        )}
                        {allFields.status === "rejected" && (
                            <p>
                                This project has been rejected by the rights holder and can no longer be changed. Please
                                create a new
                                one.
                            </p>
                        )}
                        {allFields.status === "expired" && (
                            <p>This project has expired and can no longer be changed. Please create a new one.</p>
                        )}
                        {allFields.status === "submitted_for_approval" && (
                            <p>
                                This project has been submitted to the rights holder for approval. You may not make
                                changes to it until
                                they respond to your request.
                            </p>
                        )}
                    </div>
                </div>
            </DialogModal>
            <DialogModal
                isOpen={showLicenseTerms}
                onOpenChange={setShowLicenseTerms}
                onClose={() => setShowLicenseTerms(false)}
                header="License Terms"
            >
                <LPCommercialTerms licensableProperty={talent} licenseType={allFields.license_type} />
            </DialogModal>
        </div>
    );
};

export const PanelHeader = ({ stepName, hintText }: { stepName: string; hintText?: string }) => {
    return (
        <div style={{ marginBottom: 40 }}>
            <p className="text-m-sans">Create new project</p>
            <h3 className="text-xxxl-serif">{stepName}</h3>
            {hintText && <p className="text-s-sans text-normal mt-[20px]">{hintText}</p>}
        </div>
    );
};

const ProjectNamePanel = ({ form }: { form: UseFormReturn<z.infer<typeof projectSchema>> }) => {
    return (
        <WizardPanel>
            <PanelHeader stepName="Project Name" />
            <div className="flex items-center  py-4 mb-4 mt-[60px]">
                <input
                    {...form.register("name", { required: "Project name is required" })}
                    className="wizard-text-input"
                    type="text"
                    placeholder="Ex: Beyonce Super Bowl XXX"
                    aria-label="Project Name"
                />
            </div>
        </WizardPanel>
    );
};
const ProjectDescriptionPanel = ({ form }: { form: UseFormReturn<z.infer<typeof projectSchema>> }) => {
    return (
        <WizardPanel>
            <PanelHeader stepName="Project Description" />
            <textarea
                {...form.register("description")}
                rows={8}
                className="wizard-textarea mt-[60px]"
                placeholder={"Enter project description here ..."}
            ></textarea>
        </WizardPanel>
    );
};
const ProjectLicenseTermPanel = ({
    form,
    licensableProperty,
    onShowLicenseTerms,
}: {
    form: UseFormReturn<z.infer<typeof projectSchema>>;
    licensableProperty: LicensableProperty | null;
    onShowLicenseTerms: () => void;
}) => {
    const panelFields = form.watch(["license_type", "start_date", "duration"]);
    const licenseCost = useMemo(() => {
        if (!licensableProperty) {
            return "Pick a talent first";
        } else {
            if (!licensableProperty.prices) {
                return "Talent has not set prices yet";
            } else {
                const price = licensableProperty.prices.filter((p) => p.license_type === panelFields[0]);
                if (price.length > 0) {
                    const numPeriods = Math.ceil(panelFields[2] / 30);
                    return formatCurrency(price[0].price * numPeriods);
                } else {
                    if (licensableProperty.prices.length > 0) {
                        const highestPrice = [...licensableProperty.prices].sort((a, b) => b.price - a.price)[0];
                        const numPeriods = Math.ceil(panelFields[2] / 30);
                        return formatCurrency(highestPrice.price * numPeriods) + "+";
                    } else {
                        return "TBD";
                    }
                }
            }
        }
    }, [panelFields[0], panelFields[1], panelFields[2], licensableProperty]);

    return (
        <WizardPanel>
            <PanelHeader stepName="Project Terms" />
            <div className="project_terms_panel">
                <FormField
                    control={form.control}
                    name="start_date"
                    render={({ field }) => (
                        <FormItem className={"md:mb-6"}>
                            <FormLabel className="text-gray-900 font-sans not-italic font-semibold ">License
                                Begins</FormLabel>
                            <br />
                            <Datepicker value={field.value} onChange={field.onChange} />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="duration"
                    render={({ field }) => (
                        <FormItem className={"md:mb-6"}>
                            <FormLabel className="text-gray-900 font-sans not-italic font-semibold ">License
                                Duration</FormLabel>
                            <br />
                            <select value={field.value} onChange={field.onChange} className={"term-select"}>
                                <option value={""}>-- Choose --</option>
                                <option value="30">30 days</option>
                                <option value="60">60 days</option>
                                <option value="60">90 days</option>
                                <option value="180">180 days</option>
                                <option value="360">360 days</option>
                            </select>
                        </FormItem>
                    )}
                />
                <div className="text-gray-900 flex-grow md:text-right project_term_price">
                    <div className="font-serif italic" style={{ fontSize: "40px" }}>
                        {licenseCost}
                    </div>
                    <div>Estimated cost</div>
                </div>
            </div>

            <FormField
                control={form.control}
                name="license_type"
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <CardRadioGroup field={field} options={LICENSE_TYPES} activeOption={field.value}
                                theme="lite" />
                        </FormControl>
                    </FormItem>
                )}
            />
            {panelFields[0] === "commercial" && (
                <div className="text-gray-900 mt-10 ">
                    <Heading as={"h3"}>What its good for</Heading>
                    <ul className="list-disc text-xs ml-5 mb-10">
                        <li>Small business media (under 50 employees)</li>
                        <li>Freelancers creating media for small business clients</li>
                        <li>Web streaming on social media (YouTube, Vimeo, Instagram etc)</li>
                        <li>YouTube monetization</li>
                        <li>Wedding / Event videos</li>
                        <li>Digital Ads (Pre-Roll, Post-Roll, Facebook, Instagram)</li>
                    </ul>
                    <Heading as={"h3"}>What its not good for</Heading>
                    <ul className="list-disc text-xs ml-5">
                        <li>TV, Radio, Video On Demand (Netflix, Hulu, Amazon Prime, etc</li>
                        <li>Games &amp; Apps</li>
                        <li>Theatrical Release</li>
                    </ul>
                    <div className="mt-10 mb-5">
                        <a target="_blank" rel="noreferrer"
                            href={`/license-terms/${licensableProperty?.id}/${panelFields[0]}`}
                            className="text-gray-900 hover:underline cursor-pointer mt-10">View License agreement</a>
                    </div>
                </div>
            )}
            {panelFields[0] === "personal" && (
                <div className="text-gray-900 mt-10 ">
                    <Heading as={"h3"}>What its good for</Heading>
                    <ul className="list-disc text-xs ml-5 mb-10">
                        <li>Personal project</li>
                        <li>Social Media where you&apos;re not pitching a product</li>
                    </ul>
                    <Heading as={"h3"}>What its not good for</Heading>
                    <ul className="list-disc text-xs ml-5">
                        <li>Small business media (under 50 employees)</li>
                        <li>Freelancers creating media for small business clients</li>
                        <li>Web streaming on social media (YouTube, Vimeo, Instagram etc)</li>
                        <li>YouTube monetization</li>
                        <li>Wedding / Event videos</li>
                        <li>Digital Ads (Pre-Roll, Post-Roll, Facebook, Instagram)</li>
                    </ul>
                    <div className="mt-10  mb-5">
                        <a target="_blank" rel="noreferrer"
                            href={`/license-terms/${licensableProperty?.id}/${panelFields[0]}`}
                            className="text-gray-900 hover:underline cursor-pointer mt-10">View License agreement</a>
                    </div>
                </div>
            )}
            {panelFields[0] === "expanded" && (
                <div className="text-gray-900 mt-10 ">
                    <Heading as={"h3"}>What its good for</Heading>
                    <ul className="list-disc text-xs ml-5">
                        <li>TV, Radio, Video On Demand (Netflix, Hulu, Amazon Prime, etc)</li>
                        <li>Games &amp; Apps</li>
                        <li>Theatrical Release</li>
                        <li>Mass distribution</li>
                    </ul>
                    <Heading as={"h3"}>Pricing to be independently negotiated</Heading>
                    <div className="mt-10  mb-5">
                        <a target="_blank" rel="noreferrer"
                            href={`/license-terms/${licensableProperty?.id}/${panelFields[0]}`}
                            className="text-gray-900 hover:underline cursor-pointer mt-10">View License agreement</a>
                    </div>
                </div>
            )}
        </WizardPanel>
    );
};
const ProjectUploadPanel = ({
    form,
    onFileUpload,
}: {
    form: UseFormReturn<z.infer<typeof projectSchema>>;
    onFileUpload: (files: UploadedFile[]) => void;
}) => {
    const id = form.watch("id");

    return (
        <WizardPanel>
            <PanelHeader
                stepName="Upload content"
                hintText="You can skip this step for now if you do not have content to upload at this time"
            />
            <FileManager
                multiple={true}
                objectType="project"
                objectId={id || uuidv4()}
                uploadOnSelect={true}
                isPublic={false}
                onUploadedFilesChange={onFileUpload}
            />
        </WizardPanel>
    );
};
const ProjectFilesPanel = ({
    form,
    addMoreFiles,
}: {
    form: UseFormReturn<z.infer<typeof projectSchema>>;
    addMoreFiles: () => void;
}) => {
    const uploadedFiles = form.watch("files");
    const addWatermark = form.watch("include_watermark") || false;

    const toggleWatermark = (value: boolean) => {
        form.setValue("include_watermark", value);
    };

    const onFileRemove = (file: any) => {
        form.setValue(
            "files",
            uploadedFiles.filter((f) => f.name !== file.name)
        );
    };

    return (
        <WizardPanel>
            <PanelHeader stepName="Project Assets" />
            <FileListing
                theme="lite"
                canRemove={true}
                canAdd={true}
                files={uploadedFiles ? uploadedFiles.filter(it => it.usage === "project") : []}
                onRemove={onFileRemove}
                onAdd={addMoreFiles}
                modifyFile={true}
                addWatermark={addWatermark}
                setAddWatermark={toggleWatermark}
            />
        </WizardPanel>
    );
};

const TalentPanel = ({
    form,
    onSelected,
    talent,
}: {
    form: UseFormReturn<z.infer<typeof projectSchema>>;
    onSelected: (lp: LicensableProperty) => void;
    talent: LicensableProperty | null;
}) => {
    const navigate = useNavigate();
    return (
        <WizardPanel>
            <PanelHeader stepName="Choose the talent" />
            {/* make the search bar full width */}
            <div >
                <FormField
                    control={form.control}
                    name="licensable_property_id"
                    render={({ field: { value, onChange } }) => (
                        <LicensablePropertySearch
                            showSocialAccounts={true}
                            defaultValue={talent}
                            theme="light"
                            onSelect={(licensableProperty) => {
                                navigate("/lp/" + licensableProperty.id);
                            }}
                        />
                    )}
                />
            </div>
        </WizardPanel>
    );
};

const PaymentPanel = ({
    form,
    licensableProperty,
    submitForApproval,
    errors,
    submitting,
    navigateToPanel,
}: {
    form: UseFormReturn<z.infer<typeof projectSchema>>;
    licensableProperty: LicensableProperty | null;
    submitForApproval: () => void;
    errors: ZodIssue[];
    submitting: boolean;
    navigateToPanel: (panelNum: number) => void;
}) => {
    const allFields = form.watch();
    if (allFields.license_type === "expanded") {
        return (
            <ExpandedLicenseCheckout form={form} licensableProperty={licensableProperty}
                submitForApproval={submitForApproval} errors={errors} submitting={submitting}
                navigateToPanel={navigateToPanel} />
        );
    } else {
        return (
            <CreditCardCheckout form={form} licensableProperty={licensableProperty}
                submitForApproval={submitForApproval} errors={errors} submitting={submitting}
                navigateToPanel={navigateToPanel} />
        )
    }
};

export default ProjectWizard;
