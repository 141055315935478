import React, {useCallback} from "react";
import { LicensableProperty } from "../../../model/licensable_property";
import { Button } from "../../../common/components/ui/button";
import { User } from "../../../model/user";
import { USER_TYPES } from "../../../lib/constants";
import {projectWizardSlice} from "../../project/projectWizardSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {useNavigate} from "react-router-dom";
import {ProjectWizardPanels} from "../../project/project_wizard";

export const LPActions = ({
  isLoading,
  licensableProperty,
  handlePublish,
  handleEdit,
  handleDelete,
  profile,
}: {
  isLoading: boolean;
  licensableProperty: LicensableProperty;
  handlePublish: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  profile: User;
}) => {
  const lpIsPublishable = () => {
    if (!licensableProperty.hero_image) {
      return false;
    }
    return true;
  };
  const { setProjectField, setActivePanel, setTalent}= projectWizardSlice.actions;
  const { activePanel } = useAppSelector((state) => state.projectWizard);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const applyForLicense = useCallback((licensableProperty: LicensableProperty) => {
      dispatch(setProjectField({field: "licensablePropertyId", value: licensableProperty.id || ""}))
      if(activePanel === ProjectWizardPanels.TALENT) {
          dispatch(setActivePanel(ProjectWizardPanels.LICENSE_TERMS))
      }else{
            dispatch(setActivePanel(ProjectWizardPanels.PROJECT_NAME))
      }
      dispatch(setTalent(licensableProperty));
      navigate("/project/new")
  }, [dispatch, setProjectField, setActivePanel]);


  if (profile.user_type === USER_TYPES.RIGHTS_HOLDER.key) {
    return (
      <div className="flex flex-col gap-4 text-center sm:mb-10">
        {!lpIsPublishable() && <div className="font-xs text-gray-600">Complete the profile to publish</div>}
        {!licensableProperty.is_active && (
          <Button
            disabled={!lpIsPublishable()}
            variant="primary"
            size="lg"
            onClick={() => handlePublish()}
            loading={isLoading}
          >
            Publish profile
          </Button>
        )}
        <Button variant="outline" size="lg" onClick={() => handleEdit()} disabled={isLoading}>
          Edit profile
        </Button>
        <Button variant="ghost" size="lg" onClick={() => handleDelete()} disabled={isLoading}>
          Delete profile
        </Button>
      </div>
    );
  }else if (profile.user_type === USER_TYPES.CREATOR.key) {
    return (
        <div className="flex flex-col gap-4 text-center sm:mb-10">
          <Button variant="primary" size="lg" onClick={()=>applyForLicense(licensableProperty)}>
            License Talent
          </Button>
          {/*<Button variant="outline" size="lg">*/}
          {/*    Download training data*/}
          {/*  </Button>*/}
        </div>
      )
  }else return (
      <div className="flex flex-col gap-4 text-center sm:mb-10">
        <Button variant="outline" size="lg">
          Download training data
        </Button>
    </div>
  );
};
