import React, { useMemo } from "react";
import { IconArrowLeft, IconArrowRight } from "../icons/icons";
import { Button } from "./ui/button";
import "./wizard_group.css";
interface WizardPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  heading?: React.ReactNode;
  component: React.ReactNode;
  canAdvance?: boolean;
}
interface WizardGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  panels: WizardPanelProps[];
  onSubmit?: () => void;
  onPanelChange: (panelIndex: number) => void;
  next: (panelIndex: number) => void;
  previous: (panelIndex: number) => void;
  activePanel: number;
  submitting?: boolean;
}

const PixelBoxes = ({ pattern }: any) => {
  const colorStyle = (color: string) => {
    const hexColors: any = {
      red: "#FD5939",
      white: "#FFFFFF",
      yellow: "#FDFD43",
      blue: "#2937FF",
      pink: "#F378FF",
      black: "#161618",
    };
    return hexColors[color];
  };

  return (
    <div>
      <div className="flex flex-col w-full">
        {pattern.map((row: string[], idx: number) => {
          return (
            <div key={`${row[idx]}-${idx}`} className="grid grid-cols-6 gap-0 h-[50%]">
              {row.map((color: string, idx: number) => {
                return (
                  <div key={`${color}-${idx}`} style={{ backgroundColor: colorStyle(color) }} className={"w-16 h-16 block"}>
                    &nbsp;
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const WizardGroup = ({
  panels,
  onPanelChange,
  onSubmit,
  next,
  previous,
  activePanel,
  submitting,
  ...props
}: WizardGroupProps) => {
  const pixels = useMemo(() => {
    const pattern = [
      ["white", "blue", "yellow", "red"],
      ["yellow", "pink", "white", "yellow", "pink", "black"],
    ];
    return <PixelBoxes pattern={pattern} />;
  }, [activePanel]);

  return (
    <div {...props} className="flex flex-col flex-grow">
      <div className="flex-grow wizard-panel-holder">
        {panels.map((panel, index) => {
          return (
            <div key={index} style={{ display: index === activePanel ? "block" : "none" }}>
              {panel?.heading}
              {panel.component}
            </div>
          );
        })}
      </div>

      {/*     height: 128px;
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white; */}
      <div className=" bg-brand-yellow w-full fixed bottom-0 left-0">
        <div className="flex gap-4 h-20 items-center justify-center px-10 wizard_nav_buttonbar">
          {activePanel === 0 && <div className="p-3"></div>}
          {activePanel !== 0 && (
            <NavButton
              className="bg-white p-3"
              direction={"previous"}
              onClick={() => {
                onPanelChange(activePanel - 1);
                previous(activePanel - 1);
              }}
              disabled={submitting || activePanel === 0}
            />
          )}
          <div className="flex gap-4 items-center px-10 text-brand-black font-semibold text-nowrap">
            {activePanel + 1} / {panels.length}
          </div>
          {activePanel !== panels.length - 1 && (
            <NavButton
              direction={"next"}
              onClick={() => {
                onPanelChange(activePanel + 1);
                next(activePanel + 1);
              }}
              disabled={submitting || activePanel === panels.length - 1 || !panels[activePanel].canAdvance}
            />
          )}
          {activePanel === panels.length - 1 && (
            <div>&nbsp;</div>
          )}
          {onSubmit !== undefined && activePanel === panels.length - 1 && (
            <Button
              type="submit"
              variant="secondary"
              disabled={submitting}
              loading={submitting}
              onClick={() => onSubmit()}
            >
              Finish
            </Button>
          )}
        </div>
        <div className="max-w-[400px] flex flex-grow">{pixels}</div>
        {/* <div className="flex gap-4 items-center px-10">
          {activePanel !== 0 && (
            <NavButton
              className="bg-white p-3"
              direction={"previous"}
              onClick={() => {
                onPanelChange(activePanel - 1);
                previous(activePanel - 1);
              }}
              disabled={submitting || activePanel === 0}
            />
          )}
          <div className="flex gap-4 items-center px-10 text-brand-black font-semibold text-nowrap">
            {activePanel + 1} / {panels.length}
          </div>
          {activePanel !== panels.length - 1 && (
            <NavButton
              direction={"next"}
              onClick={() => {
                onPanelChange(activePanel + 1);
                next(activePanel + 1);
              }}
              disabled={submitting || activePanel === panels.length - 1 || !panels[activePanel].canAdvance}
            />
          )}
          {onSubmit !== undefined && activePanel === panels.length - 1 && (
            <Button
              type="submit"
              variant="secondary"
              disabled={submitting}
              loading={submitting}
              onClick={() => onSubmit()}
            >
              Finish
            </Button>
          )}
        </div> */}
      </div>
    </div>
  );
};
interface NavButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  direction: "next" | "previous";
}
export const NavButton = ({ direction, onClick, disabled, children }: NavButtonProps) => {
  const bgColor = direction === "previous" ? "white" : "bg-brand-yellow";

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: bgColor,
        color: disabled ? "text-gray-400" : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {direction === "next" && <IconArrowRight />}
      {direction === "previous" && <IconArrowLeft />}
    </button>
  );
};
export default WizardGroup;
