import React from "react";
import ArtistPropertyCard from "./artist_property_card";

export function ArtistPropertyList({ properties }: any) {
  return (
    <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
      {properties.length ? (
        properties.map((licensableProperty: any /* todo */, idx: number) => {
          return (
            <div key={`${licensableProperty.id}-${idx}`} className="group relative">
              <div className="w-full overflow-hidden lg:aspect-none group-hover:opacity-85">
                <ArtistPropertyCard licensableProperty={licensableProperty} />
              </div>
            </div>
          );
        })
      ) : (
        <div className="group relative">
          <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden lg:aspect-none group-hover:opacity-85 text-white">
            <div className="rounded-t-lg bg-gray-700 h-[304px]"></div>
            <div className="rounded-b-lg bg-gray-900 h-[236px]"></div>
          </div>
        </div>
      )}
    </div>
  );
}
