import React, {ButtonHTMLAttributes} from "react";
import {Slot} from "@radix-ui/react-slot";
import {cva, type VariantProps} from "class-variance-authority";
import {Loader2} from "lucide-react";

import cn from "../../../lib/utils";
import {Link} from "react-router-dom";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center font-semibold whitespace-nowrap rounded-full text-base ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ",
  {
    variants: {
      variant: {
        default: "border border-input bg-brand-black/30 hover:bg-accent text-white hover:text-accent-foreground",
        primary: "bg-primary text-primary-foreground hover:bg-secondary border border-yellow-300",
        "primary-negative": "bg-brand-black text-white hover:bg-brand-black border border-black",
        "primary-negative-invert": "bg-white text-brand-black hover:bg-brand-yellow border border-brand-yellow",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        outline: "border border-gray-600 bg-none hover:bg-accent text-white hover:text-accent-foreground",
        "outline-negative": "border border-gray-400 bg-none hover:bg-gray-100 text-black ",
        "outline-official": "border border-gray-600 text-black bg-none hover:bg-black hover:text-white",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-white font-base font-normal underline-offset-4 hover:underline",
        icon: "bg-primary text-primary-foreground hover:bg-secondary border border-yellow-300",
      },
      size: {
        default: "h-14 px-6 py-2 text-base tracking-wide",
        sm: "rounded-full px-7 py-2.5 text-sm tracking-wide",
        xs: "rounded-full px-3 py-2 text-xs tracking-wide",
        lg: "h-14 rounded-full px-8 text-base tracking-wide",
        icon: "h-14 w-14",
      },
    },
    defaultVariants: {
      variant: "secondary",
      size: "sm",
    },
  }
);

export interface SCNButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const SCNButton = React.forwardRef<HTMLButtonElement, SCNButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  }
);
SCNButton.displayName = "Button";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: React.ReactNode;
  children?: React.ReactElement | string | undefined;
  href?: string;
  target?: "_blank" | "_self";
  variant?: any;
  size?: any;
  loading?: true | false;
  current?: true | false;
  prefix?: any;
  suffix?: any;
  className?: string;
  type?: "button" | "submit" | "reset";
}

export const Button = ({ href, children, variant, prefix, suffix, target, className, ...props }: ButtonProps) => {
  const { loading, current, size, ...rest } = props;
  if (current && !variant) {
    variant = "primary";
  }
  if (href) {
    return (
      <Link to={href} target={target} className={cn(buttonVariants({ variant, size }), "gap-1", className || "")}>
        {children}
      </Link>
    );
  }
  return (
    <SCNButton variant={variant} size={size} {...rest} className={className || ""}>
      {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      {prefix ? <span className="mr-2">{prefix}</span> : null}
      {children}
      {suffix ? <span className="ml-2">{suffix}</span> : null}
    </SCNButton>
  );
};

export { buttonVariants };
