import React, { useState } from "react";
import WizardSetup from "../../../common/components/wizard_panel";
import { TrainingDataAssetReview } from "../../../common/components/training_data_asset_review";
import { Button } from "../../../common/components/ui/button";
import { IconArrowRight } from "../../../common/icons/icons";
import { DialogModal } from "../../../common/components/ui/dialog_modal";
import Heading from "../../../common/components/heading";

export const ArtistTrainingDataAssets = ({ form, errors, formFiles, addMoreAssets }: { form: any; errors: any; formFiles: any; addMoreAssets: (idx: any) => void }) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentAsset, setCurrentAsset] = useState<any>(null);

    const onDeleteAsset = (file: any) => {
        form.setValue(
            "files",
            formFiles.filter((f: any) => f.name !== file.name)
        );
        if (formFiles.length === 1) {
            addMoreAssets(6);
        }
    };

    const openDescriptionModal = (asset: any) => {
        setModalOpen(true);
        setCurrentAsset({ ...asset });
    }

    const saveDescription = () => {
        const updatedFiles = formFiles.map((file: any) => {
            if (file.name === currentAsset.name) {
                return { ...file, description: currentAsset.description };
            }
            return file;
        });
        form.setValue("files", updatedFiles);
        setModalOpen(false);
        setCurrentAsset(null);
    }

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentAsset({ ...currentAsset, description: e.target.value });
    }

    return (
        <WizardSetup>
            <div className="flex flex-col items-center gap-3">
                {formFiles.map((asset: any, idx: any) => {
                    return (
                        <div key={`${asset.id}-${idx}`} className="mx-5">
                            <TrainingDataAssetReview asset={asset} deleteAsset={() => onDeleteAsset(asset)} openDescriptionModal={() => openDescriptionModal(asset)} />
                        </div>
                    );
                })}
                {/* TODO: do not hardcode stepBack idx */}
                <Button onClick={() => addMoreAssets(6)} variant="primary-negative" className="w-3/5 gap-2">
                    <>Add more assets <IconArrowRight fill="white" /></>
                </Button>
            </div>
            <DialogModal
                isOpen={modalOpen}
                onOpenChange={setModalOpen}
                onClose={() => { setModalOpen(false); setCurrentAsset(null); }}
            >
                {currentAsset &&
                    <div className="space-y-6">
                        <img className="h-[300px] rounded-xl" src={currentAsset.permalink || "/bit-gfx1.svg"} />
                        <div>
                            <p className="italic text-sm pb-2">{currentAsset.name}</p>
                            <Heading as="h3">Description</Heading>
                        </div>
                        <textarea
                            rows={8}
                            className="wizard-textarea mt-[60px]"
                            placeholder={"Enter description here ..."}
                            value={currentAsset.description || ""}
                            onChange={handleDescriptionChange}
                        ></textarea>
                        <Button onClick={saveDescription} variant="primary-negative">Save</Button>
                    </div>
                }
            </DialogModal>
        </WizardSetup>
    )
}