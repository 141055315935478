import React, { HTMLAttributes } from "react";
import { Label } from "./ui/label";
import { RadioGroup, RadioGroupItem } from "./ui/radio_group";

interface ButtonRadioGroupProps extends HTMLAttributes<HTMLHeadingElement> {
  children?: React.ReactNode;
  options: any[];
  field: any;
}
const ButtonRadioGroup = ({ children, ...props }: ButtonRadioGroupProps) => {
  const { options, field } = props;
  const defaultValue = options.find((o) => o.default === true)?.key;

  return (
    <RadioGroup
      onValueChange={field.onChange}
      value={field.value}
      defaultValue={defaultValue}
      className=" flex flex-wrap gap-3"
    >
      {options.map((option, idx) => {
        return (
          <div className="flex items-center " key={`${option.value}-${idx}`}>
            <RadioGroupItem value={option.value} id={option.value} className="peer sr-only" />
            <Label
              htmlFor={option.value}
              className="text-nowrap text-brand-black text-lg font-sans font-semibold not-italic rounded-full px-6 py-4 m-0 border hover:cursor-pointer peer-data-[state=checked]:border-brand-yellow hover:cursor-pointer  peer-data-[state=checked]:bg-brand-yellow [&:has([data-state=checked])]:bg-brand-yellow peer-data-[state=checked]:text-black group"
            >
              {option.label}
            </Label>
          </div>
        );
      })}
    </RadioGroup>
  );
};
export default ButtonRadioGroup;
