import React from "react";
import {UploadedFile} from "../../../api/file_api";
import cn from "../../../lib/utils";
import {humanizeName} from "../../../lib/utils";

interface FileCardProps {
    file: UploadedFile;
    onRemove?: (file: UploadedFile) => void;
    canRemove: boolean;
    theme?: "lite"|"dark"
}
const FileCard = ({file, canRemove, onRemove, theme}: FileCardProps) => {
    const thumbnail = file.thumbnail ? file.thumbnail : file.file_type === "document" ? "/images/sign_document_icon_black_110x80.png" : file.permalink;
    return <div className={cn("file-card", (theme?`file-card-${theme}`:""))}>
        <div className="file-card__thumbnail" style={{backgroundImage:`url(${thumbnail})`}}></div>
        <div className="file-card__name">
            <div style={{verticalAlign:"center"}} className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M3.7875 2.40002C3.36315 2.40002 2.95619 2.5686 2.65613 2.86865C2.35607 3.16871 2.1875 3.57568 2.1875 4.00002V12C2.1875 12.4244 2.35607 12.8313 2.65613 13.1314C2.95619 13.4315 3.36315 13.6 3.7875 13.6H13.3875C13.8118 13.6 14.2188 13.4315 14.5189 13.1314C14.8189 12.8313 14.9875 12.4244 14.9875 12V4.00002C14.9875 3.57568 14.8189 3.16871 14.5189 2.86865C14.2188 2.5686 13.8118 2.40002 13.3875 2.40002H3.7875ZM13.3875 12H3.7875L6.9875 5.60002L9.3875 10.4L10.9875 7.20002L13.3875 12Z"
                          fill="currentcolor"/>
                </svg>
                <span className="text-xs-sans">{file.file_type}</span>
            </div>
            <div className="font-serif italic" style={{fontSize:"24px", lineHeight:"150%"}}>
                {humanizeName(file.name||"")}
            </div>
        </div>
        {canRemove && onRemove && <button type="button"  onClick={() => onRemove(file)} className="pr-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
                <path
                    d="M8.5 0.5V2H12.5C12.6326 2 12.7598 2.05268 12.8536 2.14645C12.9473 2.24021 13 2.36739 13 2.5V3.5C13 3.63261 12.9473 3.75979 12.8536 3.85355C12.7598 3.94732 12.6326 4 12.5 4H0.5C0.367392 4 0.240215 3.94732 0.146447 3.85355C0.0526784 3.75979 0 3.63261 0 3.5V2.5C0 2.36739 0.0526784 2.24021 0.146447 2.14645C0.240215 2.05268 0.367392 2 0.5 2H4.5V0.5C4.5 0.367392 4.55268 0.240215 4.64645 0.146447C4.74022 0.0526784 4.86739 0 5 0H8C8.13261 0 8.25979 0.0526784 8.35355 0.146447C8.44732 0.240215 8.5 0.367392 8.5 0.5ZM2.5 16H10.5C10.8978 16 11.2794 15.842 11.5607 15.5607C11.842 15.2794 12 14.8978 12 14.5V5.5H1V14.5C1 14.8978 1.15804 15.2794 1.43934 15.5607C1.72064 15.842 2.10218 16 2.5 16Z"
                    fill="currentcolor"/>
            </svg>
        </button>}
    </div>
}

export default FileCard;