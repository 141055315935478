export const USER_TYPES = {
  CREATOR: {
    key: "creator",
    value: "creator",
    label: "Creator",
    description: "You create AI generated content and need licenses",
    default: true,
  },
  RIGHTS_HOLDER: {
    key: "rights_holder",
    value: "rights_holder",
    label: "Talent",
    description: "You are a artist or agent who holds the rights to an intellectual property",
  },
  ADMIN: {
    key: "admin",
    value: "admin",
    label: "Admin",
    description: "With great power comes great responsibility",
  },
};
export const USER_TYPES_ARRAY = [USER_TYPES.CREATOR, USER_TYPES.RIGHTS_HOLDER];
export const ARTIST_TYPES = [
  { value: "actor", label: "Actor" },
  { value: "athlete", label: "Athlete" },
  { value: "influencer", label: "Influencer" },
  { value: "model", label: "Model" },
  { value: "musician", label: "Musician" },
  { value: "public_figure", label: "Public figure" },
];
export const MEDIA_CONTENT = [
  { value: "drugs", label: "Drugs" },
  { value: "alcohol", label: "Alcohol" },
  { value: "mature_language", label: "Mature language" },
  { value: "nudity", label: "Nudity" },
  { value: "politics", label: "Politics" },
  { value: "tobacco", label: "Tobacco" },
  { value: "weapons", label: "Weapons" },
];
export const LICENSE_TYPES = [
  {
    key: "personal",
    value: "personal",
    label: "Personal license",
    description: "For creating and publishing videos or podcasts on personal web channels.",
    default: true,
  },
  {
    key: "commercial",
    value: "commercial",
    label: "Commercial license",
    description: "For small businesses and freelancers creating web content.",
  },
  {
    key: "expanded",
    value: "expanded",
    label: "Expanded rights",
    description: "For large businesses and agencies creating web content.",
  },
];

export const PROJECT_STATUS = {
  DRAFT: { key: "draft", label: "Draft" },
  SUBMITTED_FOR_APPROVAL: { key: "submitted_for_approval", label: "Submit for approval" },
  CHANGES_REQUESTED: { key: "changes_requested", label: "Changes requested" },
  REJECTED: { key: "rejected", label: "License request denied" },
  APPROVED: { key: "approved", label: "License request approved" },
};

export default { USER_TYPES, ARTIST_TYPES };
