import React from "react";
import { LicensableProperty } from "../../../model/licensable_property";
import Markdown from "react-markdown";
import "./markdown.css";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty;
}

export const LPDescription = ({ licensableProperty }: LicensablePropertyProps) => {
  if (!licensableProperty.description) return null;
  const markdown = licensableProperty.description;
  return (
    <div className="w-full">
      <Markdown className="lp_description">{markdown}</Markdown>
    </div>
  );
};
