import React from "react";
import AppFooter from "../../pages/layouts/app_footer";


const RegisterComplete = () => {
    return (
        <div className="page_header">
            <div className="register_complete grid grid-cols-2 items-center align-middle">
                <div >
                    <h1 className="text-xxxl-serif text-white mb-8">It&apos;s Official.</h1>
                    <p className="text-white text-l-sans">You&apos;re on the list.</p>
                    <div className="mt-8">
                        <p className="text-white text-m-sans text-normal">Congrats! You are officially
                            on our list for early beta access. We&apos;re inviting users from this list on a monthly basis.
                            Keep an eye out for emails from us with announcements and next steps.</p>
                    </div>
                </div>
                <div className="register_complete_image">
                    <img src="/images/signup_complete_graphic.png" alt="Signup Complete" />
                </div>
            </div>
            <AppFooter/>
        </div>
    );
};
export default RegisterComplete;
