import React, { useEffect } from "react";
import PublicHeader from "../layouts/public_header";
import AppFooter from "../layouts/app_footer";
import { BlogPost, useBlogAPI } from "../../api/blog_api";
import BlogPostCard from "./blog_post_card";
import "./blog.css";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BlogListingProps {

}
const BlogListing: React.FC<BlogListingProps> = (props) => {
    const [blogPosts, setBlogPosts] = React.useState<BlogPost[]>([]);
    const blogAPI = useBlogAPI();
    const navigate = useNavigate();
    useEffect(() => {
        blogAPI.fetchBlogPosts(null, 100, 0)
            .then((posts) => {
                setBlogPosts(posts);
            })
    }, []);
    return (
        <main className="page_container blog-container">
            <PublicHeader theme="lite" />
            <div className="blog-header text-xxxl-serif italic">
                <h1>AI State of Mind: The Official Guide to Generative Media</h1>
            </div>
            <div className="blog-listing">
                {blogPosts.map((post) => {
                    return (
                        <BlogPostCard post={post} key={post.id} onClick={() => navigate(`/blog/${post.slug}`)} />
                    );
                })}
            </div>
            <div className="blog-footer">
                <div>
                    We&rsquo;re building an ecosystem for authenticity to ignite the next creative renaissance.
                </div>
                <div className="relative">
                    <button onClick={() => navigate("/register")}>
                        Get Started
                    </button>
                </div>
            </div>
            <AppFooter />
        </main>
    );
}
export default BlogListing;