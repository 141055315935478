import React from "react";
import { Project } from "../../../api/project_api";
import Heading from "../../../common/components/heading";
import { NavLink } from "react-router-dom";
import { Badge } from "../../../common/components/ui/badge";
import { format } from "date-fns";

const ProjectCard = ({ project }: { project: Project }) => {
  const talentImage =
    project.licensable_properties && project.licensable_properties.length > 0
      ? project.licensable_properties[0].profile_image
      : "/picture-icon.svg";
  const talentName = project.licensable_properties ? project.licensable_properties[0].name : "Talent Name";
  const projectImage = project.profile_image || "/bit-gfx1.svg";

  return (
    <NavLink to={`/project/${project.id}`}>
      <article className="relative isolate flex flex-col justify-end overflow-hidden rounded-xl hover:opacity-90 bg-gray-800">
        <img src={projectImage} alt="" className="object-cover h-[304px]" />
        <div className="px-6 py-4 bg-gray-900 flex flex-col gap-5 h-[235px] ">
          <div className="flex flex-wrap justify-between">
            <div className="flex flex-wrap items-center gap-2 pt-2">
              <img className="h-10 w-10 rounded-full object-cover" src={talentImage} alt="" />
              <Heading as="body" className="m-0 text-white text-lg">
                {talentName}
              </Heading>
            </div>
            <div className="flex items-center">
              {project.start_date && <Badge variant="micro">sent:{format(project.start_date, "MM/dd/yyyy")}</Badge>}
            </div>
          </div>
          <div className="space-y-4 text-white">
            <Heading as="h3" className="text-white font-sans font-semibold not-italic tracking-wide min-h-16">
              {project.name}
            </Heading>
            <div className="py-4 text-[10px] tracking-widest uppercase">
              {project.files?.length || 0} asset{(project.files?.length || 0) > 1 ? "s" : ""}
            </div>
          </div>
        </div>
      </article>
    </NavLink>
  );
};

export default ProjectCard;
