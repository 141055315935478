import React from "react";

import {BlogPost, useBlogAPI} from "../../api/blog_api";
import {format} from "date-fns/format"
import "./blog.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BlogPostCardProps {
    post: BlogPost;
    onClick?: ()=> void;
}
const BlogPostCard: React.FC<BlogPostCardProps> = ({post, onClick}) => {
    function randomIntFromInterval(min: number, max: number) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    return (
        <div className="blog-post-card" onClick={onClick}>
            <img src={`/images/blog_image_${randomIntFromInterval(1,4)}.png`} alt={post.title} width="360" height="240"/>
            <div className="blog-title">{post.title}</div>
            <div className="blog-published-at">{format(post.published_at||new Date(), "dd MMMM yyyy")}</div>
        </div>
    );
}

export default BlogPostCard;