import React from "react";
import { useAppSelector } from "../../hooks";
import Projects from "./projects";
// import RightsHolderProjects from "./rights_holder_projects";
// import CreatorProjects from "./creator_projects";

function ProjectRouter() {
  const { profile } = useAppSelector((state) => state.user);

  // switch (profile.user_type) {
  //   case USER_TYPES.CREATOR.key:
  //     return <CreatorProjects />;
  //   case USER_TYPES.RIGHTS_HOLDER.key:
  //     return <RightsHolderProjects />;
  //   default:
  //     return <div>Dashboard coming soon</div>;
  // }

  return <Projects user={profile} />;
}

export default ProjectRouter;
