import React, { useEffect, useState } from "react";
import SubHeader from "../layouts/sub_header";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { GenerateMediaResult, GenerativeModel } from "./models/image_generator";
import "./components/image_details.css";
import Heading from "../../common/components/heading";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import { Button } from "../../common/components/ui/button";
import { IconArrowRight, EditPromptIcon, TrashIcon, MiniVerifiedLogo } from "../../common/icons/icons";
import { useNavigate } from "react-router-dom";
import { aspectRatioOptions } from "./models/constants";

interface EnrichedGeneratedMediaResult extends GenerateMediaResult {
  aspectRatioDetail?: string;
  modelData?: GenerativeModel[];
}

const MyGeneratedImages = () => {
  const [myGeneratedImages, setMyGeneratedImages] = useState<GenerateMediaResult[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<EnrichedGeneratedMediaResult | null>(null);
  const { getMyImages, getModelById, deleteImage } = useGenerativeAPI();

  const navigate = useNavigate();

  const fetchMyImages = async () => {
    try {
      const response = await getMyImages();
      const enrichedImages = await Promise.all(
        response.map(async (image: GenerateMediaResult) => {
          const modelData = await Promise.all(
            image.model_weights?.map((weight) =>
              getModelById(weight.id).then((response) => response)
            ) || []
          );

          const aspectRatioDetail = aspectRatioOptions.find(
            (option) => option.key === image.aspect_ratio
          );


          return {
            ...image,
            modelData,
            aspectRatioDetail: aspectRatioDetail ? `${aspectRatioDetail.label} ${aspectRatioDetail.description}` : "Unknown",
          };
        })
      );

      setMyGeneratedImages(enrichedImages);
    } catch (error) {
      console.error("Error fetching generative output", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyImages();
  }, []);

  const openImageDetails = (image: GenerateMediaResult) => () => {
    setSelectedImage(image);
    setOpenModal(true);
  }

  const deleteMyImage = (taskQueueId: string) => async () => {
    try {
      await deleteImage(taskQueueId);

      setOpenModal(false);
      setSelectedImage(null);

      fetchMyImages();

    } catch (error) {
      console.error("Error deleting image", error);
    }
  }

  return (
    <div className="page_content">

      <DialogModal
        isOpen={openModal}
        onOpenChange={setOpenModal}
        onClose={() => { setOpenModal(false); setSelectedImage(null) }}
        variant="large"
      >
        {selectedImage &&
          <div className="flex flex-row p-10 gap-16">
            <img className="w-[452px] rounded-lg" src={selectedImage.outputs[0].permalink} />
            <div className="flex flex-col gap-4 justify-center">
              <Heading as="h3">Image details</Heading>
              <p> {selectedImage.prompt}</p>
              <p><span className="font-semibold">Resolution:</span> {selectedImage.aspectRatioDetail}</p>
              {selectedImage.modelData?.map((model, index) => (
                <p key={index}>
                  <span className="font-semibold">
                    {model.entity_type === "USER_PRODUCT" ? "Product: "
                      : model.entity_type === "BRAND" ? "Brand: "
                        : model.entity_type === "LICENSABLE_PROPERTY" ? "Talent: "
                          : "IP Valut: "}
                  </span> {model.name}
                </p>
              ))}

              {/* TODO Apply for license */}
              {/* <p className="font-semibold">License: <Button className="text-black font-semibold outline-none" variant="link"><>Apply for a license <IconArrowRight /> </></Button></p> */}
              <div className="w-full flex justify-between">
                <Button variant="outline-official" className="rounded-xl flex gap-2 justify-start hover:fill-white" onClick={() => navigate(`/image/editor/${selectedImage.task_queue_id}`)}><><EditPromptIcon />Edit image</></Button>
                <Button variant="outline-official" className="rounded-xl flex gap-2 justify-start hover:fill-white" onClick={deleteMyImage(selectedImage.task_queue_id || "")}><><TrashIcon />Delete image</></Button>
              </div>
            </div>

          </div>
        }

      </DialogModal>
      <SubHeader />

      {loading ? <div>Loading...</div> :
        myGeneratedImages.length === 0 ? <div className="text-white  pl-20"><Heading as="h3">No images saved at this time</Heading></div> :
          <div className="flex flex-row flex-wrap">
            {myGeneratedImages.map((image, index) => (
              <div key={index} onClick={openImageDetails(image)} className={"image-card h-[460px]"}>
                <div className="image-card-inner"
                  style={{
                    backgroundImage: image.outputs[0].permalink
                      ? `linear-gradient(180deg, rgba(20, 20, 20, 0.00) 40.22%, #141414 100%), url(${image.outputs[0].permalink})`
                      : "none",
                  }}>
                  <div className="absolute top-5 right-5">
                    <MiniVerifiedLogo /></div>
                </div>
              </div>

            ))}
          </div>
      }
    </div>
  )

}

export default MyGeneratedImages;