import React from "react";
import { useForm, Controller } from "react-hook-form";
import Heading from "../../../../common/components/heading";
import { Button } from "../../../../common/components/ui/button";
import { BitBanner } from "../../../../common/components/bit_banner";
import CardRadioGroup from "../../../../common/components/card_radio_group";
import { aspectRatioOptions } from "../../models/constants";

const AspectRatio = ({ currentAspectRatio, onSave }: { currentAspectRatio: any, onSave: (newAspectRatio: string) => void }) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            aspect_ratio: currentAspectRatio
        }
    });

    const onSubmit = (data: { aspect_ratio: string }) => {
        onSave(data.aspect_ratio);
    }

    return (
        <div className="flex flex-row">
            <div className="flex items-center justify-center m-7">
                <BitBanner />
            </div>
            <div className="flex flex-col gap-7">
                <Heading className="pt-10 font-semibold font-black" as="h3">Edit aspect ratio</Heading>
                <Controller
                    name="aspect_ratio"
                    control={control}
                    render={({ field }) => (
                        <CardRadioGroup field={field} options={aspectRatioOptions} activeOption={field.value} onChange={field.onChange}
                            theme="lite" className={"radiogroup"} />)} />
                <Button onClick={handleSubmit(onSubmit)} className="self-start w-auto" variant="primary-negative">Generate image</Button>
            </div>
        </div>
    )

}

export default AspectRatio;