import React from "react";
import "./app_footer.css";
export default function AppFooter() {
    return (
        <div className="app_footer">
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="59" viewBox="0 0 52 59" fill="none" className="footer_logo">
                <g clipPath="url(#clip0_1302_751)">
                    <path
                        d="M26.3421 0C12.3196 0 1.66822 10.0329 0.0495757 25.7454H7.92964C9.11946 14.9415 15.1454 6.01181 26 6.01181C38.2799 6.01181 44.2067 17.8693 44.2067 29.2187C44.2067 42.1769 37.6007 52.9337 25.9157 52.9337C15.5395 52.9337 9.1616 44.1949 7.92964 33.624H0C1.38564 48.8804 11.5065 58.948 25.4943 58.948C39.4821 58.948 52 48.5308 52 28.8815C52 11.9418 41.9237 0 26.3421 0Z"
                        fill="#141414" />
                    <path d="M15.8075 25.7451H7.93237V33.6212H15.8075V25.7451Z" fill="#141414" />
                </g>
                <defs>
                    <clipPath id="clip0_1302_751">
                        <rect width="52" height="58.9455" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <div className="flex flex-col gap-[20px] footer_links">
                <div style={{ display: "flex", gap: "52px" }}>
                    <a className="text-s-sans text-black" href="mailto:support@theofficial.ai">Contact</a>
                    <a className="text-s-sans text-black" href="/terms">Terms of Use</a>
                    <a className="text-s-sans text-black" href="/privacy">Privacy Policy</a>
                </div>
                <div className="text-xs-sans text-gray-500 normal-case text-center">Official AI</div>
            </div>
            <div className="mobile_pixel_block">
                <div className="footer_pixel pixel_pink" />
                <div className="footer_pixel pixel_yellow" />
                <div className="footer_pixel pixel_yellow" />
                <div className="footer_pixel pixel_red" />
                <div className="footer_pixel pixel_pink" />
                <div className="footer_pixel pixel_black" />
                <div className="footer_pixel pixel_white" />
                <div className="footer_pixel pixel_yellow" />
                <div className="footer_pixel pixel_yellow" />
                <div className="footer_pixel pixel_black" />
                <div className="footer_pixel pixel_white" />
                <div className="footer_pixel pixel_yellow" />
                <div className="footer_pixel pixel_blue" />
                <div className="footer_pixel pixel_yellow" />
                <div className="footer_pixel pixel_yellow" />
                <div className="footer_pixel pixel_red" />
            </div>
        </div>
    );
}
