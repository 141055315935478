import React, { useEffect, useState } from "react"
import { UploadedFile } from "../../../api/file_api";
import FileCard from "./file_card";
import cn from "../../../lib/utils";
import { IconArrowRight } from "../../icons/icons"

interface FileListingProps {
    files?: UploadedFile[];
    onRemove?: (file: UploadedFile) => void;
    canRemove: boolean;
    onAdd?: () => void;
    canAdd: boolean;
    theme?: "lite" | "dark";
    modifyFile?: boolean;
    addWatermark?: boolean;
    setAddWatermark?: (value: boolean) => void;
}

const FileListing = ({ files, canRemove, onRemove, canAdd, onAdd, theme, modifyFile, addWatermark, setAddWatermark }: FileListingProps) => {

    const handleToggle = () => {
        if (setAddWatermark) {
            setAddWatermark(!addWatermark);
        }
    }


    return <div className={cn("file-listing flex flex-col items-center", (theme ? "file-listing-" + theme : ""))} style={{ gap: "8px", maxWidth: "800px" }}>
        {files?.map((file) => {
            return <FileCard key={file.id || file.name} file={file} onRemove={onRemove} canRemove={canRemove} theme={theme} />
        })}
        {canAdd &&
            <button type="button" style={{
                width: "100%", fontWeight: "bold",
                padding: "16px 30px", justifyContent: "center",
                alignItems: "center", gap: "10px", display: "flex",
                color: (theme === "dark" ? "#161618" : "#fff"),
                background: (theme === "dark" ? "#fff" : "#161618"), borderRadius: "70px"
            }}
                onClick={onAdd}>
                <span>Add more assets</span>

                <IconArrowRight fill="white" />
            </button>}
        {modifyFile &&
            <div className="flex items-start w-full mt-10">
                <input type="checkbox"
                    checked={addWatermark}
                    className="appearance-none w-4 h-4 border-2 border-gray-500 rounded-full checked:bg-blackish mx-4 mt-1"
                    onChange={() => handleToggle()} />
                <label className="font-semibold">
                    Add verified watermark to images once licensed
                </label>
            </div>}
    </div>
}

export default FileListing;