import React from "react";
import WizardStep from "../../../common/components/wizard_panel";
import { FormControl, FormField, FormItem } from "../../../common/components/ui/form";
import { Input } from "../../../common/components/ui/input";
import { Errors } from "./errors";
import { FIELDS } from "../../../lib/fields";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

export const ArtistNameStep = ({ form, errors }: { form: any; errors: any }) => {
  return (
    <WizardStep>
      <div className="flex items-center py-4 mb-4">
        <FormField
          control={form.control}
          name={FIELDS.NAME.key}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <div className="relative">
                  <Input
                    className="text-4xl italic font-serif placeholder-opacity-70 placeholder-gray-200 bg-white text-brand-black"
                    placeholder="Ex: Beyonce"
                    {...field}
                  />
                  {/* <ArrowRightIcon
                    height={54}
                    className={`absolute right-0 top-12 ${
                      field.value?.length > 2 ? "fill-brand-black" : "fill-gray-100"
                    }
                  }`}
                  /> */}
                </div>
              </FormControl>
              <Errors errors={errors} />
            </FormItem>
          )}
        />
      </div>
    </WizardStep>
  );
};
