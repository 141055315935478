import React, {useEffect, useState} from "react";
import {LicensableProperty, Price} from "../../../model/licensable_property";
import { FIELDS } from "../../../lib/fields";
import { formatCurrency } from "../../../lib/utils";
import { SelectDropdown } from "../../..//common/components/ui/select";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty;
  onLicenseTypeChange?: (licenseType: "commercial"|"personal"|"expanded") => void;
}

export const LPPricing = ({ licensableProperty, onLicenseTypeChange }: LicensablePropertyProps) => {
  const [licenseType, setLicenseType] = useState<"commercial"|"personal"|"expanded">("commercial");

  const getLicensePrice = () => {
    if(!licenseType || !licensableProperty || !licensableProperty.prices){
      return null;
    }
    // duration / term_size = multiplier
    // 60 / 15 = 4
    const duration = 1;
      // Just one LP per project for now
      const prices = licensableProperty.prices;
      if (prices?.length) {
        const lpPrice = prices.find((price: Price) => (price && price.license_type === licenseType ? 1 : 0));
        if (lpPrice) {
          return <span className="project_price">{formatCurrency(lpPrice.price * duration)}</span>;
        } else {
          const lpPrice = [...prices].sort((a, b) => b.price - a.price)[0];
          return <div className="project_price">{formatCurrency(lpPrice.price * duration)}+<div
              className="project-price-hint text-xs-sans text-normal capitalize">*Lowest Price</div></div>;
        }
      } else {
        return <div>TBD</div>;
      }

  };
  useEffect(()=> {
    if (onLicenseTypeChange) {
      onLicenseTypeChange(licenseType);
    }

  }, [licenseType])

  return (
    <div className="w-full text-">
      <div className="flex flex-col gap-2 text-center sm:mb-10">
        <p className="font-bold text-lg font-semibold">Commercial License Cost</p>
        <p>Select filters to determine cost up front</p>
      </div>
      <div className="flex flex-col justify-center gap-4">
        <SelectDropdown
          field={{ onChange: (v: "commercial"|"personal"|"expanded") => setLicenseType(v), value: licenseType }}
          options={FIELDS.LICENSE_TYPE.options}
          variant="button"
        />
      </div>
      <div className="py-10 text-center">
        <div className="mb-5 text-xxxl-serif">
          {getLicensePrice()}
        </div>
        <p className="font-semibold">Estimated cost per 30 days</p>
      </div>
    </div>
  );
};
