import Heading from "../../common/components/heading";
import React, { useState, useEffect } from "react";
import { Project, useProjectAPI } from "../../api/project_api";
import { useParams } from "react-router-dom";

function ProjectDescription() {
    const { id } = useParams<{ id: string }>();
    const projectApi = useProjectAPI();
    const [project, setProject] = useState<Project | null>(null);

    useEffect(() => {
        if (id) {
            projectApi.fetchProject({ id }).then((result) => {
                setProject(result);
            });
        }
    }, [id]);

    return (
        <div className="flex flex-col sm: px-5 sm: pt-24 md:px-48 md:py-52">
            <Heading as="h2">Project description</Heading>
            <p>{project?.description}</p>
        </div>
    );
}

export default ProjectDescription;
