import React from "react";
import { useAppSelector } from "../../hooks";
import { USER_TYPES } from "../../lib/constants";
import RightsHolderDashboard from "./artist_dashboard";
import CreatorDashboard from "../creator/creator_dashboard";

function Dashboard() {
  const { profile } = useAppSelector((state) => state.user);

  switch (profile.user_type) {
    case USER_TYPES.CREATOR.key:
      return <CreatorDashboard />;
    case USER_TYPES.RIGHTS_HOLDER.key:
      return <RightsHolderDashboard />;
    default:
      return <div>Dashboard coming soon</div>;
  }
}

export default Dashboard;
