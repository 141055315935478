import React, { useState } from "react";
import { useUserAPI } from "../../api/user.api";
import { useAppSelector } from "../../hooks";
import Heading from "../../common/components/heading";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../common/components/ui/form";
import { SubmitHandler, useForm } from "react-hook-form";
import { User } from "../../model/user";
import { Button } from "../../common/components/ui/button";
import { setUserProfile } from "../../user/userSlice";
import { useDispatch } from "react-redux";
import { Input } from "../../common/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../../common/components/ui/use_toast";
import { SelectDropdown } from "../../common/components/ui/select";
import { USER_TYPES } from "../../lib/constants";

export const RegisterAccountDetails = ({form, visible}: {form:any, visible: boolean}) => {
  return (
      <div style={{display: visible ? "block" : "none"}}>
          <h3 className="text-xxl-serif mb-[20px]">
              Account details
          </h3>
          <div className="register_account_info grid grid-cols-2 gap-4 gap-y-10">
              <FormField
                  control={form.control}
                  name="organization_name"
                  render={({field}) => (
                      <FormItem>
                          <FormLabel>Company Name</FormLabel>
                          <FormControl>
                              <Input placeholder="You company name" {...field} className="text-blackish"/>
                          </FormControl>
                          <FormMessage/>
                      </FormItem>
                  )}
              />
              <FormField
                  control={form.control}
                  name="organization_type"
                  render={({field}) => (
                      <FormItem>
                          <FormLabel>Company Type</FormLabel>
                          <FormControl>
                              <SelectDropdown
                                  field={field}
                                  placeholder="Select"
                                  variant="default"
                                  options={[
                                      {value: "agency", label: "Agency"},
                                      {value: "individual", label: "Individual"},
                                  ]}
                              />
                          </FormControl>
                          <FormMessage/>
                      </FormItem>
                  )}
              />
              <FormField
                  control={form.control}
                  name="full_name"
                  render={({field}) => (
                      <FormItem>
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                              <Input placeholder="Your name" {...field} className="text-blackish"/>
                          </FormControl>
                          <FormMessage/>
                      </FormItem>
                  )}
              />
              <FormField
                  control={form.control}
                  name="email"
                  render={({field}) => (
                      <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                              <Input placeholder="Your email" {...field} className="text-blackish"/>
                          </FormControl>
                          <FormMessage/>
                      </FormItem>
                  )}
              />
          </div>
      </div>
  );
};
export default RegisterAccountDetails;
