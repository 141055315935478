import React, { useEffect, useState } from "react";
import Heading from "../../common/components/heading";
import { ArtistPropertyList } from "../../common/components/artist_property_list";
import { useLicensablePropertyAPI } from "../../api/licensable_property_api";
import SubHeader from "../layouts/sub_header";

function RightsHolderDashboard() {
  const { fetchMyLicensableProperties } = useLicensablePropertyAPI();
  const [licensableProperties, setLicensableProperties] = React.useState<any[]>([]);

  useEffect(() => {
    fetchMyLicensableProperties().then((data) => setLicensableProperties(data));
  }, []);

  return (
    <div className="page_content max-w-8xl max-auto">
      <SubHeader />
      <div className="page_header">
        <div className="flex gap-[60px] flex-col">
          <h1 className="text-xxxl-serif text-white">My talent</h1>
          <ArtistPropertyList properties={licensableProperties} />
        </div>
      </div>
    </div>
  );
}

export default RightsHolderDashboard;
