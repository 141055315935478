import React from "react";

interface GenerativeModel {
    id: string;
    name: string;
    generative_tag: string;
    model_type: string;
}

const VaultDropdown = ({
    modelData,
    selectedModelId,
    onSelect,
}: {
    modelData: GenerativeModel[];
    selectedModelId: string;
    onSelect: (model: GenerativeModel) => void;
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = e.target.value;
        const selectedModel = modelData.find((model) => model.id === selectedId);
        if (selectedModel) {
            onSelect(selectedModel);
        }
    };

    const groupedModels = modelData.reduce((groups: any, model: GenerativeModel) => {
        if (!groups[model.model_type]) {
            groups[model.model_type] = [];
        }
        groups[model.model_type].push(model);
        return groups;
    }, {});

    const selectedModel = modelData.find((model) => model.id === selectedModelId);

    return (
        <div className="relative inline-block w-full">
            <select
                className="appearance-none w-full border-b-2 border-gray-300 py-2 pr-8 bg-white text-gray-700 focus:outline-none"
                // defaultValue={selectedModelId || ""}
                value={selectedModelId || ""}
                onChange={handleChange}
            >
                <option value="" disabled hidden>
                    Select an IP vault
                </option>
                {Object.keys(groupedModels).map((modelType) => (
                    <optgroup key={modelType} label={`Select ${modelType}`}>
                        {groupedModels[modelType].map((model: GenerativeModel) => (
                            <option key={model.id} value={model.id}>
                                {model.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg
                    className="w-4 h-4 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </div>
            {selectedModel && (
                <div className="absolute inset-y-0 left-0 flex items-center px-2 text-black">
                    {selectedModel.name}
                </div>
            )}
        </div>
    );
};

export default VaultDropdown;
