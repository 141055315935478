import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "../user/userSlice";
import projectsReducer from "../pages/project/projectsSlice";
import projectWizardReducer from "../pages/project/projectWizardSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    projects: projectsReducer,
    projectWizard: projectWizardReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
