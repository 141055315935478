import React, {useEffect, useState} from "react";
import {useUserAPI} from "../../api/user.api";
import {useAppSelector} from "../../hooks";
import {Form} from "../../common/components/ui/form";
import {SubmitHandler, useForm} from "react-hook-form";
import {User, UserProfile} from "../../model/user";
import {Button} from "../../common/components/ui/button";
import {setUserProfile} from "../../user/userSlice";
import {useDispatch} from "react-redux";
import {z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import {useToast} from "../../common/components/ui/use_toast";
import {USER_TYPES} from "../../lib/constants";
import RegisterAccountType from "./register_account_type";
import RegisterAccountDetails from "./register_account_details";
import {useNavigate} from "react-router-dom";
import "../home/home.css";
import "./register.css";

export const Register = ({heading}: { heading: string }) => {
    const {toast} = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {profile, status} = useAppSelector((state) => state.user);
    const {saveUserProfile, registerUser} = useUserAPI();
    const [step, setStep] = useState<number>(0);

    const initProfile = {
        ...profile,
        user_type: profile.user_type === "unknown" ? USER_TYPES.CREATOR.key : profile.user_type,
    };

    const formSchema = z.object({
        id: z.string(),
        user_type: z.string(),
        full_name: z.string().min(2, "Please enter your full name").max(64),
        email: z.string().email("Please enter a valid email address"),
        organization_type: z.string().optional(),
        organization_name: z.string().optional(),
    });

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [userTypeWatch, setUserTypeWatch] = useState<string | unknown>("");

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: initProfile,
    });

    useEffect(() => {
        form.reset(initProfile);
        const defaultUserType = profile.user_type === "unknown" ? USER_TYPES.CREATOR.key : profile.user_type;
        setUserTypeWatch(defaultUserType);
    }, [profile, form.reset]);

    useEffect(() => {
        const subscription = form.watch((value, {name}) => {
            setUserTypeWatch(value.user_type);
        });
        return () => subscription.unsubscribe();
    }, [form.watch]);

    const onSubmit: SubmitHandler<User> = (data) => {
        try {
            const profile_data:User = {full_name: data.full_name, email: data.email}
            if(data.id){
                profile_data.id = data.id
            }
            if(data.organization_name) {
                profile_data.organization_name = data.organization_name
            }
            if(data.organization_type) {
                profile_data.organization_type = data.organization_type
            }
            if(!data.user_type){
                setStep(0);
                toast({
                    title: "User Type Required",
                    description: "Please select a user type to continue.",
                });
                return;
            }else{
                profile_data.user_type = data.user_type;
            }
            setIsSubmitting(true);
            if(status === "uninitialized") {

                registerUser(profile_data).then((userProfile) => {
                    dispatch(setUserProfile(userProfile));
                    navigate("/register/complete")
                    setIsSubmitting(false);
                }).catch((error) => {
                    toast({
                        title: "Oops. Something went wrong.",
                        description: error.message,
                    });
                    setIsSubmitting(false);
                });
            }else {
                saveUserProfile(profile_data).then((userProfile) => {
                    dispatch(setUserProfile(userProfile));
                    if (profile.is_active) {
                        toast({
                            title: "Success!",
                            description: "Your profile has been updated.",
                        });
                        navigate("/dashboard");
                    } else {
                        navigate("/register/complete")
                    }
                    setIsSubmitting(false);
                }).catch((error) => {
                    toast({
                        title: "Oops.. Something went wrong.",
                        description: error.message,
                    });
                    setIsSubmitting(false);
                });
            }
        } catch (error: any) {
            setIsSubmitting(false);
            toast({
                title: "Oops... Something went wrong.",
                description: error.message,
            });
        }
    };

    return (
        <div className="page_content home_page">
            <div className="home_container p-[20px]">
            <div className="page_header">
                <div className="flex justify-between items-center mb-[60px]">
                    <h1 className="text-xxxxl-serif text-blackish">{heading}</h1>
                    <p className="text-m-sans text-blackish">{step + 1}/2</p>
                </div>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                        <RegisterAccountType form={form} visible={step === 0 }/>
                        <RegisterAccountDetails form={form} visible={step === 1 }/>
                        <div className="flex justify-end">
                            {step === 0 && <Button type="button" variant="primary-negative" onClick={() => setStep(1)}
                                                   disabled={!userTypeWatch}>Next</Button>}
                            {step === 1 && <Button type="submit" variant="primary-negative"
                                                   disabled={isSubmitting}>{heading === "Get started" ? "Sign Up" : "Update Profile"}</Button>}
                        </div>
                    </form>
                </Form>
            </div>
            </div>
        </div>
    );
};
export default Register;
