import React, { useMemo } from "react";
import { LicensableProperty } from "../model/licensable_property";
import { numberToHuman } from "../lib/utils";
import { SocialAccount } from "./components/social_account";
import "./licensable_property.css";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty;
  showSocialAccounts: boolean;
}

const LicensablePropertyCard = ({ licensableProperty, showSocialAccounts, ...props }: LicensablePropertyProps) => {
  const pricePill = useMemo(() => {
    if (licensableProperty.status === "unsigned") {
      return <div className="price-pill unsigned">UNSIGNED</div>;
    } else if (licensableProperty.status === "draft") {
      return <div className="price-pill draft">DRAFT</div>;
    }
    if (licensableProperty.cost_min || licensableProperty.cost_max) {
      return (
        <div className="price-pill">
          {licensableProperty.cost_min ? `$${numberToHuman(licensableProperty.cost_min)}` : ""}
          {licensableProperty.cost_max ? ` - $${numberToHuman(licensableProperty.cost_max)}` : ""}
        </div>
      );
    }
  }, [licensableProperty]);

  return (
    <div className="lic-prop-card hover:cursor-pointer" {...props}>
      <div
        className="lic-prop-card-inner"
        style={{
          backgroundImage:
            "linear-gradient(180deg, rgba(20, 20, 20, 0.00) 40.22%, #141414 100%), url('" +
            (licensableProperty.profile_image || "/artist_default_image.png") +
            "')",
        }}
      />
      <div className="info-block">
        <div className="talent-profession">{licensableProperty.primary_professions}</div>
        <div className="talent-name">{licensableProperty.name}</div>
        {showSocialAccounts && (
          <div className="social-iconbar">
            {licensableProperty.social_accounts
              ?.filter((it) => it.followers && it.followers > 0)
              .map((account) => {
                return (
                  <SocialAccount
                    key={account.provider}
                    provider={account.provider}
                    followers={account.followers}
                    usage="card"
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LicensablePropertyCard;
