import {SpeakerWaveIcon, VideoCameraIcon} from "@heroicons/react/24/outline";
import {
    AssetApprovedIcon,
    AssetDeniedIcon,
    DropdownApproveIcon,
    DropdownDenyIcon,
    DropdownDownloadIcon,
    DropdownViewIcon,
    ImageIcon
} from "../../../common/icons/icons";
import {PROJECT_STATUS} from "../../../lib/constants";
import IconDropdownMenu from "../../../common/components/ui/icon_dropdown_menu";
import React, {useMemo} from "react";
import {Project, ProjectFile} from "../../../api/project_api";
import {humanizeName} from "../../../lib/utils";
import "./project_file_card.css";

interface ProjectFileCardProps {
    file: ProjectFile;
    project: Project;
    isRightsHolder: boolean;
    allowFileActions: boolean;
    updateAssetStatus: (projectId: string, fileId: string, status: string) => void;
    onViewAsset: (file: ProjectFile) => void;
    onClick: () => void;
}

export const ProjectFileCard = ({
                                    file,
                                    project,
                                    isRightsHolder,
                                    allowFileActions,
                                    updateAssetStatus,
                                    onViewAsset,
                                    onClick
                                }: ProjectFileCardProps) => {
    const [showFileViewer, setShowFileViewer] = React.useState(false);

    const assetActions = (isRightsHolder && allowFileActions)
        ? [
            {value: "Approve", icon: <DropdownApproveIcon/>},
            {value: "Deny", icon: <DropdownDenyIcon/>},
            {value: "Download", icon: <DropdownDownloadIcon/>},
            {value: "View", icon: <DropdownViewIcon/>},
        ]
        : [
            {value: "Download", icon: <DropdownDownloadIcon/>},
            {value: "View", icon: <DropdownViewIcon/>},
        ];

    function handleAssetAction(action: string, file: ProjectFile) {
        if (!file.project_file_id || !project.id) return;
        switch (action) {
            case "Approve":
                updateAssetStatus(project.id, file.project_file_id, PROJECT_STATUS.APPROVED.key);
                break;
            case "Deny":
                updateAssetStatus(project.id, file.project_file_id, PROJECT_STATUS.CHANGES_REQUESTED.key);
                break;
            case "Download":
                window.open(file.permalink, "_blank");
                break;
            case "View":
                onViewAsset(file);
                break;
        }
    }

    const image = useMemo(() => {
        switch (file.file_type) {
            case "audio":
                return <SpeakerWaveIcon height={20}/>;
            case "video":
                return <VideoCameraIcon height={20}/>;
            case "image":
            case "unknown":
                return <img className="w-28 h-20 rounded-xl object-cover object-top" src={file.permalink} alt=""/>;
            case "document":
                return <img className="w-28 h-20 rounded-xl object-cover object-top"
                            src="/images/sign_document_icon_white_110x80.png" alt=""/>;
        }
    }, [file.file_type]);

    return <div className="project_file_card">
        <div className="project_card_asset_image" onClick={onClick}>{image}</div>
        <div className="project_card_asset_name" onClick={onClick}>
            <div className="px-4">
                {/* icon */}
                <div className="h-6 w-6 bg-gray-700 rounded-full flex items-center justify-center">
                    <ImageIcon color="fill-brand-700"/>
                </div>
                {/* assest type caps */}
                <div className="text-xxs text-white flex gap-2 items-center">
                    {file.file_type?.toUpperCase()}
                </div>
                {/* heading */}
                <div className="text-l-serif">
                    {humanizeName(file?.name || "")}
                </div>
            </div>

        </div>
        <div className="project_card_asset_status" onClick={onClick}>
            {file.status === PROJECT_STATUS.APPROVED.key && (
                <div className="flex  gap-2 w-fit items-center">
                    Asset approved{" "}
                    <AssetApprovedIcon/>
                </div>
            )}
            {file.status === PROJECT_STATUS.CHANGES_REQUESTED.key && (
                <div className="flex gap-2 w-fit items-center">
                    Asset denied{" "}
                    <AssetDeniedIcon/>
                </div>
            )}


        </div>
        <div className="project_card_asset_actions">
            <IconDropdownMenu dropdownOptions={assetActions} handleSelect={handleAssetAction} file={file}/>
        </div>
    </div>
}