import React, {useCallback, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import {UploadedFile, useFileAPI} from "../../../api/file_api";
import ProgressBar from "./progress_bar";
import {Button} from "./button";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import "./file_manager.css";
import cn from "../../../lib/utils";

interface FileManagerProps {
    text?: string;
    files?: File[];
    uploadedFiles?: UploadedFile[];
    onFileChange?: (files: File[]) => void;
    onFileRemove?: (file: UploadedFile) => void;
    uploadOnSelect: boolean;
    onUploadedFilesChange: (files: UploadedFile[]) => void;
    objectType: string;
    objectId?: string;
    multiple?: boolean;
    isPublic?: boolean;
    hintText?: string;
    className?: string;
    theme?: string;
    aspectRatio?: "tall" | "wide" | "square" | undefined;
    backgroundImage?: string;
    usage?: string
}

const FileManager = ({
                         text,
                         files,
                         onFileChange,
                         onFileRemove,
                         uploadedFiles,
                         uploadOnSelect,
                         onUploadedFilesChange,
                         objectType,
                         objectId,
                         isPublic,
                         multiple,
                         hintText,
                         className,
                         theme,
                         aspectRatio,
                         backgroundImage,
                         usage,
                     }: FileManagerProps) => {
    const {uploadFile} = useFileAPI();
    const uploadInput = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    const handleFileChosen = async (files: File[]) => {
        if (uploadOnSelect) {
            setUploading(true);
            uploadFile(
                {
                    files: files,
                    is_public: isPublic === undefined ? true : isPublic,
                    object_id: objectId,
                    object_type: objectType,
                    generate_thumbnail: true,
                    usage: usage,
                },
                (progressEvent) => {
                    console.log("Upload progress: " + JSON.stringify(progressEvent));
                    setUploadProgress((progressEvent.progress || 0) * 100);
                }
            )
                .then((response) => {
                    setUploading(false);
                    if (!uploadedFiles || typeof uploadedFiles[Symbol.iterator] !== "function") {
                        onUploadedFilesChange(response);
                    } else {
                        onUploadedFilesChange([...uploadedFiles, ...response]);
                    }
                })
                .catch((e) => {
                    setUploading(false);
                    console.error(e);
                });
        } else {
            if (onFileChange) {
                onFileChange(files);
            }
        }
    };

    const onDrop = useCallback((acceptedFiles: any) => {
        handleFileChosen(acceptedFiles);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
    const localOnFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            handleFileChosen(Array.from(files));
        }
    };

    const themeCss = theme && theme === "dark" ? "bg-gray-900 text-white" : "";
    const themeButtonVariant = theme && theme === "dark" ? "primary-negative-invert" : "primary-negative";

    return (
        // <div className="flex flex-col min-h-[80px] lg:w-[876px]">
        <div
            className={cn("file-upload-component " + (isDragActive ? "drag-active" : ""), className, aspectRatio ? `aspect-ratio-${aspectRatio}` : "")}
            onClick={() => uploadInput.current?.click()}
            {...getRootProps()}
            {...(backgroundImage ? {
                style: {
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }
            } : {})}
        >
            <label
                htmlFor="dropzone-file"
                className={cn(
                    "flex flex-col items-center justify-center w-full rounded-xl border-none h-full text-brand-black",
                    themeCss
                )}
            >
                <div
                    className={"file-upload-component-instructions flex flex-col items-center justify-center p-5 " + (backgroundImage ? "text-on-image" : "")}>
                    <p className="mb-2 text-xl font-semibold md:block hidden">{text || "Drag + drop here"}</p>
                    {hintText && <p className="text-base font-semibold md:block hidden">{hintText}</p>}
                    <p className="mb-4 mt-2 text-sm md:block hidden">- or -</p>
                    <Button type="button" variant={themeButtonVariant} className="gap-2">
                        <>
                            Browse <ArrowRightIcon height={24}/>
                        </>
                    </Button>
                    {uploading && (
                        <ProgressBar progress={uploadProgress}
                                     taskName={files && files.length > 0 ? files[0].name : ""}/>
                    )}
                </div>
                <input
                    id="dropzone-file"
                    name="files"
                    type="file"
                    className="hidden"
                    multiple={multiple}
                    onChange={localOnFileChange}
                    {...getInputProps()}
                    ref={uploadInput}
                />
            </label>
        </div>
        // </div>
    );
};

export default FileManager;
