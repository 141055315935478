import React from "react";
import { Project, ProjectFile } from "../../../api/project_api";
import Heading from "../../../common/components/heading";
import Card from "../../../common/components/card";
import { User } from "../../../model/user";
import { USER_TYPES } from "../../../lib/constants";
import { Button } from "../../../common/components/ui/button";
import { ArrowDownIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import { Badge } from "../../../common/components/ui/badge";
import FileViewer from "../../../common/components/file_viewer";
import { formatDateString, formatTimeString } from "../../../lib/utils";

const ProjectAssetHistory = ({ project, file, user }: { project: Project ,file: ProjectFile; user: User }) => {
  const isCreator = user.user_type === USER_TYPES.CREATOR.key;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row justify-between">
        <Heading as="h3">File history</Heading>
        <div className="">
          <div className="flex flex-shrink gap-2">
            <Button className="gap-2" size="xs" variant="outline">
              <>
                See all <ArrowDownIcon />
              </>
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3 max-h-[760px] pr-0">
        <Card key={`${file.name}`}>
          <div className="flex justify-between items-center">
            <div className="flex flex-wrap items-center gap-4">
              <FileViewer file={file} project={project}  showActions={false}>
                <img className="h-20 w-30 rounded-xl" src={file.permalink} alt="" />
              </FileViewer>
              <div className="space-y-2">
                <Badge variant="micro">Notification</Badge>
                <p className="text-base">File uploaded</p>
              </div>
            </div>
            <div className="text-right text-xs gap-1 flex flex-col">
              <div>{`${formatDateString(file.created_at)}`}</div>
              <div>{`${formatTimeString(file.created_at)}`}</div>
            </div>
          </div>
        </Card>
      </div>
      {isCreator && (
        <div className="text-right pt-4">
          <Button className="gap-2">
            <>
              Add more assets <ArrowRightIcon />
            </>
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProjectAssetHistory;
