import React from "react";
import WizardStep from "../../../common/components/wizard_panel";
import { FormLabel } from "../../../common/components/ui/form";
import { Input } from "../../../common/components/ui/input";
import { FIELDS } from "../../../lib/fields";
import { Errors } from "./errors";
import { numberWithCommas } from "../../../lib/utils";

export const ArtistSocial = ({ form, errors }: { form: any; errors: any }) => {
  return (
    <WizardStep>
      <div className="md:grid md:grid-cols-3 gap-20 mb-10 text-white">
        {FIELDS.SOCIAL_ACCOUNTS.options.map((s) => (
          <div key={s.key} className="mt-10">
            <FormLabel className="text-brand-black">{s.label}</FormLabel>
            <Input
              {...form.register(`${FIELDS.SOCIAL_ACCOUNTS.key}.${s.key}.external_id`, {})}
              placeholder="@handle or name"
              className="bg-white text-brand-black"
            />
            <Input
              {...form.register(`${FIELDS.SOCIAL_ACCOUNTS.key}.${s.key}.followers`, {
                valueAsNumber: true,
              })}
              onChange={(e) =>
                form.setValue(`${FIELDS.SOCIAL_ACCOUNTS.key}.${s.key}.followers`, numberWithCommas(e.target.value))
              }
              className="bg-white text-brand-black"
              placeholder="Follower count"
              max={999_999_999}
            />
          </div>
        ))}
      </div>
      <Errors errors={errors} />
    </WizardStep>
  );
};
