import React from "react";
import WizardStep from "../../../common/components/wizard_panel";
import { FormControl, FormField, FormItem } from "../../../common/components/ui/form";
import { Errors } from "./errors";
import { Textarea } from "../../../common/components/ui/textarea";
import { FIELDS } from "../../../lib/fields";

export const ArtistIntroductionStep = ({ form, errors }: { form: any; errors: any }) => {
  return (
    <WizardStep>
      <FormField
        control={form.control}
        name={FIELDS.INTRODUCTION.key}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormControl>
              <Textarea
                {...field}
                placeholder="Introduce the artist"
                className="block w-full text-lg rounded-md border-2 border-gray-800 p-8 bg-gray-100 text-brand-black outline-none h-64 font-sans"
              />
            </FormControl>
            <div className="text-xs text-gray-400">
              Supports limited{" "}
              <a
                className="text-gray-500 underline"
                href="https://www.markdownguide.org/basic-syntax/"
                target="_blank"
                rel="noreferrer"
              >
                markdown
              </a>
            </div>
            <Errors errors={errors} />
          </FormItem>
        )}
      />
    </WizardStep>
  );
};
