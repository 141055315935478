import { LicensableProperty } from "../../model/licensable_property";
import React from "react";
import { NavLink } from "react-router-dom";
import Heading from "./heading";
import { AlertCircleIcon } from "lucide-react";
import { Badge } from "./ui/badge";
import { PROJECT_STATUS } from "../../lib/constants";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty;
}

const ArtistPropertyCard = ({ licensableProperty }: LicensablePropertyProps) => {
  const projectsCountByStatus = (status: string) =>
    licensableProperty.projects?.filter((p) => p.status === status)?.length ?? 0;

  const nameParts = (part: string) => {
    const parts: string[] | undefined = licensableProperty.name?.split(" ");
    if (parts?.length) {
      if (part === "first") return parts[0];
      parts.shift();
      return parts.join(" ");
    }
    return "--";
  };

  const talenImage = licensableProperty.profile_image ? licensableProperty.profile_image : "/bit-gfx1.svg";

  return (
    <NavLink to={`/lp/${licensableProperty.id}`}>
      <article className="relative aspect-[3/4] isolate flex flex-col justify-end overflow-hidden rounded-xl px-8 pb-2 pt-80 sm:pt-48 lg:pt-80 hover:opacity-95">
        <img src={talenImage} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
        <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black/80 via-black/10"></div>
        <div className="absolute inset-0 -z-10 bg-gradient-to-b from-black/30 via-black/10"></div>
        <div className="absolute right-4 top-4 flex gap-2 items-center">
          {!licensableProperty.is_active ? (
            <Badge variant="micro">
              <>
                <AlertCircleIcon height={16} />
                Publish pending
              </>
            </Badge>
          ) : (
            <>
              {licensableProperty.projects?.length ? (
                <>
                  {projectsCountByStatus(PROJECT_STATUS.CHANGES_REQUESTED.key) ? (
                    <Badge variant="micro">
                      {projectsCountByStatus(PROJECT_STATUS.CHANGES_REQUESTED.key)} change requests
                    </Badge>
                  ) : null}
                  {projectsCountByStatus(PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key) ? (
                    <Badge variant="micro" className="text-brand-black bg-brand-yellow">
                      {projectsCountByStatus(PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key)} pending
                    </Badge>
                  ) : null}
                </>
              ) : (
                <Badge variant="micro" className="text-white border-white">
                  No projects
                </Badge>
              )}
            </>
          )}
        </div>
        <Heading as="h5" className="mb-2 font-light h-5">
          {nameParts("last") && nameParts("first")}
        </Heading>
        <Heading as="h3" className="text-white font-sans font-semibold not-italic tracking-wide no-underline">
          {nameParts("last") ? nameParts("last") : nameParts("first")}
        </Heading>
      </article>
    </NavLink>
  );
};

export default ArtistPropertyCard;
