import React, { useState } from "react";
import { Project, ProjectMessage, useProjectAPI } from "../../../api/project_api";
import Heading from "../../../common/components/heading";
import Card from "../../../common/components/card";
import { User } from "../../../model/user";
import { Button } from "../../../common/components/ui/button";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Badge } from "../../../common/components/ui/badge";
import { Textarea } from "../../../common/components/ui/textarea";
import { Avatar } from "../../../common/components/ui/avatar";
import { useToast } from "../../../common/components/ui/use_toast";
import { formatDateString, formatTimeString } from "../../../lib/utils";

const ProjectMessages = ({ project, user }: { project: Project; user: User }) => {
  const [messages, setMessages] = useState<ProjectMessage[]>(project.messages || []);
  const [message, setMessage] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const { toast } = useToast();

  const projectApi = useProjectAPI();

  const getMessageAuthorName = (message: ProjectMessage) => {
    return message.from_user_full_name ?? user.full_name;
  };

  const handleSendMessage = async () => {
    if (!message.length) return null;
    setSending(true);
    const res = await projectApi.postProjectMessage({
      project_id: project.id,
      message,
    });
    const newMessage = {
      project_id: project.id,
      message,
      createdAt: new Date().toISOString(),
    };
    setMessages([...messages, newMessage]);
    setSending(false);
    setMessage("");
    toast({
      title: "Message sent!",
    });
    console.log("message", res);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row justify-between">
        <Heading as="h3">Messages</Heading>
      </div>
      <div className="max-h-[700px] overflow-y-auto space-y-4">
        {messages?.map((message, idx) => {
          return (
            <Card key={`${message.id}-${idx}`}>
              <div className="flex flex-row justify-between items-center ">
                <div className="flex gap-4 items-center place-items-start">
                  <Avatar name={getMessageAuthorName(message)} />
                  <div className="space-y-2">
                    <Badge variant="micro" className="w-28 flex justify-center px-0">
                      Notification
                    </Badge>
                    <div className="text-base">{message.message}</div>
                  </div>
                </div>
                <div className="text-right text-xs gap-1 flex flex-col">
                  {!message.created_at && "Just now"}
                  {message.created_at && (
                    <>
                      <div>{`${formatDateString(message.created_at)}`}</div>
                      <div>{`${formatTimeString(message.created_at)}`}</div>
                    </>
                  )}
                </div>
              </div>
            </Card>
          );
        })}
      </div>

      <div className="my-8 space-y-6">
        <p className=" font-bold text-lg">Write Message</p>
        <Textarea
          disabled={sending}
          rows={6}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="rounded-xl border-gray-800 text-lg bg-gray-900"
        ></Textarea>
        <Button className="float-right" onClick={() => handleSendMessage()} loading={sending} disabled={!message}>
          <>
            Send message
            <ArrowRightIcon />
          </>
        </Button>
      </div>
    </div>
  );
};

export default ProjectMessages;
