import React from "react";
import { LicensableProperty } from "../../model/licensable_property";
import { useNavigate } from "react-router-dom";
import LicensablePropertySearch from "../licensable-property/licensable_property_search";
import SubHeader from "../layouts/sub_header";

function CreatorDashboard() {
  const navigate = useNavigate();

  return (
    <div className="page_content">
        <SubHeader />
      <LicensablePropertySearch
        showSocialAccounts={true}
        onSelect={(licensableProperty: LicensableProperty) => navigate(`/lp/${licensableProperty.id}`)}
        defaultValue={null}
      />
    </div>
  );
}

export default CreatorDashboard;
