import React, {useEffect, useState} from "react";
import WizardGroup from "../../common/components/wizard_group";
import {useForm} from "react-hook-form";
import {Form} from "../../common/components/ui/form";
import Heading from "../../common/components/heading";
import {FIELDS} from "../../lib/fields";
import {ArtistSocial} from "./form/artist_social";
import {ArtistNameStep} from "./form/artist_name";
import {ArtistTypeStep} from "./form/artist_type";
import {ArtistIntroductionStep} from "./form/artist_introduction";
import {ArtistContentTopicsStep} from "./form/artist_content_topics";
import {ArtistPricingStep} from "./form/artist_pricing";
import {ArtistAddTrainingData} from "./form/artist_add_training_data";
import {ArtistTrainingDataAssets} from "./form/artists_training_data_assets";
import {ImageUpload} from "./form/image_upload";
import {useLicensablePropertyAPI} from "../../api/licensable_property_api";
import {LicensableProperty, Price} from "../../model/licensable_property";
import {useNavigate, useParams} from "react-router-dom";
import {SocialAccount} from "../../model/user";
import {Button} from "../../common/components/ui/button";
import {useToast} from "../../common/components/ui/use_toast";
import {v7 as uuidv7} from "uuid";


const ARTIST_PROFILE_STORAGE_KEY = "artist_profile";

const StepHeader = ({ stepName }: { stepName: string }) => {
  return (
    <div className="mb-6">
      <Heading as="h5" className="font-lg font-sans">
        Add talent
      </Heading>
      <Heading as="h2">{stepName}</Heading>
    </div>
  );
};

const mapFileType = (files: any) => {
  return files.map((file: any) => ({
    ...file,
    file_type: file.fileType,
  }));
};

const initValuesEmpty = {
  [FIELDS.NAME.key]: "",
  [FIELDS.TYPE.key]: "",
  [FIELDS.INTRODUCTION.key]: "",
  [FIELDS.CONTENT_TOPICS.key]: [],
  [FIELDS.PRICES.key]: [
    {
      price: 1000000,
      [FIELDS.LICENSE_TYPE.key]: FIELDS.LICENSE_TYPE.options[0]?.value, // Commercial
      currency: "USD",
      valid_days_from: 0,
      valid_days_to: 30,
    },
    {
      price: 1000000,
      [FIELDS.LICENSE_TYPE.key]: FIELDS.LICENSE_TYPE.options[1]?.value, // Personal
      currency: "USD",
      valid_days_from: 0,
      valid_days_to: 30,
    },
  ],
};

const ArtistWizard = ({ isEditing }: { isEditing?: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [errors, setErrors] = useState<object>({});
  const { toast } = useToast();

  const [initFormValues, setInitFormValues] = useState<any>(initValuesEmpty);
  const { createLicensableProperty, updateLicensableProperty, getById } = useLicensablePropertyAPI();
  const navigate = useNavigate();

  const buildInitValues = (lp: LicensableProperty) => {
    const social: any = {};
    if (lp.social_accounts) {
      lp.social_accounts.forEach((sa: SocialAccount) => {
        if (sa.provider) {
          social[sa.provider] = sa;
        }
      });
    }
    const commercialPrice = lp.prices?.find((p: Price) => p.license_type === "commercial");
    const personalPrice = lp.prices?.find((p: Price) => p.license_type === "personal");
    const prices = [
      {
        price: commercialPrice?.price ?? 0,
        [FIELDS.LICENSE_TYPE.key]: FIELDS.LICENSE_TYPE.options[0]?.value, // Commercial
        currency: commercialPrice?.currency ?? "USD",
        valid_days_from: commercialPrice?.valid_days_from ?? 0,
        valid_days_to: commercialPrice?.valid_days_to ?? 30,
      },
      {
        price: personalPrice?.price ?? 0,
        [FIELDS.LICENSE_TYPE.key]: FIELDS.LICENSE_TYPE.options[1]?.value, // Personal
        currency: personalPrice?.currency ?? "USD",
        valid_days_from: personalPrice?.valid_days_from ?? 0,
        valid_days_to: personalPrice?.valid_days_to ?? 30,
      },
    ];
    const _lp = {
      ...initValuesEmpty,
      id: lp.id ?? undefined,
      [FIELDS.NAME.key]: lp.name,
      [FIELDS.PROFILE_IMAGE.key]: lp.profile_image,
      [FIELDS.HERO_IMAGE.key]: lp.hero_image,
      [FIELDS.FILES.key]: mapFileType(lp.files),
      [FIELDS.TYPE.key]: lp.primary_professions,
      [FIELDS.INTRODUCTION.key]: lp.description,
      [FIELDS.CONTENT_TOPICS.key]: lp.content_topics,
      [FIELDS.PRICES.key]: prices,
      [FIELDS.SOCIAL_ACCOUNTS.key]: social,
    };
    return _lp;
  };

  useEffect(() => {
    try {
      if (isEditing && id) {
        getById(id).then((lp) => {
          // Reset the form w/ the LP
          form.reset(buildInitValues(lp));
        });
      } else if (!isEditing) {
        form.setValue("id", uuidv7());
      }
    } catch (error) {
      console.log("err", error);
    }
  }, [isEditing, id]);

  const form = useForm({
    // defaultValues: storedLP ?? initFormValues,
    defaultValues: initFormValues,
    // TODO - use resolver
  });

  const formValues = form.getValues();
  const formFiles = formValues?.files?.filter((file: any) => file.usage === "training");

  useEffect(() => {
    // Wholesale unset errors when the form changes. Re-validate on submit
    setErrors({});
  }, [form.watch]);

  const handleStepForward = (stepIdx: number) => {
    try {
      const data = form.getValues();

      if (validateFormData(data)) {
        setActiveStep(stepIdx);
      }
    } catch (error) {
      console.log("form validation error", error);
    }
  };

  const handleStepBackward = (stepIdx: number) => {
    setActiveStep(stepIdx);
  };

  const steps = [
    {
      field: FIELDS.NAME.key,
      heading: <StepHeader stepName="Talent name" />,
      component: <ArtistNameStep form={form} errors={errors} />,
      canAdvance: true,
    },
    {
      field: FIELDS.TYPE.key,
      heading: <StepHeader stepName="Talent type" />,
      component: <ArtistTypeStep form={form} errors={errors} />,
      canAdvance: true,
    },
    {
      field: FIELDS.INTRODUCTION.key,
      heading: <StepHeader stepName="Introduction" />,
      component: <ArtistIntroductionStep form={form} errors={errors} />,
      canAdvance: true,
    },
    {
      field: FIELDS.CONTENT_TOPICS.key,
      heading: <StepHeader stepName="Open to media with" />,
      component: <ArtistContentTopicsStep form={form} errors={errors} />,
      canAdvance: true,
    },
    {
      field: FIELDS.SOCIAL_ACCOUNTS.key,
      heading: <StepHeader stepName="Social accounts" />,
      component: <ArtistSocial form={form} errors={errors} />,
      canAdvance: true,
    },
    {
      field: FIELDS.PRICES.key,
      heading: <StepHeader stepName="Pricing" />,
      component: <ArtistPricingStep form={form} errors={errors} />,
      canAdvance: true,
    },
    {
      field: FIELDS.ADD_TRAINING_DATA.key,
      heading: <StepHeader stepName="Add training data" />,
      component: <ArtistAddTrainingData field={FIELDS.ADD_TRAINING_DATA.key} form={form} onUploadComplete={handleStepForward} />,
      canAdvance: true,
    },
    ...(formFiles && formFiles.length > 0 ? [{
      field: FIELDS.TRAINING_DATA_ASSETS.key,
      heading: <StepHeader stepName="Training data assets" />,
      component: <ArtistTrainingDataAssets form={form} errors={errors} formFiles={formFiles} addMoreAssets={handleStepBackward} />,
      canAdvance: true,
    }] : []),
    {
      field: FIELDS.PROFILE_IMAGE.key,
      heading: <StepHeader stepName="Upload a headshot" />,
      component: <ImageUpload key={`${FIELDS.PROFILE_IMAGE.key}`} form={form} errors={errors}
                              field={FIELDS.PROFILE_IMAGE.key} aspectRatio="tall" usage="profile"/>,
      canAdvance: true,
    },
    {
      field: FIELDS.HERO_IMAGE.key,
      heading: <StepHeader stepName="Upload a cover image" />,
      component: <ImageUpload key={`${FIELDS.HERO_IMAGE.key}`} form={form} errors={errors} field={FIELDS.HERO_IMAGE.key}
                              aspectRatio="wide" usage="hero"/>,
      canAdvance: true,
    },
  ];

  const validateFormData = (inputs: any) => {
    setErrors({});
    if (activeStep === steps.length - 1) {
      return true;
    }
    const step = steps[activeStep];
    if (!step.field) return true;
    switch (step.field) {
      case FIELDS.NAME.key:
      case FIELDS.TYPE.key:
        if (inputs[step.field].length < 3) {
          setErrors({ ...errors, [step.field]: `A ${step.field} for the artist is required` });
          return false;
        }
        break;
      case FIELDS.INTRODUCTION.key:
        if (inputs[step.field].length < 3) {
          setErrors({ ...errors, [step.field]: "A short introduction for the artist is required" });
          return false;
        }
        break;
      case FIELDS.PRICES.key:
        if (inputs[step.field].find((p: Price) => typeof p.price === "string")) {
          setErrors({ ...errors, [step.field]: "Ensure your prices are set before continuing" });
          return false;
        }
        if (inputs[step.field].find((p: Price) => p.price < 0)) {
          setErrors({ ...errors, [step.field]: "Price can not be negative" });
          return false;
        }
        break;
      case FIELDS.CONTENT_TOPICS.key:
        break;
      case FIELDS.SOCIAL_ACCOUNTS.key:
        if (Object.keys(inputs[step.field]).find((key) => inputs[step.field][key].followers >= 999_999_999)) {
          setErrors({ ...errors, [step.field]: "Please make sure all follower counts are less than 999,999,999" });
          return false;
        }
        break;

      default:
        break;
    }
    return true;
  };



  const onSaveAndExit = async () => {
    try {
      const formData = form.getValues();
      setSubmitting(true);
      const lpData: LicensableProperty = {
        id: formData?.id,
        name: formData[FIELDS.NAME.key],
        property_type: "real_person",
        hero_image: formData?.hero_image,
        profile_image: formData?.profile_image,
        files: formData?.files,
        description: formData[FIELDS.INTRODUCTION.key],
        primary_professions: formData[FIELDS.TYPE.key],
        content_topics: formData[FIELDS.CONTENT_TOPICS.key],
        social_accounts: [],
        prices: [],
      };
      if (formData[FIELDS.PRICES.key]) {
        Object.keys(formData[FIELDS.PRICES.key]).forEach((key: any) => {
          lpData.prices?.push({
            ...formData[FIELDS.PRICES.key][key],
          });
        });
      }
      Object.entries(formData[FIELDS.SOCIAL_ACCOUNTS.key]).forEach((accountKeys: any) => {
        const account = formData[FIELDS.SOCIAL_ACCOUNTS.key][accountKeys[0]];
        if (
          Object.hasOwn(account, "external_id") &&
          account["external_id"] !== undefined &&
          account["external_id"] !== ""
        ) {
          lpData?.social_accounts?.push({
            provider: accountKeys[0],
            external_id: account["external_id"] || undefined,
            followers: Number(account["followers"]) || undefined,
            link: account["link"] || undefined,
          });
        }
      });
      let res: any;
      if (lpData.id) {
        res = await updateLicensableProperty(lpData);
      } else {
        res = await createLicensableProperty(lpData);
      }
      const t = setTimeout(() => {
        toast({
          title: "Saved!",
          description: `Profile for ${lpData.name} saved.`,
        });
        navigate(`/lp/${res.id}`);
        setSubmitting(false);
        clearTimeout(t);
      }, 1000);
    } catch (error: any) {
      setSubmitting(false);
      setErrors(error.message);
      console.log("err", error);
    }
  };

  const onSubmit = async (formData: any) => {
    try {
      localStorage.setItem(ARTIST_PROFILE_STORAGE_KEY, JSON.stringify(formData));
      if (activeStep === steps.length - 1 && !submitting) {
        onSaveAndExit();
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <div className="pt-48 mx-auto max-w-8xl px-4">
      {isEditing && (
        <Button
          variant="primary-negative"
          className="z-50 absolute top-5 right-20"
          onClick={() => onSaveAndExit()}
          loading={submitting}
        >
          Save & exit
        </Button>
      )}
      <Form {...form}>
        <form id="artistEditForm" onSubmit={form.handleSubmit(onSubmit)}>
          <WizardGroup
            style={{ minHeight: "500px" }}
            panels={steps}
            activePanel={activeStep}
            onPanelChange={() => console.log("onPanelChange")}
            onSubmit={() => onSubmit(form.getValues())}
            next={handleStepForward}
            previous={handleStepBackward}
            submitting={submitting}
          />
          {/* <div className="mt-20 text-gray-700">{JSON.stringify(form.getValues())}</div> */}
        </form>
      </Form>
    </div>
  );
};

export default ArtistWizard;
