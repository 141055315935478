import React, {useState} from "react";
import Heading from "../../common/components/heading";
import {FormControl, FormField, FormItem} from "../../common/components/ui/form";
import CardRadioGroup from "../../common/components/card_radio_group";
import {USER_TYPES_ARRAY} from "../../lib/constants";

export const RegisterAccountType = ({form, visible}: { form: any, visible: boolean }) => {
    const userTypeWatch = form.watch("user_type");
    return (
        <div style={{display: visible?"block":"none"}} className="register_account_type">
            <h3 className="text-xxl-serif mb-[20px]">
                Account type
            </h3>
            <FormField
                control={form.control}
                name="user_type"
                render={({field}) => (
                    <FormItem>
                        <FormControl>
                            <CardRadioGroup field={field} options={USER_TYPES_ARRAY} activeOption={userTypeWatch} theme="lite" className={"radiogroup"}/>
                        </FormControl>
                    </FormItem>
                )}
            />
        </div>
    );
};
export default RegisterAccountType;
