import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLicensablePropertyAPI } from "../../api/licensable_property_api";
import { LicensableProperty } from "../../model/licensable_property";
import Heading from "../../common/components/heading";
import { useAppSelector } from "../../hooks";
import { LPHeading } from "./components/licensable_property_heading";
import { LPFAQ } from "./components/licensable_property_faq";
import { LPPricing } from "./components/licensable_property_pricing";
import { LPActions } from "./components/licensable_property_actions";
import { LPContentTopics } from "./components/licensable_property_content_topics";
import { LPDescription } from "./components/licensable_property_description";
import { useToast } from "../../common/components/ui/use_toast";
import { Button } from "../../common/components/ui/button";
import { ImageIconNoCircle, AudioIconNoCircle, VideoIconNoCircle, DocumentIconNoCircle } from "../../common/icons/icons";


export const LicensablePropertyProfile = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { id } = useParams<{ id: string }>();
  const { profile } = useAppSelector((state) => state.user);
  const LPApi = useLicensablePropertyAPI();
  const [licensableProperty, setLicensableProperty] = useState<LicensableProperty | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [licenseType, setLicenseType] = useState<"commercial" | "personal" | "expanded">("commercial");
  const coverImage = licensableProperty?.hero_image || require("./cover_image.jpg");
  const [trainingData, setTrainingData] = useState<any[]>();

  useEffect(() => {
    try {
      if (id && LPApi && !licensableProperty) {
        LPApi.getById(id).then((lp) => {
          setLicensableProperty(lp);

          const files = mapFileType(lp.files);
          const fileTypeCounts: Record<string, any> = {};

          files.forEach((file: any) => {
            if (file.usage === "training" && file.file_type) {

              const fileType = file.file_type;
              if (!fileTypeCounts[fileType]) {
                fileTypeCounts[fileType] = {
                  fileType,
                  count: 0,
                  icon: getIconForFileType(fileType),
                };
              }
              fileTypeCounts[fileType].count += 1;
            }

          });

          const trainingData = Object.values(fileTypeCounts);
          setTrainingData(trainingData);

        });
      }
    } catch (error) {
      console.log("err", error);
    }
  }, [id, LPApi, licensableProperty, setLicensableProperty]);

  const mapFileType = (files: any) => {
    return files.map((file: any) => {
      const { fileType, ...rest } = file;
      return {
        ...rest,
        file_type: fileType
      }
    })
  }

  const getIconForFileType = (fileType: string) => {
    switch (fileType) {
      case "image":
        return <ImageIconNoCircle />;
      case "video":
        return <VideoIconNoCircle />;
      case "audio":
        return <AudioIconNoCircle />;
      case "document":
        return <DocumentIconNoCircle />;
    }
  }


  const handlePublish = () => {
    setIsLoading(true);
    try {
      LPApi.updateLicensableProperty({ ...licensableProperty, is_active: true }).then((lp) => {
        setLicensableProperty(lp);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      console.log("err", error);
    }
  };

  const handleEdit = () => {
    if (licensableProperty) {
      navigate(`/lp/${licensableProperty.id}/edit`);
    }
  };

  const handleDelete = async () => {
    if (licensableProperty) {
      const lpName = licensableProperty.name;
      // TODO need to handled failed requests
      const res = await LPApi.deleteLicensableProperty(licensableProperty);
      toast({
        title: "Success!",
        description: `Profile for ${lpName} deleted.`,
      });
      navigate("/dashboard");
    }
  };

  if (!licensableProperty) {
    return (
      <div
        className="bg-black text-gray-700 flex w-full h-100vh justify-center items-center"
        style={{ height: "100vh" }}
      >
        <Heading as="h3">Loading...</Heading>
      </div>
    );
  }

  return (
    <>
      <div className="relative isolate overflow-hidden">
        {coverImage && (
          <img
            src={coverImage}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
        )}
        <div className="mx-auto py-32 sm:py-48 lg:py-64 flex justify-center items-center">
        </div>
      </div>

      {!licensableProperty?.hero_image && (<div className="flex mx-auto px-10 py-5 mt-20 justify-between sm:max-w-7xl max-w-6xl bg-white rounded-xl">
        <p className="font-semibold content-center">Upload a cover image to complete your profile</p>
        <Button variant="primary" onClick={handleEdit}>Upload image</Button>
      </div>
      )}
      <div className="mx-auto sm:max-w-7xl max-w-6xl flex-grow sm:px-8 lg:px-8 px-4">
        <div className="grid md:grid-cols-2 gap-10 pt-20">
          <div className="text-white flex flex-col gap-20">
            <LPHeading licensableProperty={licensableProperty} />
            <LPDescription licensableProperty={licensableProperty} />
            <div className="hidden md:block">
              <LPFAQ licensableProperty={licensableProperty} />
            </div>
          </div>
          <div className="bg-gray-900 flex flex-col gap-4 p-10 pt-14 rounded-3xl text-white">
            <LPActions
              isLoading={isLoading}
              profile={profile}
              handlePublish={handlePublish}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              licensableProperty={licensableProperty}
            />
            <LPPricing licensableProperty={licensableProperty} onLicenseTypeChange={setLicenseType} />
            <LPContentTopics licensableProperty={licensableProperty} />
            <div className="flex flex-col gap-4 text-center sm:my-10">
              <Heading as="h5" className="text-center font-sans not-italic font-semibold">
                Training data {trainingData && trainingData.length > 0 && "available"}
              </Heading>
              {
                trainingData && trainingData.length > 0 && (
                  <div className="flex flex-wrap justify-center">
                    {trainingData.map(({ fileType, count, icon }) => (
                      <div key={fileType} className="flex justify-center uppercase text-xxs font-semibold mx-2">
                        {icon}{count} {fileType}{count > 1 && "s"}
                      </div>
                    ))}
                  </div>
                )
              }
              {
                !(trainingData && trainingData.length > 0) && (
                  <p className="text-xs">No training data available at this time</p>
                )
              }
            </div>
          </div>
          <div className="text-white block md:hidden">
            <LPFAQ licensableProperty={licensableProperty} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LicensablePropertyProfile;
