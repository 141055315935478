import React, { HTMLAttributes } from "react";
import { Checkbox } from "./ui/checkbox";
import { FormControl, FormItem } from "./ui/form";
import { Label } from "./ui/label";

interface ButtonCheckboxGroupProps extends HTMLAttributes<HTMLHeadingElement> {
  children?: React.ReactNode;
  options: any[];
  field: any;
}
const ButtonCheckboxGroup = ({ children, ...props }: ButtonCheckboxGroupProps) => {
  const { options, field } = props;
  const itemChecked = (fieldValue: string | [], optionValue: string) => {
    if (Array.isArray(fieldValue)) {
      return fieldValue.find((v: { name: string }) => v.name === optionValue);
    }
    return fieldValue === optionValue;
  };

  return (
    <div className="flex flex-row flex-wrap gap justify-start">
      {options.map((item) => {
        return (
          <FormItem key={item.value} className="items-center space-x-3 space-y-3 h-[75px]">
            <FormControl>
              <>
                <Checkbox
                  id={item.value}
                  className="sr-only peer"
                  checked={itemChecked(field.value, item.value)}
                  onCheckedChange={(checked: boolean) => {
                    return checked
                      ? field.onChange([...field.value, { name: item.value, is_allowed: true }])
                      : field.onChange(
                          field.value?.filter((value: { name: string }) => {
                            return value.name !== item.value;
                          })
                        );
                  }}
                />

                <Label
                  htmlFor={item.value}
                  className="text-brand-black text-lg font-sanserif font-semibold not-italic text-nowrap rounded-full px-6 py-4 m-0 border hover:cursor-pointer peer-data-[state=checked]:border-brand-yellow hover:cursor-pointer  peer-data-[state=checked]:bg-brand-yellow [&:has([data-state=checked])]:bg-brand-yellow peer-data-[state=checked]:text-black group"
                >
                  {item.label}
                </Label>
              </>
            </FormControl>
          </FormItem>
        );
      })}
    </div>
  );
};
export default ButtonCheckboxGroup;
