import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {LicensableProperty, NameValuePair} from "@src/model/licensable_property";
import LicensablePropertyList from "../../common/licensable_property_list";
import {useLicensablePropertyAPI} from "../../api/licensable_property_api";
import {debounce} from "lodash";
import cn from "../../lib/utils";

interface LicensablePropertySearchProps {
  showSocialAccounts: boolean;
  onSelect: (licensableProperty: LicensableProperty) => void;
  defaultValue?: LicensableProperty | null;
  theme?: "dark" | "light";
}
export const LicensablePropertySearch = ({
  showSocialAccounts,
  onSelect,
  defaultValue,
  theme = "dark"
}: LicensablePropertySearchProps) => {
  const defaultValueRef = useRef<LicensableProperty | null | undefined>(defaultValue);
  const { search, getById } = useLicensablePropertyAPI();
  const [searchText, setSearchText] = React.useState<string>(defaultValue?.name || "");
  const [licensableProperties, setLicensableProperties] = React.useState<LicensableProperty[]>(
    defaultValue ? [defaultValue] : []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    debouncedSearch(event.target.value, [], 20, 0);
  };
  const doSearch = useCallback(
    (searchText: string, criteria: NameValuePair[], limit: number, offset: number) => {
      search(searchText, criteria, limit, offset).then((data) => {
        if (defaultValueRef.current === null) {
          setLicensableProperties(data);
        }
        defaultValueRef.current = null;
      });
    },
    [search, setLicensableProperties, defaultValueRef]
  );
  const debouncedSearch = useMemo(() => {
    return debounce(doSearch, 500);
  }, [doSearch]);

  useEffect(() => {
    if (defaultValue == null) {
      doSearch("", [], 20, 0);
    } else {
      setSearchText(defaultValue.name || "");
      defaultValueRef.current = defaultValue;
      if (defaultValue.id) {
        getById(defaultValue.id).then((data) => {
          setLicensableProperties([data]);
          setSearchText(data.name || "");
        });
      }
    }
  }, [defaultValue]);

  const onItemSelected = useCallback(
    (licensableProperty: LicensableProperty) => {
      setLicensableProperties(licensableProperties.filter((it) => it.id === licensableProperty.id));
      onSelect(licensableProperty);
    },
    [onSelect, licensableProperties]
  );

  return (
    <>
      <div className="page_header">
        <div>
          <input
            className={cn("wizard-text-input focus:border-b-brand-yellow", (theme === "dark"? "text-white": "text-gray-900"))}
            style={{ maxWidth: "1160px" }}
            type="text"
            placeholder="Search by keyword"
            aria-label="Search by keyword"
            value={searchText}
            onChange={handleSearchChange}
          />
          {/*<div className="text-white text-xs font-normal mt-2">*/}
          {/*    Match my needs with the perfect talent{" "}*/}
          {/*    <svg*/}
          {/*        xmlns="http://www.w3.org/2000/svg"*/}
          {/*        width="20"*/}
          {/*        height="20"*/}
          {/*        viewBox="0 0 20 20"*/}
          {/*        fill="none"*/}
          {/*        className="inline"*/}
          {/*    >*/}
          {/*        <path*/}
          {/*            fillRule="evenodd"*/}
          {/*            clipRule="evenodd"*/}
          {/*            d="M10.293 3.29303C10.4805 3.10556 10.7348 3.00024 11 3.00024C11.2652 3.00024 11.5195 3.10556 11.707 3.29303L17.707 9.29303C17.8945 9.48056 17.9998 9.73487 17.9998 10C17.9998 10.2652 17.8945 10.5195 17.707 10.707L11.707 16.707C11.5184 16.8892 11.2658 16.99 11.0036 16.9877C10.7414 16.9854 10.4906 16.8803 10.3052 16.6948C10.1198 16.5094 10.0146 16.2586 10.0123 15.9964C10.01 15.7342 10.1108 15.4816 10.293 15.293L14.586 11H3C2.73478 11 2.48043 10.8947 2.29289 10.7071C2.10536 10.5196 2 10.2652 2 10C2 9.73481 2.10536 9.48046 2.29289 9.29292C2.48043 9.10539 2.73478 9.00003 3 9.00003H14.586L10.293 4.70703C10.1055 4.5195 10.0002 4.26519 10.0002 4.00003C10.0002 3.73487 10.1055 3.48056 10.293 3.29303Z"*/}
          {/*            fill="white"*/}
          {/*        />*/}
          {/*    </svg>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="mt-4 p-4 flex justify-end gap-2">
        {/*    <button*/}
        {/*        type="button"*/}
        {/*        className="rounded-full text-xs pl-4 pr-4 pt-2 pb-2 bg-black border border-gray-600 text-white"*/}
        {/*    >*/}
        {/*        Sort By*/}
        {/*        <svg*/}
        {/*            xmlns="http://www.w3.org/2000/svg"*/}
        {/*            width="16"*/}
        {/*            height="16"*/}
        {/*            viewBox="0 0 21 20"*/}
        {/*            fill="none"*/}
        {/*            className="inline ml-2 align-middle"*/}
        {/*        >*/}
        {/*            <path*/}
        {/*                d="M17.5731 8.04021L11.3868 13.7862C11.1068 14.0462 10.7393 14.0462 10.4593 13.7862L4.2731 8.04021C4.05435 7.83221 3.4231 7.37458 3.4231 6.99758C3.4231 6.12658 4.56185 5.71321 5.0606 6.16821L10.9231 11.6152L16.7856 6.16821C17.2843 5.71321 18.4231 6.12658 18.4231 6.99758C18.4231 7.37458 17.7918 7.83221 17.5731 8.04021Z"*/}
        {/*                fill="white"*/}
        {/*            />*/}
        {/*        </svg>*/}
        {/*    </button>*/}
        {/*    <button*/}
        {/*        type="button"*/}
        {/*        className="rounded-full text-xs pl-4 pr-4 pt-2 pb-2 bg-black border border-gray-600 text-white"*/}
        {/*    >*/}
        {/*        Filter*/}
        {/*        <svg*/}
        {/*            xmlns="http://www.w3.org/2000/svg"*/}
        {/*            width="12"*/}
        {/*            height="12"*/}
        {/*            viewBox="0 0 12 12"*/}
        {/*            fill="none"*/}
        {/*            className="inline ml-2 align-middle"*/}
        {/*        >*/}
        {/*            <path*/}
        {/*                fillRule="evenodd"*/}
        {/*                clipRule="evenodd"*/}
        {/*                d="M5.1554 0.923077C5.34556 0.384923 5.85879 0 6.46156 0C7.06525 0 7.57848 0.384923 7.76771 0.923077H12V1.84615H7.76679C7.57663 2.38431 7.06433 2.76923 6.46156 2.76923C5.85879 2.76923 5.34556 2.38431 5.1554 1.84615H0.923096V0.923077H5.1554ZM9.23079 7.38462C9.83356 7.38462 10.3459 6.99969 10.536 6.46154H12V5.53846H10.5369C10.3477 5.00031 9.83448 4.61538 9.23079 4.61538C8.62802 4.61538 8.11571 5.00031 7.92556 5.53846H0.923096V6.46154H7.92556C8.11663 6.99785 8.62987 7.38462 9.23079 7.38462ZM4.99848 11.0769C4.8074 11.6151 4.2951 12 3.69233 12C3.08956 12 2.57633 11.6151 2.38617 11.0769H0.923096V10.1538H2.3871C2.57725 9.61569 3.08956 9.23077 3.69233 9.23077C4.29694 9.23077 4.80925 9.61569 4.9994 10.1538H12V11.0769H4.99848Z"*/}
        {/*                fill="white"*/}
        {/*            />*/}
        {/*        </svg>*/}
        {/*    </button>*/}
      </div>
      <LicensablePropertyList
        licensableProperties={licensableProperties}
        showSocialAccounts={showSocialAccounts}
        onItemSelected={onItemSelected}
      />
    </>
  );
};

export default LicensablePropertySearch;
