import React from "react";
import WizardStep from "../../../common/components/wizard_panel";
import { FormControl, FormField, FormItem } from "../../../common/components/ui/form";
import { Errors } from "./errors";
import ButtonRadioGroup from "../../../common/components/button_radio_group";
import { FIELDS } from "../../../lib/fields";

export const ArtistTypeStep = ({ form, errors }: { form: any; errors: any }) => {
  return (
    <WizardStep>
      <div className="flex items-center py-4 mb-4">
        <FormField
          control={form.control}
          name={FIELDS.TYPE.key}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <ButtonRadioGroup field={field} options={FIELDS.TALENT_TYPE.options} />
              </FormControl>
              <Errors errors={errors} />
            </FormItem>
          )}
        />
      </div>
    </WizardStep>
  );
};
