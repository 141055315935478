import React, { useState } from "react";
import { BlackCheck } from "../../../common/icons/icons";

interface Option {
    key: string;
    value: string;
    label: string;
    description: string;
    default?: boolean;
}

interface RadioCardGroupProps {
    options: Option[];
    label?: string;
    size: "small" | "large";
    selected: string | null;
    onSelect: (value: string) => void;
}

const AspectRatioCardGroup = ({ options, label, size, selected, onSelect }: RadioCardGroupProps) => {
    // const [selected, setSelected] = useState<string | null>(
    //     options.find(option => option.default)?.value || null
    // );

    // const handleSelect = (value: string) => {
    //     setSelected(value);
    // };

    const itemDimensions = size === "large"
        ? "w-full h-[103px]"
        : "w-full h-[57px]";

    return (
        <div className="space-y-4">
            {label && <p className="text-base">{label}</p>}

            {/* Responsive grid layout */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {options.map((option) => (
                    <div
                        key={option.key}
                        className={`relative cursor-pointer flex items-start justify-start border rounded-[20px] pl-3 pt-1 transition-colors ${selected === option.value
                            ? "bg-brand-yellow border-brand-yellow"
                            : "bg-white border-gray-300 hover:border-gray-400"
                            } ${itemDimensions}`}
                        onClick={() => onSelect(option.value)}
                    >
                        <div className="flex flex-col w-full px-2">
                            <span className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis w-full max-w-full">
                                {option.label}
                            </span>
                            <p className="text-sm text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis w-full max-w-full">
                                {option.description}
                            </p>
                        </div>

                        {selected === option.value && (
                            <div className="absolute top-4 right-2">
                                <BlackCheck />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AspectRatioCardGroup;
