export const aspectRatioOptions = [
    {
        key: "SQUARE",
        value: "SQUARE",
        label: "1:1",
        description: "Square",
        default: true,
    },
    {
        key: "WIDESCREEN",
        value: "WIDESCREEN",
        label: "16:9",
        description: "Widescreen",
    },
    {
        key: "ULTRA_WIDESCREEN",
        value: "ULTRA_WIDESCREEN",
        label: "21:9",
        description: "Ultrawide",
    },
    {
        key: "MEDIUM",
        value: "MEDIUM",
        label: "5:4",
        description: "Medium format",
    },
    {
        key: "VERTICAL",
        value: "VERTICAL",
        label: "9:16",
        description: "Vertical",
    },
    {
        key: "TALL",
        value: "TALL",
        label: "9:21",
        description: "Tall",
    },
]
