import React, {useState, forwardRef, ReactNode} from "react";
import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {format} from "date-fns";
interface Props extends Omit<ReactDatePickerProps, "onChange"> {
    onClick?(): void;
    onChange?(): void;
}
export type Ref = HTMLButtonElement;


const Datepicker = ({value, onChange}: {value: Date, onChange: (val:any)=>void}) => {

        const ExampleCustomInput = forwardRef<Ref, Props>(({ value, onClick, ...rest }, ref) => (
                <button type="button" className="datepicker-button" onClick={onClick} ref={ref}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap:"10px"}}>
                        <span>{value}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                            d="M2.14286 20H13.5714C13.7614 20 13.9429 19.925 14.0764 19.7907L19.7907 14.0764C19.925 13.9429 20 13.7607 20 13.5714V5C20 3.81857 19.0386 2.85714 17.8571 2.85714H16.7857V0.714286C16.7857 0.32 16.4664 0 16.0714 0C15.6764 0 15.3571 0.32 15.3571 0.714286V2.85714H4.64286V0.714286C4.64286 0.32 4.32357 0 3.92857 0C3.53357 0 3.21429 0.32 3.21429 0.714286V2.85714H2.14286C0.961429 2.85714 0 3.81857 0 5V17.8571C0 19.0386 0.961429 20 2.14286 20ZM14.2857 17.5614V15C14.2857 14.6064 14.6057 14.2857 15 14.2857H17.5614L14.2857 17.5614ZM1.42857 5C1.42857 4.60643 1.74857 4.28571 2.14286 4.28571H17.8571C18.2514 4.28571 18.5714 4.60643 18.5714 5V12.8571H15C13.8186 12.8571 12.8571 13.8186 12.8571 15V18.5714H2.14286C1.74857 18.5714 1.42857 18.2507 1.42857 17.8571V5Z"
                            fill="#555"/>
                    </svg>
                    </div>
                </button>
        ));
    ExampleCustomInput.displayName = "ExampleCustomInput";
    return (

        <DatePicker
            selected={value}
            onChange={onChange}

        />
    );
}

export default Datepicker;