import * as React from "react";
import { Menu, Transition } from "@headlessui/react";
import { ProjectFile } from "../../../api/project_api";
// TODO: Accept Icon buttona as prop
import { EllipsesIcon } from "../../../common/icons/icons";

interface IconDropdownProps {
    // iconButton: any;
    dropdownOptions: { value: string; icon: any }[];
    handleSelect: (action: string, file: ProjectFile) => void;
    file: ProjectFile;
}

export default function IconDropdownMenu({ dropdownOptions, handleSelect, file }: IconDropdownProps) {
    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(" ");
    }

    return (
        <Menu as="div" className="relative">
            <div className="text-white">
                <Menu.Button className="relative flex max-w-xs items-center rounded-full p-4 text-sm focus:outline-none">
                    <EllipsesIcon />
                </Menu.Button>
            </div>
            <Transition
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute select-none right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {dropdownOptions.map((item) => (
                        <Menu.Item key={item.value}>
                            {({ active }) => (
                                <p
                                    onClick={() => handleSelect(item.value, file)}
                                    className={classNames(
                                        active ? "bg-gray-100 " : "",
                                        "flex justify-between px-4 py-2 text-sm text-gray-650"
                                    )}
                                >
                                    {item.value} {item.icon}
                                </p>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>

    )
}
