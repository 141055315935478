import React from "react";
import { useAppSelector } from "../../hooks";
// import { USER_TYPES } from "../../lib/constants";
// import ProjectWizard from "./new/project_wizard";
import ProjectReview from "./project_review";

function Project() {
  const { profile } = useAppSelector((state) => state.user);

  // switch (profile.user_type) {
  //   case USER_TYPES.CREATOR.key:
  //     return <ProjectWizard />;
  //   case USER_TYPES.RIGHTS_HOLDER.key:
  //     return <ProjectReview />;
  //   default:
  //     return <div>404 me</div>;
  // }

  return <ProjectReview user={profile} />;
}

export default Project;
