import React, {ReactNode} from "react";
import {DialogModal, DialogModalProps} from "./dialog_modal";
import {Button} from "./button";

interface ConfirmationModalProps extends DialogModalProps{
    preTitle?: React.ReactElement | string;
    title: React.ReactElement | string;
    hintText?: React.ReactElement | string;
    onYes?: ()=>void;
    onNo?: ()=>void;
    yesText?: React.ReactElement | string;
    noText?: React.ReactElement | string;
}
const ConfirmationModal = ({preTitle, title, hintText, onYes, onNo, yesText, noText, ...rest}: ConfirmationModalProps) => {
    return <DialogModal {...rest}>
        <div className="modal_container">
            <img className="modal_image" src={`/images/modal_header_${Math.ceil(Math.random() * (3)+1)}.svg`} alt="modal_header"/>
            {preTitle && <div className="modal_pretitle text-s-sans">{preTitle}</div>}
            <div className="modal_title text-xl-serif">{title}</div>
            <div className="modal_hint text-s-sans text-normal">{hintText}</div>
            {(onYes || onNo) && <div className="modal_actions">
                {onYes && <Button variant="outline-negative" onClick={onYes}>{yesText || "Yes"}</Button>}
                {onNo&& <Button variant="primary-negative" onClick={onNo}>{noText || "No"}</Button>}
            </div>
            }
        </div>
    </DialogModal>
}
export default ConfirmationModal;

