import React from "react";
import { LicensableProperty } from "../../../model/licensable_property";
import Heading from "../../../common/components/heading";
import {formatCurrency} from "../../../lib/utils";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty | null;
  licenseType: "personal"|"commercial"|"expanded";
}

export const LPCommercialTerms = ({ licensableProperty, licenseType }: LicensablePropertyProps) => {
  const price = licensableProperty?.prices?.find(price => price.license_type === licenseType) || null;
  const commercialLicenseTerms = <div>
    <h1 className="text-xxl-sans">Small Business License Agreement</h1>
    <p>This License Agreement (&quot;License&quot;) is made between the Person identified in the Official AI Marketplace (&quot;Licensee&quot;) and Official Technologies Inc. d/b/a Official AI (Official AI). By using the Licensed Images, Licensee accepts the terms of this License. If Licensee does not agree to the terms of this License, Licensee must not use the Licensed Images.</p>

    <h2 className="text-xl-sans">1. Definitions</h2>
    <h3 className="text-l-sans">Sublicensees</h3>
    <p>Sublicensees means the Persons identified by Licensee in the Work Title during the License purchase process as Persons who may use, perform or distribute the Work.</p>
    <h3 className="text-l-sans">Effective Date</h3>
    <p>Effective Date means the date Licensee licensed the Licensed Images in the Official AI Marketplace.</p>
    <h3 className="text-l-sans">License Fee</h3>
    <p>License Fee means the fees for Licensed images identified in the Official AI Marketplace.</p>
    <h3 className="text-l-sans">Licensed Images</h3>
    <p>Licensed Images means the Images selected by Licensee during the License purchase process in the Official AI Marketplace.</p>
    <h3 className="text-l-sans">Person</h3>
    <p>Person means an individual, corporation, company, non-profit company, organization, or other entity.</p>
    <h3 className="text-l-sans">Work</h3>
    <p>Work means the audiovisual work in which the Licensed Images will be used, as identified and described by Licensee in the Official AI Marketplace during the License purchase process. Work Title must identify any sublicensee if applicable.</p>
    <h3 className="text-l-sans">Small Business</h3>
    <p>Small Business means a Company, Corporation, Non-Profit Organization or any other business entity with no more than 50 employees.</p>
    <h2 className="text-xl-sans">2. Licensed Images</h2>
    <p>Licensed Images Title(s) and Information Number(s):</p>
    <p>(Official AI # {licensableProperty?.id})</p>
    <p>License Fee:</p>
    <p>{price? formatCurrency(price?.price) : "TBD"} per 30 day period</p>
    <h2 className="text-xl-sans">3. License and License Restrictions</h2>
    <h3 className="text-l-sans">License</h3>
    <p>Official AI grants to Licensee the non-exclusive license, privilege and authority to copy, perform, edit and/or loop portions of (provided that no such change shall alter the fundamental character of the portion of the Licensed Image), and use the Licensed Images in synchronization or timed relation in the Work during the Term throughout the world for the Permitted Purposes set forth in Section 3 (d).</p>
    <h3 className="text-l-sans">License Restrictions</h3>
    <p>The License granted in Section 3 (a) is conditioned upon Licensee&apos;s compliance with the restrictions set forth in this Section 3 (b).</p>
    <ul className="list-disc list-inside pl-5">
      <li>Small Business Use Only: Licensee must be a Small Business as of the Effective Date. For clarity, if you are creating the Work for a Sublicensee, that Sublicensee must be a Small Business as of the Effective Date.</li>
      <li>Other Restrictions: Licensee must not use the Licensed Images:
        <ol className="list-roman list-inside pl-5">
          <li>For any business with more than 50 employees;</li>
          <li>In any audiovisual work other than the Work. For clarity, use of the Licensed Images in multiple videos will require separate licenses;</li>
          <li>Separately and independently as a another product;</li>
          <li>In broadcast, cable, web television, video games, mobile applications, theatrical releases or radio;</li>
          <li>On or with any pornographic work; hateful, gratuitously violent, libelous, defamatory, fraudulent, infringing or illegal content; or</li>
          <li>In any audiovisual work or any other use that has a political purpose (including, but not limited to, supporting or opposing any government policy, government official, political action, or candidate for political office).</li>
        </ol>
      </li>
    </ul>
    <ol className="list-roman list-inside pl-5"></ol>
    <p>The license is granted upon the express condition that the Licensed Images shall not be used to manufacture, sell, license, or exploit sound records or otherwise be used apart from the Work by the Licensee.</p>
    <h3 className="text-l-sans">Sublicenses.</h3>
    <ul className="list-disc list-inside pl-5">
      <li>Sublicensee have been identified to Official AI by Licensee as a Sublicensee in the Work Title;</li>
      <li>are Small Businesses; and</li>
      <li>comply with all terms and conditions set forth in this License. The scope of any such sublicense shall be no greater than the rights set forth in this License. Licensee shall have no other right to sublicense the Licensed Images except as expressly set forth in this Section 3 (c).</li>
    </ul>
    <h3 className="text-l-sans">Permitted Purposes; Distribution.</h3>
    <ul className="list-disc list-inside pl-5">
      <li>The Work may be a video that is intended for promotional, editorial, informational or entertainment purposes. The Work may also be a video that advertises or promotes a brand, product, or service of the Licensee or a permitted Sublicensee.</li>
      <li>This License grants Licensee the right to publicly display and distribute the Licensed Images as embodied in only the Work in the following ways:
        <ol className="list-roman list-inside pl-5">
          <li>online, including on website(s) and social media sites/platforms such as Vimeo, Facebook, Twitch, Tik Tok and YouTube; which includes media buys; and</li>
          <li>via distribution of the Work on DVD&apos;s or flashdrives; and</li>
          <li>at a tradeshow, conference, or other Company-related event (rights to any other method of public performance or distribution is not granted under this License and may cause Licensee to incur additional royalty fees for such use). Please contact licensing@theofficial.ai should you need additional rights.</li>
        </ol>
      </li>
    </ul>
    <h3 className="text-l-sans">Damages</h3>
    <p>Any use of the Licensed Images outside the scope of the license rights granted hereunder may subject the Licensee to damages to Official AI and to third parties, including Official AI’s licensors.</p>
    <h2 className="text-xl-sans">4. Term and Termination</h2>
    <ul className="list-disc list-inside pl-5">
      <li>This License begins on the Effective Date and is granted in perpetuity. This License shall terminate immediately without notice if Licensee breaches this License.</li>
      <li>If this License terminates for any reason, Sections 3(e), 5, 6, and 9-12 will continue to survive.</li>
    </ul>
    <h2 className="text-xl-sans">5. Additional Terms</h2>
    <p>This License incorporates by reference the most current Terms of Use and Privacy Policy of Official AI available on www.theofficial.ai</p>

    <h2 className="text-xl-sans">6. Intellectual Property</h2>
    <p>As between the parties, Official AI or its licensors owns all right, title, and interest in and to the Licensed Images. All rights not expressly granted herein are specifically reserved by Official AI or its licensors.</p>
    <h2 className="text-xl-sans">7. Royalties</h2>
    <p>In consideration of the rights granted under this License, Licensee agrees to pay the License Fees.</p>
    <h2 className="text-xl-sans">8. Credit and Attribution</h2>
    <p>Licensee shall use best efforts to credit Official AI and the artist, and include such credit in the final edited version of the Work in each case in which credits are included in the Work. No casual or inadvertent failure by Licensee to comply with the credit requirements set forth above, nor any failure by third parties to so comply, shall constitute a breach of this License by Licensee. Recommended language for credit is: &quot;[Name of Artist], licensed by Official AI.&quot; If the Work is posted to YouTube, the credit language should be posted in the video description.</p>
    <h2 className="text-xl-sans">9. Representations and Warranties</h2>
    <h3 className={"text-l-sans"}>Official AI</h3>
    <p>Official AI hereby represents and warrants that: (i) it has the power, capacity, and authority to enter into this Agreement; (ii) it has all necessary rights to license the Licensed Images to Licensee under the terms of this License; and (iii) Licensee&apos;s exercise of rights hereunder will not infringe any third party&apos;s intellectual property rights.</p>
    <h3 className="text-l-sans">Licensee</h3>
    <p>Licensee hereby represents and warrants that: (i) it has the power, capacity, and authority to enter into this Agreement; (ii) it meets the criteria set forth in Section 3 .</p>
    <h2 className="text-xl-sans">10. Indemnification</h2>
    <p>Licensee agrees to indemnify, defend, and hold harmless Official AI and its agents and licensors from and against any claim, actions or demands by a third party and all related liabilities, losses, damages, judgments, settlements, costs, and expenses (including reasonable attorneys&apos; fees) arising out of or based on any breach of any representation, warranty or covenant made herein. Licensee shall provide Official AI prompt notice in writing of any such claims under this Section 10 and provide Official AI with reasonable information and assistance to help defend such Claims.</p>
    <h2 className="text-xl-sans">11. Limitation of Liability</h2>
    <p>IN NO EVENT SHALL OFFICIAL AI BE LIABLE TO LICENSEE OR ITS AFFILIATES FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY NATURE ARISING OUT OF OR RELATED TO THIS AGREEMENT, EVEN IF OFFICIAL AI HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND IN NO EVENT SHALL THE TOTAL CUMULATIVE LIABILITY OF OFFICIAL AI TO LICENSEE OR ANY THIRD PARTY RELATED TO THIS AGREEMENT EXCEED THE AMOUNT OF THE LICENSE FEE PAID BY LICENSEE HEREUNDER. THE FOREGOING SHALL APPLY REGARDLESS OF THE NEGLIGENCE OR OTHER FAULT OF OFFICIAL AI AND REGARDLESS OF WHETHER SUCH LIABILITY ARISES IN CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR UNDER ANY OTHER THEORY OF LIABILITY.</p>
    <h2 className="text-xl-sans">12. General</h2>
    <h3 className="text-l-sans">Governing Law</h3>
    <p>This License is governed by the law of the State of California (without regard to conflicts of law principles). Any dispute arising out of or relating to this License shall be commenced in the federal or state courts located in Los Angeles County, California. In any such dispute, the substantially prevailing party shall be entitled to recover its reasonable attorneys&apos; fees from the other party.</p>
    <h3 className="text-l-sans">Damages</h3>
    <p>Licensee agrees that damages for the breach of this License are difficult to accurately calculate because of the negative impacts on Official AI’s goodwill and reputation in the marketplace and with its artists. Therefore, both parties agree to fix as liquidated damages, and not as a penalty, an amount equal to $25,000 USD, plus Official AI’s reasonable attorneys&apos; fees and costs. These liquidated damages are meant to be in addition to Official AI’s other remedies, and Licensee agrees they are a reasonable measure of the harm that would be suffered by Official AI.</p>
    <h3 className="text-l-sans">Injunctive Relief</h3>
    <p>If Licensee breaches this License, Official AI will be entitled to injunctive relief without necessity of posting bond in order to restrain Licensee&apos;s breach.</p>
    <h3 className="text-l-sans">Assignment; Waiver; Modification</h3>
    <p>This License may not be transferred or assigned by Licensee, and is binding on permitted successors and assigns. No modification of this License will be binding unless made in a writing signed by both parties. No waiver of any term, condition or obligation of this License will be valid unless made in writing and signed by the party to which such performance is due. No failure or delay by any party at any time to enforce one or more of the terms, conditions or obligations of this License will (i) constitute waiver of such term, condition or obligation, (ii) preclude such party from requiring performance by the other party at any later time, or (iii) be deemed to be a waiver of any other subsequent term, condition or obligation, whether of like or different nature.</p>
    <h3 className="text-l-sans">Entire License; Severability</h3>
    <p>This License constitutes the entire agreement between the parties with respect to the subject matter hereof and merges all prior and contemporaneous communications. If any provision of this License is held to be invalid or unenforceable to any extent, then the remainder of this License will have full force and effect and such provision will be interpreted, construed or reformed to the extent reasonably required to render the same valid, enforceable and consistent with the original intent underlying such provision.</p>
    <h3 className="text-l-sans">Notices</h3>
    <p>Any notice of breach or termination hereunder shall be sent via express or registered mail. Notices sent to Official AI shall be sent to marketplace@theofficial.ai. Notices to Licensee shall be sent to the address provided by Licensee in its online account with Official AI. Notices will be deemed delivered upon receipt or three (3) business days after deposit with such mail service.</p>
  </div>

  const personalLicenseTerms = <div>
    <h1 className="text-xxl-sans">Individual License Agreement</h1>
    <p>This License Agreement (&quot;License&quot;) is made between Individual (&quot;Licensee&quot;) and Official Technologies, Inc. d/b/a
      Official AI (Official AI). By using the Licensed Images, Licensee accepts the terms of this License. If Licensee
      does not agree to the terms of this License, Licensee must not use the Licensed Images.</p>
    <h2 className="text-xl-sans">1. Definitions</h2>
    <h3 className="text-l-sans">Effective Date</h3>
    <p>Effective Date means the date Licensee licensed the Licensed Images in the Official AI Marketplace.</p>
    <h3 className="text-l-sans">License Fee</h3>
    <p>License Fee means the fees for Licensed images identified in the Official AI Marketplace.</p>
    <h3 className="text-l-sans">Licensed Images</h3>
    <p>Licensed Images means the Images selected by Licensee during the License purchase process in the Official AI
      Marketplace.</p>
    <h3 className="text-l-sans">Person</h3>
    <p>Person means an individual.</p>
    <h3 className="text-l-sans">Work</h3>
    <p>Work means the audiovisual work in which the Licensed Images will be used, as identified and described by
      Licensee in the Official AI Marketplace during the License purchase process.</p>

    <h2 className="text-xl-sans">2. Licensed Images</h2>
    <p>Licensed Images Title(s) and Information Number(s):</p>
    <p>Official AI # {licensableProperty?.id}</p>
    <p>License Fee:</p>
    <p>{price? formatCurrency(price?.price) : "TBD"} per 30 day period</p>

    <h2 className="text-xl-sans">3. License and License Restrictions</h2>
    <h3 className="text-l-sans">License</h3>
    <p>Official AI grants to Licensee the non-exclusive license, privilege and authority to copy, perform, edit and/or
      loop portions of (provided that no such change shall alter the fundamental character of the portion of the
      Licensed Image), and use the Licensed Images in synchronization or timed relation in the Work during the Term
      throughout the world for the Permitted Purposes set forth in Section 3(c). Notwithstanding the foregoing, the
      Licensed Images shall not be distributed or exploited separately or independently of the Work by the Licensee.
      Licensee may not sublicense or assign this License.</p>
    <h3 className="text-l-sans">License Restrictions</h3>
    <p>The License granted in Section 3(a) is conditioned upon Licensee&apos;s compliance with the restrictions set
      forth in this Section 3(b).</p>
    <ul className="list-disc list-inside pl-5">
      <li>Other Restrictions. Licensee must not use the Licensed Images:
        <ol className="list-roman list-inside pl-5">
          <li>in any audiovisual work other than the Work;</li>
          <li>in any advertisement;</li>
          <li>in any Work made for Hire or use by another party that makes or generates compensation from use of the
            Licensed Images or the Work that includes the Licensed Images.;
          </li>
          <li>in broadcast, cable, or web television, video games, mobile applications or radio;</li>
          <li>in theatrical releases;</li>
          <li>in pornographic work;</li>
          <li>in any audiovisual work or any other use that has a political purpose (including, but not limited to,
            supporting or opposing any government policy, government official, political action, or candidate for
            political office).
          </li>
        </ol>
      </li>
    </ul>
    <p>The license is granted upon the express condition that the Licensed Images shall not be used to manufacture,
      sell, license, or exploit sound records or otherwise be used apart from the Work by the Licensee.</p>
    <h3 className="text-l-sans">Permitted Purposes; Distribution</h3>
    <ul className="list-disc list-inside pl-5">
      <li>The Work may be a video or background music in an audio podcast that is intended for Individual distribution
        on personal channels and student assignments/projects only.
      </li>
      <li>This License grants Licensee the right to publicly display and distribute the Licensed Images as embodied in
        the Work in the following ways:
        <ol className="list-roman list-inside pl-5">
          <li>online, on personal social media sites/platforms such as Vimeo, Facebook, and YouTube</li>
          <li>via distribution of Work on DVD units, provided that all such DVD units must be distributed without charge
            or other consideration. Please use a Business License should you wish to sell DVD&apos;s.
          </li>
        </ol>
      </li>
      <li>This license grants Licensee the right to enable monetization via 3rd party ads delivered on their personal
        channels, and to share in the revenues derived from such advertising (e.g. AdSense for videos, Anchor for
        podcasts).
      </li>
    </ul>

    <h2 className="text-xl-sans">4. Term and Termination</h2>
    <p>This License begins on the Effective Date and is granted in perpetuity. This License shall terminate immediately
      without notice if Licensee breaches this License.</p>

    <h2 className="text-xl-sans">5. Additional Terms</h2>
    <p>This License incorporates by reference the most current Terms of Use and Privacy Policy of Official AI located at
      <a href="https://www.theofficial.ai">https://www.theofficial.ai</a></p>

    <h2 className="text-xl-sans">6. Intellectual Property</h2>
    <p>As between the parties, Official AI or its licensors owns all right, title, and interest in and to the Licensed
      Images. All rights not expressly granted herein are specifically reserved by Official AI or its licensors.</p>

    <h2 className="text-xl-sans">7. Royalties</h2>
    <p>In consideration of the rights granted under this License, Licensee agrees to pay the License Fees.</p>

    <h2 className="text-xl-sans">8. Credit and Attribution</h2>
    <p>Licensee shall use best efforts to credit Official AI and the artist, and include such credit in the final edited
      version of the Work in each case in which credits for music are included in the Work. No casual or inadvertent
      failure by Licensee to comply with the credit requirements set forth above, nor any failure by third parties to so
      comply, shall constitute a breach of this License by Licensee. Recommended language for credit is: &quot;[Name of
      Artist], licensed by Official AI.&quot; If the Work is posted to YouTube, the credit language should be posted in
      the video description.</p>

    <h2 className="text-xl-sans">9. Representations and Warranties</h2>
    <h3 className="text-l-sans">Official AI.</h3>
    <p>Official AI hereby represents and warrants that: (i) it has the power, capacity, and authority to enter into this
      Agreement; (ii) it has all necessary rights to license the Licensed Images to Licensee under the terms of this
      License; and (iii) Licensee&apos;s exercise of rights hereunder will not infringe any third party&apos;s
      intellectual property rights.</p>
    <h3 className="text-l-sans">Licensee.</h3>
    <p>Licensee hereby represents and warrants that: (i) it has the power, capacity, and authority to enter into this
      Agreement; (ii) it meets the criteria set forth in Section 2(b)(i).</p>

    <h2 className="text-xl-sans">10. Indemnification</h2>
    <p>Licensee agrees to indemnify, defend, and hold harmless Official AI and its agents and licensors from and against
      any claim, actions or demands by a third party and all related liabilities, losses, damages, judgments,
      settlements, costs, and expenses (including reasonable attorneys&apos; fees) arising out of or based on any breach
      of any representation, warranty or covenant made herein. Licensee will shall provide Official AI prompt notice in
      writing of any such claims under this Section 10 and provide Official AI with reasonable information and
      assistance to help the defend such Claims.</p>

    <h2 className="text-xl-sans">11. Limitation of Liability</h2>
    <p>IN NO EVENT SHALL OFFICIAL AI BE LIABLE TO LICENSEE OR ITS AFFILIATES FOR ANY SPECIAL, INDIRECT, INCIDENTAL,
      CONSEQUENTIAL OR EXEMPLARY DAMAGES OF ANY NATURE ARISING OUT OF OR RELATED TO THIS AGREEMENT, EVEN IF OFFICIAL AI
      HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND IN NO EVENT SHALL THE TOTAL CUMULATIVE LIABILITY OF
      OFFICIAL AI TO LICENSEE OR ANY THIRD PARTY RELATED TO THIS AGREEMENT EXCEED THE AMOUNT OF THE LICENSE FEE PAID BY
      LICENSEE HEREUNDER. THE FOREGOING SHALL APPLY REGARDLESS OF THE NEGLIGENCE OR OTHER FAULT OF OFFICIAL AI AND
      REGARDLESS OF WHETHER SUCH LIABILITY ARISES IN CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR UNDER ANY OTHER
      THEORY OF LIABILITY.</p>

    <h2 className="text-xl-sans">12. General</h2>
    <h3 className="text-l-sans">Governing Law</h3>
    <p>This License is governed by the law of the State of California (without regard to conflicts of law principles).
      Any dispute arising out of or relating to this License shall be commenced in the federal or state courts located
      in Los Angeles County, California. In any such dispute, the substantially prevailing party shall be entitled to
      its reasonable attorneys&apos; fees.</p>
    <h3 className="text-l-sans">Damages</h3>
    <p>Licensee agrees that damages for the breach of this License are difficult to accurately calculate because of the
      negative impacts on Official AI&apos;s goodwill and reputation in the marketplace and with its artists. Therefore, both
      parties agree to fix as liquidated damages, and not as a penalty, an amount equal to $25,000 USD, plus Official
      AI&apos;s reasonable attorneys&apos; fees and costs. These liquidated damages are meant to be in addition to Official
      AI&apos;s other remedies, and Licensee agrees they are a reasonable measure of the harm that would be suffered by
      Official AI.</p>
    <h3 className="text-l-sans">Assignment; Waiver; Modification</h3>
    <p>This License may not be transferred or assigned by Licensee, and is binding on permitted successors and assigns.
      No modification of this License will be binding unless made in a writing signed by both parties. No waiver of any
      term, condition or obligation of this License will be valid unless made in writing and signed by the party to
      which such performance is due. No failure or delay by any party at any time to enforce one or more of the terms,
      conditions or obligations of this License will (i) constitute waiver of such term, condition or obligation, (ii)
      preclude such party from requiring performance by the other party at any later time, or (iii) be deemed to be a
      waiver of any other subsequent term, condition or obligation, whether of like or different nature.</p>
    <h3 className="text-l-sans">Entire License; Severability</h3>
    <p>This License constitutes the entire agreement between the parties with respect to the subject matter hereof and
      merges all prior and contemporaneous communications. If any provision of this License is held to be invalid or
      unenforceable to any extent, then the remainder of this License will have full force and effect and such provision
      will be interpreted, construed or reformed to the extent reasonably required to render the same valid, enforceable
      and consistent with the original intent underlying such provision.</p>
    <h3 className="text-l-sans">Notices</h3>
    <p>Any notice of breach or termination hereunder shall be sent via express or registered mail. Notices sent to
      Official AI shall be sent to marketplace@theofficial.ai. Notices to Licensee shall be sent to the address provided
      by Licensee in its online account with Official AI. Notices will be deemed delivered upon receipt or three (3)
      business days after deposit with such mail service.</p>
  </div>
  const expandedLicenseTerms = <div>
    <h1 className="text-xxl-sans">Large Business License Agreement</h1>
    <p>This License Agreement (&quot;License&quot;) is made between the Person identified in the Official AI Marketplace
      (&quot;Licensee&quot;) and Official Technologies, Inc. d/b/a Official AI (Official AI). By using the Licensed
      Images,
      Licensee accepts the terms of this License. If Licensee does not agree to the terms of this License, Licensee must
      not use the Licensed Images.</p>
    <h2 className="text-xl-sans">1. Definitions</h2>
    <h3 className="text-l-sans">Sublicensees</h3>
    <p>Sublicensees means the Persons identified by Licensee in the Work Title during the License purchase process as
      Persons who may use, perform or distribute the Work.</p>
    <h3 className="text-l-sans">Effective Date</h3>
    <p>Effective Date means the date Licensee licensed the Licensed Images in the Official AI Marketplace.</p>
    <h3 className="text-l-sans">License Fee</h3>
    <p>License Fee means the fees for Licensed Images identified in the Official AI Marketplace.</p>
    <h3 className="text-l-sans">Licensed Images</h3>
    <p>Licensed Images means theimages selected by Licensee during the License purchase process in the Official AI
      Marketplace.</p>
    <h3 className="text-l-sans">Person</h3>
    <p>Person means an individual, corporation, company, non-profit company, organization, or other entity.</p>
    <h3 className="text-l-sans">Work</h3>
    <p>Work means the audiovisual work in which the Licensed Images will be used, as identified and described by
      Licensee in the Official AI Marketplace during the License purchase process.</p>

    <h2 className="text-xl-sans">2. Licensed Images</h2>
    <p>Licensed Images Title(s) and Information Number(s):</p>
    <p>(Official AI # {licensableProperty?.id})</p>
    <p>License Fee:</p>
    <p>{price? formatCurrency(price?.price) : "TBD"} per 15 day period</p>

    <h2 className="text-xl-sans">3. Grant of License</h2>
    <p>Subject to the terms of this License and Licensee&apos;s payment of the applicable license fees, Licensor hereby
      grants to Licensee a perpetual (subject to Section 5), non-exclusive right and license within the Territory to use
      all or any part of the Image(s) identified in Section 2 (each a &quot;Licensed Image&quot; and collectively,
      the &quot;Licensed
      Images&quot;) in Licensee&apos;s short form editorial videos, promotional videos, online games, branded
      entertainment or ads
      (&quot;Work&quot;). Each Licensed Image downloaded by Licensee is licensed solely for a particular Work and, to
      the extent
      that Licensee uses that same Image in a different Work, such use will count as an additional and separate Licensed
      Image against the total number of Licensed Images licensed under this License. The foregoing license is granted
      solely for the purpose of:</p>
    <ul className="list-disc list-inside pl-5">
      <li>combining and synchronizing the Licensed Images with a Work; and</li>
      <li>enhancing, or modifying the Licensed Images (or any part thereof), provided that no such change shall alter
        the fundamental character of the portion of the Licensed Images.
      </li>
      <li>Licensor hereby grants to Licensee a perpetual (subject to Section 5), non-exclusive, sublicenseable through
        multiple tiers, right and license within the Territory to:
      </li>
      <li>distribute the Work via the internet for use on computers, mobile phones and mobile devices.</li>
    </ul>

    <h2 className="text-xl-sans">4. Restrictions</h2>
    <p>Except as expressly permitted above, neither Licensee nor its permitted sublicensees may use the Licensed
      Images:</p>
    <ul className="list-disc list-inside pl-5">
      <li>as a standalone feature separate from a Work;</li>
      <li>on broadcast television, radio or movies;</li>
      <li>with a Work distributed in any manner other than via the internet or mobile devices;</li>
      <li>on or with any pornographic, hateful, gratuitously violent, libelous, defamatory, fraudulent, infringing or
        illegal content.
      </li>
    </ul>
    <p>Further, Licensee may not use the Licensed Images for political purposes (including, but not limited to,
      supporting or opposing any government policy, government official, political action, or candidate for political
      office).</p>

    <h2 className="text-xl-sans">5. Termination</h2>
    <p>If Licensee breaches the License, Licensor reserves the right to terminate the License immediately</p>
    <p>This License begins on the Effective Date and is granted in perpetuity. This License shall terminate immediately
      without notice if Licensee breaches this License.</p>
    <p>If this License terminates for any reason, Sections 3(e), 5, 6, and 9-12 will continue to survive.</p>

    <h2 className="text-xl-sans">6. Royalties</h2>
    <p>The Licensed Images may be subject to royalties and fees due to third party performing rights organizations,
      unions or guilds, and/or similar rights organizations or collection societies.</p>

    <h2 className="text-xl-sans">7. Intellectual Property</h2>
    <p>As between the parties, Licensor owns all right, title, and interest in and to the Licensed Images. Except as
      expressly set forth in this License, nothing contained herein shall be deemed to convey or transfer to Licensee
      any interest, including copyright, in any Licensed Images hereunder. All rights not expressly granted by Licensor
      to Licensee pursuant to this License are specifically reserved by Licensor.</p>
    <h3 className="text-l-sans">Licensee&apos;s Representations and Warranties</h3>
    <p>Licensee hereby represents and warrants that it is of legal age in its jurisdiction and that it has the power,
      capacity and authority to enter into this License. If Licensee is entering into this License on behalf of an
      organization, Licensee represents and warrants that it has the authority to bind that organization, in which case
      &quot;Licensee&quot; shall mean that organization.</p>
    <h3 className="text-l-sans">Licensor&apos;s Representations and Warranties</h3>
    <p>Licensor hereby represents and warrants that: (a) Licensor has the power, capacity, and authority to enter into
      this License; (b) Licensor has all necessary rights (including consents, authorizations, licenses, and
      permissions) to license the Licensed Images to Licensee under the terms hereof; and (c) Licensee&apos;s exercise
      of
      rights hereunder will not infringe any third party&apos;s intellectual property rights.</p>

    <h2 className="text-xl-sans">10. Indemnification</h2>
    <p>Licensee agrees to indemnify and hold harmless Licensor from and against any claim, loss, damage, cost and
      expense (including reasonable attorneys&apos; fees), arising out of any third party claim arising out of a breach
      of
      this License by Licensee.</p>

    <h2 className="text-xl-sans">11. Limitation of Liability</h2>
    <p>LICENSOR&apos;S MAXIMUM LIABILITY TO LICENSEE UNDER ANY CIRCUMSTANCES IS LIMITED TO THE AMOUNT OF THE FEE
      LICENSEE
      PAID FOR THE LICENSE.</p>

    <h2 className="text-xl-sans">12. General</h2>
    <p>You agree that damages for breach of this License are difficult to calculate accurately because of the negative
      impacts on our goodwill and reputation in the marketplace and with our Artists and therefore, you and we agree to
      fix as liquidated damages for breach of this License, and not as a penalty, an amount equal to $25,000 USD, plus
      our reasonable attorneys&apos; fees and costs, which you and we agree represent damages actually sustained by us
      for
      your breach. These liquidated damages are meant to be and are cumulative of and in addition to our other remedies
      and you agree they are a reasonable measure of the harm that would be suffered by Licensor in addition to any
      damages owed by Licensee to Artists.</p>
    <p>This License: (a) contains the entire agreement between Licensor and Licensee and supersedes any prior agreements
      relating to the subject matter hereof; (b) is severable; (c) may not be transferred or assigned by Licensee; (d)
      is binding on permitted successors and assigns; and (e) is governed by the law of the State of California (without
      regard to conflicts of law principles). Any dispute arising out of or relating to this License shall be commenced
      in the federal or state courts located in Los Angeles County, California. In any such dispute, the substantially
      prevailing party shall be entitled to its reasonable attorneys&apos; fees. No modification or waiver of this
      License
      will be binding unless made in a writing signed by Licensee and one of Licensor&apos;s duly authorized
      representatives.
      No failure by Licensor to exercise a right or remedy shall be deemed a waiver of any further right or remedy.</p>

  </div>
  switch (licenseType) {
    case "commercial":
      return commercialLicenseTerms;
    case "personal":
      return personalLicenseTerms;
    case "expanded":
      return expandedLicenseTerms;
    default:
      return commercialLicenseTerms;
  }
};
