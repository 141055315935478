import React, { HTMLAttributes } from "react";
import { SCard, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./ui/base/card";
import { cva, type VariantProps } from "class-variance-authority";
import cn from "../../lib/utils";

interface CardProps {
  children?: React.ReactElement | string | undefined;
  variant?: any;
  title?: any;
  description?: any;
  footer?: any;
}

export function Card({ children, variant, ...props }: CardProps) {
  const cardVariants = cva(" rounded-lg border-none ", {
    variants: {
      variant: {
        default: "bg-gray-900 text-white",
        primary: "bg-white text-gray-900",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  });

  return (
    <SCard className={cn(cardVariants({ variant }))} {...props}>
      {props.title ||
        (props.description && (
          <CardHeader>
            <CardTitle>{props.title}</CardTitle>
            <CardDescription>{props.description}</CardDescription>
          </CardHeader>
        ))}
      <CardContent>{children}</CardContent>
    </SCard>
  );
}

export default Card;
