import React, { useEffect, useState } from "react";
import Heading from "../../common/components/heading";
import { Project, ProjectFile, useProjectAPI } from "../../api/project_api";
import { useParams } from "react-router-dom";
import Card from "../../common/components/card";
import ProjectMessages from "./components/project_messages";
import { useAppSelector } from "../../hooks";
import { Button } from "../../common/components/ui/button";
import { ArrowLeftIcon, DownloadIcon } from "@radix-ui/react-icons";
import { CheckCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { UploadedFile } from "../../api/file_api";
import ProjectAssetHistory from "./components/project_asset_history";
import FileViewer from "../../common/components/file_viewer";
import { PROJECT_STATUS, USER_TYPES } from "../../lib/constants";
import { useToast } from "../../common/components/ui/use_toast";
import { Badge } from "../../common/components/ui/badge";
import { humanizeName } from "../../lib/utils";

export const fileIsLicensed = (file: ProjectFile) =>
  file.status === PROJECT_STATUS.APPROVED.key;

function ProjectAssetReview() {
  const [updatingStatus, setUpdatingStatus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [project, setProject] = useState<Project | null>(null);
  const [file, setFile] = useState<UploadedFile | undefined>();
  const [fileStatus, setFileStatus] = useState<string | undefined>(
    file?.status
  );
  const [showFileViewer, setShowFileViewer] = useState(false);
  const { profile: user } = useAppSelector((state) => state.user);
  const { id, fileId } = useParams<{ id: string; fileId: string }>();
  const projectApi = useProjectAPI();
  const { toast } = useToast();

  const isCreator = user.user_type === USER_TYPES.CREATOR.key;
  const isRightsHolder = user.user_type === USER_TYPES.RIGHTS_HOLDER.key;
  const allowFileActions = project?.status !== PROJECT_STATUS.APPROVED.key;

  useEffect(() => {
    if (id) {
      projectApi.fetchProject({ id }).then((project) => {
        setProject(project);
        const file = project.files?.find((f) => f.id === fileId);
        setFile(file);
        setFileStatus(file?.status);
        setLoading(false);
      });
    }
  }, [id]);

  const handleDeleteAsset = () => {
    const doDelete = confirm(
      "Are you sure you want to delete this file from your project?"
    );
    if (doDelete) {
      console.log("do delete", file);
    }
  };

  const updateAssetStatus = async (file: ProjectFile, status: string) => {
    if (!file?.project_file_id || !project?.id) return;
    setUpdatingStatus(status);
    await projectApi
      .updateProjectFileStatus(project.id, file.project_file_id, status)
      .then((res) => {
        return String(res);
      });
    setUpdatingStatus("");
    setFileStatus(status);
    toast({
      title: "Asset status updated!",
      description: `The file status has been changed: ${PROJECT_STATUS.APPROVED.key === status
        ? PROJECT_STATUS.APPROVED.label
        : PROJECT_STATUS.CHANGES_REQUESTED.label
        }`,
    });
  };

  if (loading || !project || !file) return <div>Loading</div>;

  return (
    <div className="flex flex-col sm:gap-20 gap-10 text-white mx-auto max-w-6xl pt-48">
      <div className="space-y-10">
        <Button
          className="gap-5"
          size="xs"
          variant="outline"
          href={`/project/${project.id}`}
        >
          <>
            <ArrowLeftIcon />
            Back to {project.name}
          </>
        </Button>
        <Card>
          <div className="overflow-hidden">
            <div className="space-y-2">
              <div className="flex flex-row justify-between">
                <div className="flex flex-wrap items-center gap-4">
                  <FileViewer
                    file={file}
                    project={project}
                    showActions={isRightsHolder && allowFileActions}
                    isOpen={showFileViewer}
                    setIsOpen={setShowFileViewer}
                    childrenButtonOnClick={() => setShowFileViewer(true)}
                  >
                    <img
                      className="h-28 w-28 rounded-full object-cover object-top"
                      src={file.permalink}
                      alt=""
                    />
                  </FileViewer>
                  <Heading as="h4" className="font-sans not-italic">
                    {project.name}
                  </Heading>
                </div>
              </div>
              <FileViewer
                file={file}
                project={project}
                showActions={isRightsHolder && allowFileActions}
                isOpen={showFileViewer}
                setIsOpen={setShowFileViewer}
                childrenButtonOnClick={() => setShowFileViewer(true)}
              >
                <Heading as="h2">{humanizeName(file.name || "")}</Heading>
              </FileViewer>
              <div className="flex justify-between">
                {fileStatus === PROJECT_STATUS.APPROVED.key && (
                  <div className="flex flex-grow gap-2 w-fit items-center">
                    Asset approved{" "}
                    <CheckCircleIcon height={32} className="fill-brand-yellow" />
                  </div>
                )}
                {fileStatus === PROJECT_STATUS.CHANGES_REQUESTED.key && (
                  <div className="flex flex-grow gap-2 w-fit items-center">
                    Asset denied{" "}
                    <XMarkIcon height={32} className="fill-brand-red" />
                  </div>
                )}
                {isRightsHolder && fileStatus && ![PROJECT_STATUS.APPROVED.key, PROJECT_STATUS.CHANGES_REQUESTED.key].includes(fileStatus) && (
                  <div className="sm:pt-4 flex flex-shrink gap-4">
                    <Button
                      variant="outline"
                      size="lg"
                      className="font-semibold"
                      loading={updatingStatus === PROJECT_STATUS.APPROVED.key}
                      disabled={fileStatus === PROJECT_STATUS.APPROVED.key}
                      onClick={() =>
                        updateAssetStatus(
                          file,
                          PROJECT_STATUS.APPROVED.key
                        )
                      }
                    >
                      <div className="flex flex-row items-center w-full relative">
                        <div className="w-full pr-4">Accept asset</div>
                        <CheckCircleIcon
                          height={28}
                          className="absolute -right-5 fill-yellow-500"
                        />
                      </div>
                    </Button>
                    <Button
                      variant="outline"
                      size="lg"
                      className="font-semibold"
                      onClick={() =>
                        updateAssetStatus(
                          file,
                          PROJECT_STATUS.CHANGES_REQUESTED.key
                        )
                      }
                    >
                      <div className="flex justify-center items-center w-full relative">
                        <div className="w-full pr-4">Deny asset</div>
                        <XCircleIcon
                          height={28}
                          className="absolute -right-4 fill-red-500"
                        />
                      </div>
                    </Button>
                  </div>
                )}
                {isCreator && (
                  <>
                    {fileIsLicensed(file) ? (
                      <div className="sm:pt-4 flex gap-4">
                        <Button
                          className="gap-1"
                          variant="secondary"
                          href={file.permalink}
                        >
                          <>
                            Download licensed asset
                            <DownloadIcon height={24} />
                          </>
                        </Button>
                      </div>
                    ) : (
                      <div><Badge variant="micro">{file.status?.replaceAll("_", " ")}</Badge></div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="w-full sm:pl-20 content-center">
              <div className="flex flex-col gap-4 py-6"></div>
            </div>
          </div>
        </Card>
        <ProjectAssetHistory file={file} project={project} user={user} />
        <ProjectMessages project={project} user={user} />
      </div>
    </div>
  );
}

export default ProjectAssetReview;
