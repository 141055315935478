import React from "react";
import {Project, ProjectFile} from "../../../api/project_api";
import Heading from "../../../common/components/heading";
import { User } from "../../../model/user";
import { PROJECT_STATUS, USER_TYPES } from "../../../lib/constants";
import { Button } from "../../../common/components/ui/button";
import { ArrowDownIcon, DownloadIcon } from "@radix-ui/react-icons";
import { ProjectFileCard } from "../../project/components/project_file_card";
import FileViewer from "../../../common/components/file_viewer";

const ProjectAssets = ({ project, user, updateAssetStatus }: { project: Project; user: User; updateAssetStatus: (projectId: string, fileId: string, status: string) => void }) => {
  const isCreator = user.user_type === USER_TYPES.CREATOR.key;
  const isRightsHolder = user.user_type === USER_TYPES.RIGHTS_HOLDER.key;
  const allowFileActions = project?.status !== PROJECT_STATUS.APPROVED.key;
  const [selectedFile, setSelectedFile] = React.useState<ProjectFile | null>(null);
  const _files = [];
  if (project.files) {
    for (let i = 0; i < 10; i += 1) {
      _files.push(project.files[0]);
    }
  }
  const onViewAsset = (file: ProjectFile) => {
    setSelectedFile(file);
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row justify-between">
        <Heading as="h3">Assets</Heading>
        {isCreator && (
          <div className="">
            <div className="flex flex-shrink gap-2">
              <Button className="gap-2" size="xs" variant="outline">
                <>
                  Add more assets <DownloadIcon />
                </>
              </Button>
              <Button className="gap-2" size="xs" variant="outline">
                <>
                  See all <ArrowDownIcon />
                </>
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-3 pr-0">
        {project.files?.map((file, idx) => {
          return (
            <ProjectFileCard key={`project_${idx}`} onViewAsset={onViewAsset} onClick={() => (window.location.href = `/project/${project.id}/asset/${file.id}`)} project={project} file={file} isRightsHolder={isRightsHolder} allowFileActions={allowFileActions} updateAssetStatus={updateAssetStatus} />
          );
        })}
      </div>
        {selectedFile &&
        <FileViewer project={project} file={selectedFile} isOpen={!!selectedFile} setIsOpen={(open) => setSelectedFile(null)} />
        }
    </div>
  );
};

export default ProjectAssets;
