import { useAuth0 } from "@auth0/auth0-react";
import { LicensableProperty, Price } from "../model/licensable_property";
import { UploadedFile } from "../api/file_api";

export interface ProjectSaveRequest {
  id?: string;
  name?: string;
  description?: string;
  status?: "draft" | "submitted_for_approval" | "changes_requested" | "approved" | "rejected" | "expired";
  licensable_property_id?: string;
  license_type?: "personal" | "commercial" | "expanded";
  duration?: number;
  start_date?: Date;
  payment_account_id?: string;
  include_watermark?: boolean;

  billing_name?: string;
  billing_address?: string;
  billing_address2?: string;
  billing_city?: string;
  billing_state?: string;
  billing_zip?: string;
  billing_card_name?: string;
  billing_card_address?: string;
  billing_card_exp?: string;
  billing_card_cvv?: string;

  files?: FileUsageDTO[];
}

export interface FileUsageDTO {
  fileId?: string;
  usage?: string;
}

export interface Project {
  id?: string;
  name?: string;
  description?: string;
  created_by_user?: string;
  created_by?: string;
  status?: "draft" | "submitted_for_approval" | "changes_requested" | "approved" | "rejected" | "expired";
  licensable_property_id?: string;
  include_watermark?: boolean;
  start_date?: Date;
  end_date?: Date;
  updated_at?: Date;
  license_type?: "personal" | "commercial" | "expanded";
  duration?: number;
  profile_image?: string;
  files?: UploadedFile[];
  prices?: Price[];
  messages?: ProjectMessage[];
  licensable_properties?: LicensableProperty[];
}

export interface File {
  id?: string;
  name?: string;
  description?: string;
  file_type?: "IMAGE" | "VIDEO" | "DOCUMENT" | "AUDIO";
  mime_type?: string;
  permalink?: string;
  size?: number;
  is_public?: boolean;
}

export interface ProjectFile {
  id?: string;
  project_id?: string;
  project_file_id?: string;
  file?: File;
  usage?: string;
  name?: string;
  status?: string;
  permalink?: string;
  mimeType?: string;
  fileType?: string;
  file_type?: string;
  size?: number;
  isPublic?: boolean;
  is_public?: boolean;
  created_at?: string;
}

export interface ProjectMessage {
  id?: string;
  project_id?: string;
  from_user_id?: string;
  from_user_full_name?: string;
  fromUserId?: string; // ??
  to_user_id?: string;
  message?: string;
  read_at?: string;
  created_at?: string;
  createdAt?: string; // ??
}

export function useProjectAPI() {
  const { getAccessTokenSilently } = useAuth0();

  const fetchProject = async ({ id }: { id: string }): Promise<Project> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<Project>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/project/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: Project) => {
            if (data.start_date) {
              data.start_date = new Date(data.start_date);
            }
            if (data.end_date) {
              data.end_date = new Date(data.end_date);
            }
            resolve(data);
          });
        } else {
          reject(new Error("There was an issue retrieving the project state " + response.statusText));
        }
      });
    });
  };
  const fetchProjectEditState = async ({ id }: { id: string }): Promise<ProjectSaveRequest> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<ProjectSaveRequest>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/project/${id}/edit`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: ProjectSaveRequest) => {
            if (data.start_date) {
              data.start_date = new Date(data.start_date);
            }
            resolve(data);
          });
        } else {
          reject(new Error("There was an issue retrieving the project state " + response.statusText));
        }
      });
    });
  };

  const fetchProjects = async (): Promise<Project[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<Project[]>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/project/my`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: Project[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("There was an issue retrieving the projects " + response.statusText));
        }
      });
    });
  };

  const saveProject = async (projectSaveReq: ProjectSaveRequest): Promise<Project> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<Project>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/project${projectSaveReq.id ? `/${projectSaveReq.id}` : ""}`;
      fetch(url, {
        method: projectSaveReq.id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(projectSaveReq),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data: Project) => {
            if (data.start_date) {
              data.start_date = new Date(data.start_date);
            }
            if (data.end_date) {
              data.end_date = new Date(data.end_date);
            }
            resolve(data);
          });
        } else {
          if (response.status === 401) {
            reject(new Error("User is not authenticated"));
          } else {
            reject(new Error("Failed to save project: " + response.statusText));
          }
        }
      });
    });
  };

  const updateProjectStatus = async (
    project: Project,
    status: string,
    message?: string
  ): Promise<Project> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<Project>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/project/${project.id}/status`;
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ status, message }),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data: Project) => {
            resolve(data);
          });
        } else {
          if (response.status === 401) {
            reject(new Error("Not authorized"));
          } else {
            reject(new Error("Failed to update project status: " + response.statusText));
          }
        }
      });
    });
  };

  const updateProjectFileStatus = async (
    projectId: string,
    projectFileId: string,
    status: string,
    message?: string
  ): Promise<Project> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<Project>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/project/${projectId}/file/${projectFileId}/status`;
      fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ status, message }),
      }).then(async (response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data: Project) => {
            resolve(data);
          })
        } else {
          if (response.status === 401) {
            reject(new Error("Not authorized"));
          } else {
            reject(new Error("Failed to update project status: " + response.statusText));
          }
        }
      });
    });
  };

  const postProjectMessage = async (projectMessage: ProjectMessage): Promise<ProjectMessage> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<ProjectMessage>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/project/${projectMessage.project_id}/message`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(projectMessage),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          resolve(projectMessage);
        } else {
          if (response.status === 401) {
            reject(new Error("Not authorized"));
          } else {
            reject(new Error("Failed to post new message: " + response.statusText));
          }
        }
      });
    });
  };

  return { fetchProjects, fetchProject, saveProject, fetchProjectEditState, updateProjectStatus, postProjectMessage, updateProjectFileStatus };
}