import React from "react";
import { LicensableProperty } from "../../../model/licensable_property";
import Heading from "../../../common/components/heading";
import { Faq } from "../../../common/components/ui/faq";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty;
}

export const LPFAQ = ({ licensableProperty }: LicensablePropertyProps) => {
  return (
    <div>
      <Heading as="h3" className="mb-6">
        Frequently asked questions
      </Heading>
      <Faq
        faq={[
          {
            question: "How long does it take for a license to be approved?",
            answer:
              "When it comes to standard licenses, you can generally expect approval within a week's time. Although the process often moves faster than that, it's wise to allow for 7 days when planning for time-sensitive requirements. If you're seeking expanded rights, however, the approval timeline can vary. The specific turnaround time will depend on the complexity of your request.",
          },
          {
            question: "Can I extend a license after it is approved?",
            answer:
              "Yes! You can extend any standard license in 15-day increments (up to a maximum of 90 days) through the Official AI marketplace. ",
          },
          {
            question: "Can I negotiate additional usage rights for a license? ",
            answer: "Yes! You can use our expanded rights license to request additional usage rights for your project.",
          },
          {
            question: "Can I transfer a commercial license to a client I am working on behalf of? ",
            answer:
              "You can include a client as a licensee in the commercial license. You are not able to transfer an individual or personal license to a client or third party.",
          },
        ]}
      />
    </div>
  );
};
