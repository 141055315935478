import React from "react"
import AppHeader from "../layouts/header";
import AppFooter from "../layouts/app_footer";
import Heading from "../../common/components/heading";

const Terms = () => {
    return <div className="flex flex-col min-h-screen">
        <AppHeader/>
        <main style={{
            maxWidth: "1440px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "90px",
            padding: "0px 172px 24px 172px"
        }}>
            <div className="text-white">
                <h1 style={{color:"white",fontFamily:"Lora",fontSize:"60px",fontStyle:"italic",fontWeight:"400",lineHeight:"100px"}}>Terms And Conditions</h1>
                
                <p>The following Terms and Conditions of Use, together with our Privacy Policy, govern your access to and use of the Official AI: Marketplace, the website theofficial.ai and any other online features, products or services offered by Official AI (collectively, &quot;the Service&quot;). The Official AI: Marketplace and theofficial.ai are owned and operated by Official Technologies, Inc. (&quot;Official AI&quot;, &quot;we&quot;, and &quot;us&quot;). The Service is offered subject to your acceptance, without modification, of these terms and conditions and the Privacy Policy (collectively, the &quot;Agreement&quot;). By accessing or using any part of the Service, you agree to be bound by this Agreement. If you do not agree to all the terms and conditions of this Agreement, then you must not access or use the Service.</p>
                <p>Last Updated: 04-03-2024</p>
                <p><strong>Authority To Enter Into This Agreement</strong></p>
                <p>You represent and warrant that you have all necessary rights, power and authority to enter into this Agreement.</p>
                <p><strong>Age</strong></p>
                <p>You represent and warrant that you are 13 years of age or older. If you are under the age of 13, you must not use the Service.</p>
                <p><strong>Modification of Terms</strong></p>
                <p>Official AI may modify these Terms and Conditions of Use from time to time. If we revise these Terms and Conditions of Use, those changes will appear on this page and the &quot;Last Updated&quot; line at the top of the policy will be updated. Your continued use of the Service after any change in these Terms and Conditions of Use will constitute your acceptance of such change. Official AI may also, in the future, offer new services and features through the Service (including, the release of new tools and resources). Such new features and services will be subject to this Agreement. If you do not agree with the modified terms, you must cease use of the Service and notify Official AI that you are doing so because of the modification of the terms.</p>
                <p><strong>You Are Responsible For Your Conduct</strong></p>
                <p>With respect to the Service, you are fully and solely responsible for (a) any activity that occurs under your screen name, password or account, including any violation of this Agreement, (b) your own conduct and your interactions with other users of the Service, and (c) any content, material or information that you upload, create, post, display, publish, e-mail, communicate, transmit or otherwise make available, or which you transmit to other Users (collectively, &quot;User Content&quot;).</p>
                <p><strong>Account</strong></p>
                <p>You are responsible for maintaining the security of your account, and you are fully responsible for all actions taken in connection with it. You must not use your account in a misleading or unlawful manner. Official AI will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, deactivate, or terminate your account or your use of the Service at any time, and you may likewise terminate your account at any time. You agree to keep your password secure and confidential and not to disclose it to anyone and will not permit any third party to access your account.</p>
                <p>You are responsible to protect the confidentiality and safety of your account details (username and password) and for restricting access to your account. You accept full and exclusive responsibility for all activities that occur under your account or password.</p>
                <p><strong>Payments, Taxes, and Refund Policy</strong></p>
                <p>You will provide a credit card for all payments under this account (your &quot;Payment Method&quot;). You agree that you will pay for all content you license through Official AI and that Official AI may charge your Payment Method for the correct price of any content purchased or content downloaded and used without a proper license, including content used outside of the terms of service or license agreement, for any additional amounts (including any taxes and infringement fees, as applicable) that may be accrued by or in connection with your Account. YOU ARE RESPONSIBLE FOR THE TIMELY PAYMENT OF ALL FEES AND FOR PROVIDING OFFICIAL AI WITH A VALID PAYMENT METHOD AND UPDATES AS REQUIRED FOR PAYMENT OF ALL FEES.</p>
                <p>In the event that content is used outside of The Terms of Service, or the license agreement, Official AI will contact you 15 days prior to charging your Payment Method for any payments due related to the misuse of content purchased or downloaded and used outside of the terms of the service or license agreement obtained. You will have 15 days to dispute the additional payment, at which time Official AI will provide a decision of any additional payments due.</p>
                <p>All images that you license are: (i) for single use only (meaning that you&apos;ll need separate licenses if you wish to use a single image in multiple and separate works); and (ii) subject to the licensing terms in your end user license agreement. In addition, the specific work that the images will be incorporated with must be identified at the time you license the images.</p>


                <p><strong>All sales are final</strong></p>
                <p>Prices for products offered may change at any time, and Official AI does not provide price protection or refunds in the event of a price reduction or promotional offering. No refunds will be given except in Official AI&apos;s sole and absolute discretion.</p>
                <p><strong>Unauthorized Conduct</strong></p>
                <p>You agree that you will not engage in any illegal or prohibited conduct by using the Service, including but not limited to not violating any and all applicable local, state, national and international laws, rules and regulations.</p>
                <p>You agree that damages for breach of these Terms of Service or any Official AI License are difficult to calculate because of the negative impacts on our goodwill and reputation in the marketplace and with our Artists and therefore, you and we agree to fix as liquidated damages for breach of this License, and not as a penalty, an amount equal to $25,000 USD, plus our reasonable attorneys&apos; fees and costs, which you and we agree represent damages actually sustained by us for your breach. These liquidated damages are meant to be and are cumulative of, and in addition to our other remedies and you agree they are a reasonable measure of the harm that would be suffered by Official AI.</p>
                <p><strong>You Are Responsible for User Content</strong></p>
                <p>You are solely responsible for the content of, and any harm resulting from, User Content. Official AI is not responsible for User Content&apos;s use or effects. By operating the Service, Official AI does not represent or imply that it endorses User Content posted on the Service, or that it believes such User Content to be accurate or non-harmful. Under no circumstances will Official AI be liable in any way for any User Content, including any errors or omissions in any User Content or any loss or damage of any kind incurred as a result of the use of any User Content made available through the Service. By making User Content available, you represent and warrant that:</p>
                <ul>
                    <li>the downloading, copying and use of the User Content will not infringe the proprietary rights of any third-party, including copyright, patent, trademark or trade secret rights, or violate any applicable local, state, national, or international law or any regulations having the force of law; the User Content is not spam, and does not contain unethical or unwanted commercial content or further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);</li>
                    <li>the User Content is not obscene, libelous or defamatory, hateful or racially or ethnically objectionable; does not violate the privacy or publicity rights of any third-party; and the User Content does not impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; the User Content does not disrupt the Service, or servers or networks connected thereto or violate any requirements, procedures, policies, or regulations of networks connected to the Service.</li>
                </ul>
                <p><strong>License Of User Content To Official AI</strong></p>
                <p>By displaying or publishing (&quote;uploading&quote;) any User Content on or through the Service, you hereby grant to Official AI a non-exclusive, perpetual, world-wide, fully-paid and royalty-free limited license to use, copy, modify, adapt, translate, publicly perform, digitally perform, publicly display, reproduce, and distribute such User Content (in whole or in part) or prepare derivative works. You represent and warrant that you have sufficient ownership rights to grant this license.</p>
                <p><strong>User Content Removal</strong></p>
                <p>You can remove your User Content at any time by deleting your account. Deletion of your account will not terminate any licenses for User Content granted by you prior to deletion of your account. You acknowledge that Official AI does not pre-screen all User Content on this Site, but that Official AI may, in its sole discretion, pre-screen, refuse, remove, monitor or remove any specific User Content or portion thereof that is available via the Service. Without limiting any of those representations or warranties, Official AI has the right, but not the obligation, in its sole discretion, to refuse or remove any User Content.</p>
                <p><strong>Forums and Public Communication</strong></p>
                <p>For your convenience and enjoyment, we may offer discussion boards and other forums (&quote;Forum(s)&quote;) where you can connect with other users of the Service, comment upon content and otherwise submit User Content. You agree that all User Content is the sole responsibility of the individual or entity from whom such User Content originated. This means that individual users (and not Official AI) are entirely responsible for all User Content that you submit via the Forum or elsewhere on the Site.</p>
                <p><strong>Third-Party Materials</strong></p>
                <p>Some materials or information accessible through the Service may be provided by third parties such as other users of the Service or advertisers. These materials may include, without limitation, advertisements for third-party or user-provided products or services. You may have opportunities on or through the Service to purchase certain products and/or services directly from such third parties. You are solely responsible for any and all consequences of your activities, transactions and relationships that you may conduct or form with other users of the Service or other third parties. You make any purchases from such third parties at your own risk. You acknowledge and agree that Official AI does not recommend or endorse any third-party websites or services and has no responsibility or liability for your purchases and/or transactions.</p>
                <p><strong>Connecting to Third-Party Websites</strong></p>
                <p>Official AI offers you the possibility to connect your accounts from websites and social platforms, with the Service by an application programming interface (API) or other software. By allowing Official AI to connect with your accounts on such websites, you consent to Official AI accessing the information in those accounts, which may include personally identifiable information (as to which you represent you have the rights to provide access to Official AI). You likewise consent to Official AI publishing on the Service any such information or actions you take on other such applications or websites. Any information accessed or published from your account on another website or application on the Service is considered User Content, and is subject to this Agreement. It is your choice whether to use any such third-party application or website, and you understand that in some instances applications may request to access and publish information about you or your friends that is included in your Official AI account. You are responsible for reviewing the terms of use and privacy policy of any application or website before using it, since the terms of use for those website and applications may differ from ours, and they may use and share information in ways Official AI does not.</p>
                <p><strong>Copyright Policy</strong></p>
                <p>In operating the Service, we may act as an online services provider (as defined by the Digital Millennium Copyright Act, &quote;DMCA&quote;) and offer services as an online provider of materials and links to third-party websites. As a result, third-party materials that we do not own or control may be transmitted, stored, accessed or otherwise made available using the Service. Official AI has adopted a policy that provides for the prompt removal of any content or the suspension of any user that is found to have infringed on the rights of Official AI or of a third-party, or who has otherwise violated any intellectual property laws or regulations, or any of the terms and conditions of this Agreement. Without limiting the foregoing, if you believe that your work has been posted without your authorization on the Service in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: (i) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description of where the material that you claim is infringing is located on the Service or its websites; (iv) your address, telephone number, and email address; (v) a written statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner&apos;s behalf. Official AI&apos;s Copyright Agent for notice of claims of copyright infringement can be reached at Marketplace@theofficial.ai.</p>
                <p><strong>Proprietary Rights In Official AI&apos;s And Third-Party Content And Technology</strong></p>
                <p>All of the content featured or displayed via the Service, including without limitation text, graphics, photographs, images, moving images, sounds, and illustrations (&quote;Content&quote;), is owned by Official AI, its licensors, vendors, agents, and/or its Content Providers. You acknowledge the Service contains copyrighted material, trademarks, and other proprietary information of Official AI and its licensors and you agree that all right, title and interest to the Service and the Contents therein remains with Official AI or third parties, as applicable. This Agreement does not transfer from Official AI to you any Official AI or third-party intellectual property, and all right, title and interest in and to such property will remain (as between the parties) solely with Official AI.</p>

            </div>

        </main>
        <AppFooter/>
    </div>


}

export default Terms;