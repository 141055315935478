export const FIELDS = {
  NAME: {
    key: "name",
    label: "Artist name",
    type: "string",
  },
  TYPE: {
    key: "type",
    label: "Artist type",
    type: "string",
  },
  INTRODUCTION: {
    key: "description",
    label: "Artist introduction",
    type: "string",
  },
  TALENT_TYPE: {
    key: "type",
    label: "Talent introduction",
    type: "string",
    options: [
      { value: "actor", label: "Actor" },
      { value: "comedian", label: "Comedian" },
      { value: "athlete", label: "Athlete" },
      { value: "influencer", label: "Influencer" },
      { value: "model", label: "Model" },
      { value: "musician", label: "Musician" },
      { value: "public_figure", label: "Public figure" },
    ],
  },
  CONTENT_TOPICS: {
    key: "content_topics",
    label: "Open to media containing",
    type: "array",
    options: [
      { is_allowed: true, value: "drugs", label: "Drugs" },
      { is_allowed: true, value: "alcohol", label: "Alcohol" },
      { is_allowed: true, value: "mature_language", label: "Mature language" },
      { is_allowed: true, value: "nudity", label: "Nudity" },
      { is_allowed: true, value: "politics", label: "Politics" },
      { is_allowed: true, value: "tobacco", label: "Tobacco" },
      { is_allowed: true, value: "weapons", label: "Weapons" },
      { is_allowed: true, value: "animals", label: "Animals" },
    ],
  },
  SOCIAL_ACCOUNTS: {
    key: "social_accounts",
    label: "Social",
    type: "array",
    options: [
      { key: "tiktok", label: "Tiktok profile", followers: 0 },
      { key: "instagram", label: "Instragram profile", followers: 0 },
      { key: "facebook", label: "Facebook URL", followers: 0 },
      // { key: "twitch", label: "Twitch", followers: 0 },
      // { key: "youtube", label: "Youtube", followers: 0 },
      // { key: "pinterest", label: "Pinterest", followers: 0 },
      // { key: "linkedin", label: "Linkedin", followers: 0 },
      // { key: "x", label: "X", followers: 0 },
    ],
  },
  PRICES: {
    key: "prices",
    label: "Price",
    type: "number",
  },
  FILES: {
    key: "files",
    label: "Files",
    type: "array",
  },
  ADD_TRAINING_DATA: {
    key: "add_training_data",
    label: "Add training data",
    type: "string",
  },
  TRAINING_DATA_ASSETS: {
    key: "training_data_assets",
    label: "Training data assets",
    type: "string",
  },
  LICENSE_TYPE: {
    key: "license_type",
    label: "License type",
    type: "string",
    options: [
      {
        value: "commercial",
        label: "Commercial license",
        description: "For small businesses and freelancers creating web content ",
      },
      {
        value: "personal",
        label: "Personal license",
        description: "For creating and publishing videos or podcasts on personal web channels.",
      },
    ],
  },
  HERO_IMAGE: {
    key: "hero_image",
    label: "Cover image",
    type: "string",
  },
  PROFILE_IMAGE: {
    key: "profile_image",
    label: "Profile image",
    type: "string",
  },
};
