import React, {useEffect, useMemo, useRef, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {Button} from "../../common/components/ui/button";
import {useAppSelector} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {PROJECT_STATUS, USER_TYPES} from "../../lib/constants";
import {useProjectAPI} from "../../api/project_api";
import {useDispatch} from "react-redux";
import {updateProjects, updateProjectsSubmittedForApprovalCount} from "../project/projectsSlice";
import {projectWizardSlice} from "../../pages/project/projectWizardSlice";
import {DialogModal} from "../../common/components/ui/dialog_modal";
import "./header.css";
import useClickOutside from "../../hooks/click_outside";
import {useFileAPI} from "../../api/file_api";
import bit_image from "../../common/components/ui/bit_image.png";
import {useGenerativeAPI} from "../../api/generative_ai_api";

const PublicHeader = ({ minimalHeader, theme, setOpacity }: { minimalHeader?: boolean, theme?: "dark" | "lite", setOpacity?: boolean }) => {
    const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
    const { profile } = useAppSelector((state: any) => state.user);
    const [scrollTop, setScrollTop] = useState(0);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { resetProjectWizard } = projectWizardSlice.actions;
    const { fetchProjects } = useProjectAPI();
    const menuRef = useRef<HTMLDivElement>(null);
    const createMenuRef = useRef<HTMLUListElement>(null);
    useClickOutside(menuRef, () => { setNavOpen(false) });
    useClickOutside(createMenuRef, () => { setIsDropdownOpen(false) });
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const { uploadFile } = useFileAPI();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {initStarterImage} = useGenerativeAPI();

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    useEffect(() => {
        const handleScroll = (event: any) => {
            setScrollTop(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchProjects().then((projects) => {
                dispatch(updateProjects(projects));
                const count = projects.filter(
                    (p) =>
                        p.status &&
                        [PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key, PROJECT_STATUS.CHANGES_REQUESTED.key].includes(p.status)
                )?.length;
                dispatch(updateProjectsSubmittedForApprovalCount(count));
            });
        }
    }, [isAuthenticated]);


    const colorTheme = useMemo(() => {
        return scrollTop > 10 ? "lite" : theme || "lite";
    }, [scrollTop, theme])

    const isMarketingPage = window.location.pathname.includes("/for-marketers") || window.location.pathname.includes("/for-talent") || window.location.pathname == ("/");

    if (isLoading) {
        return null;
    }

    const openModal = () => {
        console.log("open modal");
        setIsCreateModalOpen(true);
    };

    const navigateToCreateImage = () => {
        setIsCreateModalOpen(false);
        navigate("/image/new");
    }

    const browseForStarterImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        // TODO: show loading page
        const files: File[] = event.target.files ? Array.from(event.target.files) : [];
        if (files.length > 0) {
            setUploading(true);
            uploadFile(
                {
                    files: files,
                    is_public: true,
                    generate_thumbnail: true,
                    // object_id: objectId,
                    object_type: "starter_image",
                },
                (progressEvent) => {
                    setUploadProgress((progressEvent.progress || 0) * 100);
                }
            ).then((response) => {
                setUploading(false);
                if (response.length > 0 && response[0].id) {
                    initStarterImage({file_id: response[0].id}).then((response) => {
                        setIsCreateModalOpen(false)
                        navigate("/image/editor/" + response.id);
                    }).catch((error) => {
                        console.error(error);
                    })
                }
            });

        }
    };

    return (
        <div>
            <header className={"nav_header " + colorTheme + " " + ((scrollTop > 10) || (setOpacity) ? "scroll_background" : "") + (minimalHeader ? " minimal_header" : "")}>
                <button className="menu_button" onClick={() => setNavOpen((old) => !old)}>{navOpen ? <XMarkIcon width={32} /> : <Bars3Icon width={32} />}</button>
                {navOpen && <div className="mobile_nav" ref={menuRef}>
                    <ul className="mobile_nav_links">
                        {isAuthenticated && profile.user_type === USER_TYPES.RIGHTS_HOLDER.key && <li><a href="/add-talent">Add talent</a></li>}
                        {isAuthenticated && profile.user_type === USER_TYPES.CREATOR.key && <li><a href="/project/new">Create project</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/check-license">Verify license</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/for-talent">For Talent</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/for-marketers">For Marketers</a></li>}
                        {(!isAuthenticated || isMarketingPage) && <li><a href="/blog">Blog</a></li>}
                        {isAuthenticated && profile.roles.includes("AI_IMAGE_CREATOR") && <li><a onClick={openModal}>Create image</a></li>}
                        {isAuthenticated && <li><a href="/dashboard">Dashboard</a></li>}
                        {!isAuthenticated && <li><a href="/register">Get started</a></li>}
                        {!isAuthenticated && <li><a href="#" onClick={() => loginWithRedirect()}>Log in</a></li>}
                        {isAuthenticated && <li><a href="#" onClick={handleLogout}>Log out</a></li>}
                    </ul>
                </div>}
                <a href={isMarketingPage ? "/" : "/dashboard"} className="logo"><img
                    src={colorTheme === "dark" ? "/officialai_logo_black.svg" : "/officialai_logo_white.svg"}
                    alt="logo" /></a>
                {!minimalHeader && <>
                    {(!isAuthenticated || isMarketingPage) && <ul className="nav_links">
                        <li><a href="/check-license">Verify license</a></li>
                        <li><a href="/for-talent">For Talent</a></li>
                        <li><a href="/for-marketers">For Marketers</a></li>
                        <li><a href="/blog">Blog</a></li>
                        {isAuthenticated && <li><a href="/dashboard">Dashboard</a></li>}
                    </ul>}
                    {!isAuthenticated && <ul className="nav_actions">
                        <li><a href="/register" className={"pill_button " + theme}>Get started</a></li>
                        <li><a href={"#"} onClick={() => loginWithRedirect()}>Log in</a></li>
                    </ul>}
                    {isAuthenticated &&
                        <ul className="nav_actions" ref={createMenuRef}>
                            <li><Button className="border-none" variant="primary" onClick={() => {
                                setIsDropdownOpen((old) => !old);
                                // TODO dispatch for reset
                                // dispatch(resetProjectWizard());
                                // navigate("/project/new")
                            }}>
                                Create
                            </Button>
                                {isDropdownOpen &&
                                    <ul className="create_dropdown">
                                        {profile.user_type === USER_TYPES.RIGHTS_HOLDER.key && <li ><a className="text-black" href="/add-talent">Create talent</a></li>}
                                        {profile.user_type === USER_TYPES.CREATOR.key && <li><a className="text-black" href="/project/new">Create project</a></li>}
                                        {profile.roles.includes("AI_IMAGE_CREATOR") && <li><button className="text-black" onClick={() => setIsCreateModalOpen(true)}>Create image</button></li>}
                                    </ul>
                                }
                            </li>
                            <li><a href="#" onClick={handleLogout}>Log out</a></li>
                        </ul>
                    }
                </>
                }
            </header>
            <DialogModal
                isOpen={isCreateModalOpen}
                onOpenChange={setIsCreateModalOpen}
                onClose={() => { setIsCreateModalOpen(false) }}
                variant="large"
            >
                <div className="flex flex-row gap-16">
                    <img className="rounded-lg" src={bit_image} />
                    <div className="flex flex-col gap-9 justify-center">
                        <h1 className="text-5xl">Create image</h1>
                        <div className="flex flex-col gap-2">
                            <p className="italic text-xl">Start from a prompt</p>
                            <p>Start with a prompt and have us create your image</p>
                            <Button onClick={navigateToCreateImage} className="text-black inline-flex self-start hover:bg-black hover:text-white" variant="outline">Get started</Button>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="italic text-xl">Edit existing</p>
                            <p>Upload a starter image to edit directly</p>
                            <Button onClick={browseForStarterImage}
                                    className="text-black inline-flex self-start hover:bg-black hover:text-white"
                                    variant="outline"
                                    disabled={uploading}>{uploading ? "Uploading file ..." : "Upload starter image"}</Button>
                            <input
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                            />
                        </div>
                    </div>

                </div>
            </DialogModal>
        </div>
    )
}

export default PublicHeader;