import React from "react";
import { LicensableProperty } from "../../../model/licensable_property";
import Heading from "../../../common/components/heading";
import { SocialAccount } from "../../../common/components/social_account";
import upperFirst from "lodash/upperFirst";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty;
}

export const LPHeading = ({ licensableProperty }: LicensablePropertyProps) => {
  function goToSocialAccount(social: any) {
    let externalId = social.external_id;
    let url;

    if (externalId.includes("https://")) {
      url = externalId;
    } else {
      if (social.provider === "instagram") {
        externalId = externalId.replace("@", "");
      }
      url = `https://www.${social.provider}.com/${externalId}`;
    }
    window.open(url, "_blank");
  }

  return (
    <div className="w-full">
      <Heading as="h5" className="font-sans not-italic">
        {upperFirst(licensableProperty.primary_professions).replace("_", " ")}
      </Heading>
      <Heading as="h1" className="text-white">
        {licensableProperty.name}
      </Heading>
      <div className="flex flex-wrap justify-start gap-8">
        {licensableProperty?.social_accounts &&
          licensableProperty.social_accounts.map((social) => {
            return (
              <div key={social.followers} className="w-10 cursor-pointer" onClick={() => goToSocialAccount(social)}>
                <SocialAccount provider={social.provider} followers={social.followers} usage="profile" />
              </div>
            );
          })}
      </div>
    </div>
  );
};
