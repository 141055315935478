"use client";

import * as React from "react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "./base/accordion";

export function Faq({ faq }: any) {
  return (
    <Accordion type="multiple">
      {faq.map((item: any, idx: number) => {
        return (
          <AccordionItem value={`item-${idx}`} key={idx} className="border-0 mb-4">
            <AccordionTrigger className="bg-gray-900 px-6 py-6 rounded-xl font-sans text-base font-semibold">
              {item.question}
            </AccordionTrigger>
            <AccordionContent className="p-4 border-0 text-base">{item.answer}</AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
}
