import { random } from "lodash";
import React from "react";

const b4 = (
  <svg width="417" height="280" viewBox="0 0 417 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1335_86191)">
      <rect width="417" height="280" rx="20" fill="#141414" />
      <rect x="278" y="139" width="140" height="140" fill="#0C21FF" />
      <rect x="0.00976562" y="139" width="140" height="140.083" fill="#F378FF" />
      <rect x="139" y="139" width="140" height="140.083" fill="white" />
      <rect x="279" y="-1" width="70" height="140" transform="rotate(90 279 -1)" fill="#FD5939" />
      <rect x="279" y="69" width="70" height="140" transform="rotate(90 279 69)" fill="#FDFD43" />
    </g>
    <defs>
      <clipPath id="clip0_1335_86191">
        <rect width="417" height="280" rx="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const b3 = (
  <svg width="417" height="280" viewBox="0 0 417 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1335_86190)">
      <rect width="417" height="280" rx="20" fill="#141414" />
      <rect x="-2" y="140" width="141.472" height="140" fill="#0C21FF" />
      <rect x="280" width="140" height="140" fill="#FDFD43" />
      <rect x="139.921" width="46.6667" height="140" transform="rotate(90 139.921 0)" fill="#FDFD43" />
      <rect x="139.921" y="46.6667" width="46.6667" height="140" transform="rotate(90 139.921 46.6667)" fill="white" />
      <rect
        x="139.921"
        y="93.3333"
        width="46.6667"
        height="140"
        transform="rotate(90 139.921 93.3333)"
        fill="#F378FF"
      />
      <rect x="140" y="140" width="140" height="140" fill="white" />
      <rect x="420" y="140" width="70" height="140" transform="rotate(90 420 140)" fill="#FD5939" />
      <rect x="420" y="210" width="70" height="140" transform="rotate(90 420 210)" fill="#F378FF" />
    </g>
    <defs>
      <clipPath id="clip0_1335_86190">
        <rect width="417" height="280" rx="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const b2 = (
  <svg width="417" height="280" viewBox="0 0 417 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1335_86189)">
      <rect width="417" height="280" rx="20" fill="#141414" />
      <rect x="139" y="140" width="140" height="140" fill="#0C21FF" />
      <rect x="279" width="140" height="140" fill="#FD5939" />
      <rect width="140" height="140.083" fill="#FDFD43" />
      <rect x="279" y="140.066" width="46.6667" height="140" fill="white" />
      <rect x="325.667" y="140.066" width="46.6667" height="140" fill="#F378FF" />
      <rect x="372.333" y="140.066" width="46.6667" height="140" fill="#FDFD43" />
    </g>
    <defs>
      <clipPath id="clip0_1335_86189">
        <rect width="417" height="280" rx="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const b1 = (
  <svg width="417" height="280" viewBox="0 0 417 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1335_86188)">
      <rect width="417" height="280" rx="20" fill="#141414" />
      <rect x="280" y="-0.827393" width="140" height="140.083" fill="#0C0DFF" />
      <rect
        x="279.921"
        y="0.173218"
        width="46.6944"
        height="140"
        transform="rotate(90 279.921 0.173218)"
        fill="#141414"
      />
      <rect
        x="279.921"
        y="46.8676"
        width="46.6944"
        height="140"
        transform="rotate(90 279.921 46.8676)"
        fill="#F378FF"
      />
      <rect x="279.921" y="93.562" width="46.6944" height="140" transform="rotate(90 279.921 93.562)" fill="#FDFD43" />
      <rect x="140" y="140.256" width="140" height="140.083" fill="white" />
      <rect x="-0.125" y="0.0137939" width="140" height="140" fill="#FD5939" />
      <rect x="-0.125" y="140.014" width="140" height="140" fill="#FDFD43" />
    </g>
    <defs>
      <clipPath id="clip0_1335_86188">
        <rect width="417" height="280" rx="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const banners = [b1, b2, b3, b4];
export function BitBanner() {
  return (
    <div className="w-full">
      {banners[random(0, banners.length - 1)]}
    </div>
  );
}
