import React from "react";
import { ContentTopic, LicensableProperty } from "../../../model/licensable_property";
import { IconXMark, IconCheckMark } from "../../../common/icons/icons";
import { FIELDS } from "../../../lib/fields";
import Heading from "../../../common/components/heading";

interface LicensablePropertyProps extends React.HTMLAttributes<HTMLDivElement> {
  licensableProperty: LicensableProperty;
}

export const LPContentTopics = (props: LicensablePropertyProps) => {
  const topicAvailable = (contentTopic: ContentTopic) => {
    return props.licensableProperty?.content_topics?.find((topic: ContentTopic) => topic.name === contentTopic.value)
      ?.is_allowed;
  };

  return (
    <div className="flex flex-col gap-2">
      <Heading as="h5" className="text-center font-sans not-italic font-semibold">
        Open to content containing
      </Heading>
      <div className="flex flex-wrap gap-2 justify-center px-10">
        {FIELDS.CONTENT_TOPICS.options.map((topic) => {
          return (
            <div key={topic.label} className="text-xs flex items-center gap-1 font-semibold">
              {topicAvailable(topic) ? (
                <>
                  <IconCheckMark color="yellow" />
                  <span className="text-brand-yellow">{topic.label}</span>
                </>
              ) : (
                <>
                  <IconXMark />
                  {topic.label}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
