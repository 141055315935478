import React, { useEffect, useMemo, useState } from "react";
import Heading from "../../common/components/heading";
// import { useLicensablePropertyAPI } from "../../api/licensable_property_api";
import { Project, useProjectAPI } from "../../api/project_api";
import ProjectCard from "./components/project_card";
import { User } from "../../model/user";
import SubHeader from "../layouts/sub_header";
import { useSearchParams } from "react-router-dom";
import ConfirmationDialog from "../../common/components/ui/confirmation_modal";
import "./project.css";
function Projects({ user }: { user: User }) {
  const [projects, setProjects] = useState<Project[]>([]);
  const [submittedProjectName, setSubmittedProjectName] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [fetchCompleted, setFetchCompleted] = useState<boolean>(false);
  const { fetchProjects } = useProjectAPI();
  const [showLicenseApplicationSuccess, setShowLicenseApplicationSuccess] = useState<boolean>(false);

  const filteredProjects = useMemo(() => {
    return {
      draft: projects.filter((project) => project.status === "draft"),
      pending: projects.filter(
        (project) => project.status === "submitted_for_approval" || project.status === "changes_requested"
      ),
      licensed: projects.filter((project) => project.status === "approved"),
      denied: projects.filter((project) => project.status === "rejected"),
    };
  }, [projects]);

  useEffect(() => {
    fetchProjects().then((result) => {
      setProjects(result);
      setFetchCompleted(true);
      if (searchParams.get("submittedProject")) {
        const project = result.find((p) => p.id === searchParams.get("submittedProject"));
        if (project) {
          setSubmittedProjectName(project.name || "");
          setShowLicenseApplicationSuccess(true);
        }
      }
    });
  }, []);

  return (
    <div className="page_content">
      <SubHeader />
      <div className="page_header">
        <Heading as="h1" className="text-white mb-16">
          Projects
        </Heading>
        {projects.length === 0 && <div className="project_card">
          <div className="project_card_image">&nbsp;</div>
          <div className="project_card_content">&nbsp;</div>
        </div>}
        {filteredProjects.draft.length > 0 && <ProjectStatusGroup projects={filteredProjects.draft} title={"Draft"} />}
        {filteredProjects.pending.length > 0 && (
          <ProjectStatusGroup projects={filteredProjects.pending} title={"Pending"} />
        )}
        {filteredProjects.licensed.length > 0 && (
          <ProjectStatusGroup projects={filteredProjects.licensed} title={"Licensed"} />
        )}
        {filteredProjects.denied.length > 0 && (
          <ProjectStatusGroup projects={filteredProjects.denied} title={"Denied"} />
        )}
      </div>
      <ConfirmationDialog
        onOpenChange={setShowLicenseApplicationSuccess}
        isOpen={showLicenseApplicationSuccess}
        onClose={() => setShowLicenseApplicationSuccess(false)}
        preTitle={submittedProjectName ? `New Project: ${submittedProjectName}` : undefined}
        title="You have successfully applied for a license"
        hintText="We will let you know when it has been reviewed"
      />
    </div>
  );
}

const ProjectStatusGroup = ({ projects, title }: { projects: Project[]; title: string }) => {
  return (
    <div className="space-y-4 mb-16">
      <Heading as="h3" className="text-white mb-0">
        {title}
      </Heading>
      <div className="grid sm:grid-cols-3 gap-6">
        {projects.map((project: Project, idx: number) => {
          return (
            <div key={`${project.id}-${idx}`} className="group relative">
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden lg:aspect-none group-hover:opacity-85 ">
                <ProjectCard project={project} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Projects;
