import React, {useCallback, useEffect, useState} from "react";
import Heading from "../../common/components/heading";
import {Input} from "../../common/components/ui/input";
import {useForm} from "react-hook-form";
import {UploadedFile} from "../../api/file_api";
import FileManager from "../../common/components/ui/file_manager";
import {v4 as uuidv4} from "uuid";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import {Loader2} from "lucide-react";
import {useValidateContentApi, ValidationInfo} from "../../api/validate_api";
import axios from "axios";
import SubHeader from "../../pages/layouts/sub_header";
import {useParams} from "react-router-dom";

function validateURL(url: string) {
  if (
    url.length > 11 &&
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g.test(url)
  ) {
    return true;
  }
  return false;
}

function CheckLicense() {
  const form = useForm();
  const { id } = useParams<{ id: string }>();
  const uploadedFiles = form.watch("files");

  const [errors, setErrors] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();
  const [file, setFile] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [results, setResults] = useState<ValidationInfo | undefined>();

  const { validateContent } = useValidateContentApi();

  const submitContentForValidation = async () => {
    const fv = form.getValues();
    console.log("submitContentForValidation", fv);
    const res = await validateContent({ url });
    setResults(res);
  };
  const onSubmit = async (data: any) => {
    if(data.url){
      axios.post(`${process.env.REACT_APP_API_URL}/license/public/check-url`, {
        url: data.url
      }).then((response) => {
        if (response.status === 200) {
          setResults(response.data)
        } else {
          console.log("Error")
        }
      });
      }
    }

  useEffect(() => {
    if(id){
      axios.post(`${process.env.REACT_APP_API_URL}/license/public/check-url`, {
        licenseId: id
      }).then((response) => {
        if (response.status === 200) {
          setResults(response.data)
        } else {
          console.log("Error")
        }
      });
    }
  }, [id])
  const onFileUpload = (files: File[]) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    //Need to use axios instead of fetch to support progress events
    axios
        .post(`${process.env.REACT_APP_API_URL}/license/public/check-file`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setResults(response.data)
          } else {
            console.log("Error")
          }
        });
  };

  const onUploadedFilesChange = useCallback(
    (files: UploadedFile[]) => {
      form.setValue("files", files);
      const formData = form.getValues();
      console.log("formData", formData);

      // if (field && files[0]?.permalink) {
      //   form.setValue(field, files[0].permalink);
      // }
    },
    [form, uploadedFiles]
  );

  const onFileChange = useCallback(
    (files: File[]) => {
      if (files) {
        console.log("🚨 onFileChange files", files);
        form.setValue("files", files);
      }
    },
    [form, uploadedFiles]
  );

  const onFileRemove = (file: any) => {
    form.setValue(
      "files",
      uploadedFiles.filter((f: UploadedFile) => f.name !== file.name)
    );
  };

  const handleOnKeyDown = (e: any) => {
    setResults(undefined);
    if (e.keyCode === 13 && url && validateURL(url)) {
      form.setValue("validateUrl", url);
      setSubmitting(true);
      const t = setTimeout(() => {
        submitContentForValidation();
        setSubmitting(false);
        clearTimeout(t);
      }, 3000);
    }
  };

  return (
      <div className="page_content">
        <SubHeader />
        <div className="page_header text-white">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="space-y-16 max-w-[984px]">
            <div>
              <h1 className="text-xxxxxl-serif mb-4">{
                !results ? "Is it Official?" : results.status === "LICENSED" || results.status === "EXPIRED" ? "It's Official" : "It's unofficial"
              }</h1>
              <p className="text-s-sans text-normal">
                Add URL to AI content in inquiry or upload content below to validate against our database of licensed
                content.{" "}
              </p>
            </div>
            {results && (
              <div>
                <div className="grid sm:grid-cols-2 grid-cols-1">
                  <div className="space-y-10">
                    <div className="space-y-4">
                      <Heading as="h6" className="text-base">
                        Licensed to
                      </Heading>
                      <div>{results.license_to}</div>
                    </div>
                    <div className="space-y-4">
                      <Heading as="h6" className="text-base">
                        Licensed type
                      </Heading>
                      <div>{results.license_type}</div>
                    </div>
                  </div>
                  <div className="space-y-10">
                    <div className="space-y-4">
                      <Heading as="h6" className="text-base">
                        Validity
                      </Heading>
                      <div>
                        {`Before ${results.validity_not_before} Not after ${results.validity_not_after}`}
                      </div>
                    </div>
                    <div className="space-y-4">
                      <Heading as="h6" className="text-base">
                        Finger print
                      </Heading>
                      <div className="break-words">{results.hash}</div>
                    </div>
                  </div>
                </div>
                <div className="my-8">
                  <Heading as="h6" className="text-base">
                    Description
                  </Heading>
                  <p>{
                      results.status === "C2PA_UNKNOWN" ? "C2PA header found, but not licensed through Official AI" : results.description
                  }</p>
                </div>
                {results.samples && (
                  <div className="mt-12">
                    <Heading as="h6" className="text-base">
                      Sample content
                    </Heading>
                    <div className="flex flex-row flex-grow items-top gap-3 pt-4">
                      {results.samples.map((url) => {
                        return <img src={url} className="rounded-lg max-h-48" key={url} />;
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div>
              <h4 className="text-l-sans  mb-[32px]">
                Link to content
              </h4>
              <div className="relative flex flex-row items-center">
                <Input
                  placeholder="Ex: https://www.youtube.com"
                  className="text-l-serif p-0 w-full placeholder:italic placeholder:text-white/10"
                  // onChange={(e) => setUrl(e.target.value)}
                  // onKeyDown={(e) => {
                  //   handleOnKeyDown(e);
                  // }}
                  disabled={submitting}
                  readOnly={submitting}
                  {...form.register("url")}
                />
                {submitting ? (
                  <Loader2 className="absolute -right-1 top-2 h-10 w-10 animate-spin stroke-brand-yellow" />
                ) : (
                  <button type="submit">
                    <ArrowRightIcon
                    height={24}
                    opacity={url && validateURL(url) ? "100%" : "30%"}
                    className={"absolute -right-2 top-0 }"}
                  /></button>
                )}
              </div>
              <div className="flex flex-col justify-left pt-20 gap-3 overflow-hidden">
                <h4 className="text-l-sans mb-[32px]">
                  Upload content
                </h4>
                <FileManager
                  multiple={false}
                  objectType="project"
                  objectId={uuidv4()}
                  uploadOnSelect={false}
                  isPublic={false}
                  onUploadedFilesChange={onUploadedFilesChange}
                  onFileChange={onFileUpload}
                  theme="dark"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      </div>
  );
}

export default CheckLicense;
