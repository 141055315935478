import * as React from "react";

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "./base/select";

interface SelectDropdownOptions {
  field: any; // todo: proptype
  placeholder?: string;
  variant?: string;
  options: { value: string | null; label: string }[];
  as?: "button" | "default";
}

export function SelectDropdown({ field, as, options, placeholder, variant = "default" }: SelectDropdownOptions) {
  const variantClassName: any = {
    button:
      "inline-flex items-center justify-center whitespace-nowrap ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-gray-600 bg-none hover:bg-accent text-white hover:text-accent-foreground h-14 rounded-full px-8 text-lg font-extrabold",
    default:
      "w-full pl-0 border-0 border-b-2 border-gray-500 rounded-none h-12 text-white placeholder:text-primary-foreground bg-transparent",
  };

  return (
    <Select onValueChange={field.onChange} defaultValue={field.value}>
      <SelectTrigger className={variant === "button" ? variantClassName.button : variantClassName.default}>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map((option: any) => {
            return (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
