import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ProjectSaveRequest} from "../../api/project_api";
import {LicensableProperty} from "../../model/licensable_property";

export interface ProjectWizardState {
  project: ProjectSaveRequest;
  activePanel: number;
  talent: LicensableProperty | null
}

const initialState: ProjectWizardState = {
  project: { duration: 30, start_date: new Date() },
  activePanel: 0,
  talent: null
};

export const projectWizardSlice = createSlice({
  name: "projectWizard",
  initialState,
  reducers: {
    updateProject(state, action: PayloadAction<ProjectSaveRequest>) {
        state.project = action.payload;
    },
    setProjectField(state, action: PayloadAction<{ field: string, value: any }>) {
      state.project = {...state.project, [action.payload.field]: action.payload.value};
    },
    setActivePanel: (state, action: PayloadAction<number>) => {
      state.activePanel = action.payload;
    },
    setTalent: (state, action: PayloadAction<LicensableProperty>) => {
      state.talent = action.payload;
    },
    resetProjectWizard: (state) => {
      state.project = initialState.project;
      state.activePanel = initialState.activePanel;
      state.talent = initialState.talent;
    }

  },
});

export const { updateProject, setActivePanel, resetProjectWizard } = projectWizardSlice.actions;
//
// export const project = (state: RootState) => state.projectWizard.project;
// export const activePanel = (state: RootState) => state.projectWizard.activePanel;

export default projectWizardSlice.reducer;
