import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

export const Errors = ({ errors }: { errors: any }) => {
  return (
    <div>
      {Object.keys(errors).map((key) => (
        <div key={`error-${key}`}>
          <div className="text-destructive my-4 flex justify-start gap-1 items-center">
            <ExclamationCircleIcon height="18" />
            {errors[key]}
          </div>
        </div>
      ))}
    </div>
  );
};
