/* eslint-disable react/prop-types */
import * as React from "react";

import { cn } from "../../../lib/utils";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        "peer block w-full bg-transparent py-3 text-white border-0 border-b-2 focus:border-brand-yellow focus:ring-0 focus:ring-offset-0 sm:leading-6 outline-none",
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
