import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useAppSelector} from "../hooks";
import {useNavigate} from "react-router-dom";
import HomePage from "./home/home.tsx";

export default function Root() {
    const {isAuthenticated, loginWithRedirect} = useAuth0();
    const {status, profile} = useAppSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            // loginWithRedirect();
        }
        // TODO: We need a "isLoading" for the user state
        if (!profile.id) return;
        // TODO: enum profile status
        if (["uninitialized", "unregistered", "onboarding","unknown"].includes(status)) {
            // TODO: enum routes and/or ClientRouteBuilder
            navigate("/register");
        } else {
            // navigate("/dashboard");
        }
    }, [status]);

    return <HomePage/>;
}
