import React, { useState } from "react";
import ImageCard from "./image_card";
import { GenerativeModel } from "../models/image_generator";
import cn from "../../../lib/utils";
import { IconCheckMark, EmptyCircleIcon } from "../../../common/icons/icons";

const ModelSelect = ({ modelList, handleModelSelect, selectedModel }: { modelList?: any, handleModelSelect: any, selectedModel: any }) => {
    const [searchText, setSearchText] = useState<string>("");

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    return (
        <div>
            {/* <input
                className={cn("wizard-text-input focus:border-b-brand-yellow", "text-black")}
                style={{ maxWidth: "1160px" }}
                type="text"
                placeholder="Search by keyword"
                aria-label="Search by keyword"
                value={searchText}
                onChange={handleSearchChange}
                // TODO: Make sure to change PT-52 when search reimplemented
            /> */}
            <div className={`flex ${modelList.length < 4 ? "gap-4" : "-mx-20 md:-mx-[120px] flex-wrap"} pt-[12px]`}>
                {modelList.map((image: GenerativeModel) => (
                    <ImageCard key={image.id} imageModel={image}
                        imageAction={() => handleModelSelect(image)}
                        icon={selectedModel?.id === image.id ? <IconCheckMark /> : <EmptyCircleIcon />}
                        selected={selectedModel?.id === image.id} isCompactView={modelList.length < 4} />
                ))}
            </div>
        </div>
    )

}

export default ModelSelect;