"use client";

import * as React from "react";

export function Avatar({ url, name, size }: { url?: string | undefined; size?: string; name?: string }) {
  if (name) {
    const parts = name.split(" ");
    const f = parts[0].split("")[0].toUpperCase();
    let l = f;
    if (parts[1]) {
      l = parts[1].split("")[0].toUpperCase();
    }

    return (
      <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
        <span className="text-lg font-medium leading-none text-white">
          {f}
          {l}
        </span>
      </span>
    );
  }
  if (url) {
    return <img src={url} className="h-16 w-16 rounded-full" alt={`avatar for ${url}`} />;
  }
  return null;
}
