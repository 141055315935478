import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Project, useProjectAPI } from "../../../api/project_api";
import Heading from "../../../common/components/heading";
import { User } from "../../../model/user";
import { PROJECT_STATUS, USER_TYPES } from "../../../lib/constants";
import { Button } from "../../../common/components/ui/button";
import { DialogModal } from "../../../common/components/ui/dialog_modal";
import { useToast } from "../../../common/components/ui/use_toast";
import { projectLicensePrice } from "../project_review";
import {BitBanner} from "../../../common/components/bit_banner";
import {resetProjectWizard} from "../../../pages/project/projectWizardSlice";
import {useAppDispatch} from "../../../hooks";

const ProjectActionsCreator = ({ project, user, onUpdate }: { project: Project; user: User; onUpdate: any }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string | null>(null);
  const [messageText, setMessageText] = useState<string | null>(null);
  const { toast } = useToast();
  const projectApi = useProjectAPI();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isCreator = user.user_type === USER_TYPES.CREATOR.key;
  if (!isCreator) return null;

  const handleLicenseRequest = async (status: string) => {
    setSubmitting(true);
    let systemMessage = "";
    switch (status) {
      case PROJECT_STATUS.DRAFT.key:
        systemMessage = "License request was cancelled by the creator";
        break;
      case PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key:
        systemMessage = "Project submitted for license approval";
        break;
      default:
        break;
    }
    const res = await projectApi.updateProjectStatus(
      project,
      status,
      `${systemMessage}${messageText ? `: ${messageText}` : ""}`
    );
    if (res) {
      setMessageText("");
      setSubmitting(false);
      onUpdate({ status: status });
      toast({
        title: "Request submitted",
        description: "Project has been submitted for approval. You will be notified when it is approved.",
      });
      setModalOpen(false);
    }else{
        setSubmitting(false);
        toast({
            title: "Request failed",
            description: "There was an error submitting your request. Please try again.",
        });
    }
  };

  const modalContent: any = {
    request: {
      body: (
        <div className="space-y-6 w-full">
          <div className="mt-4">
            <BitBanner/>
          </div>
          <div>
            <Heading as="h3">Submit for approval</Heading>
            <p>
              You are about to submit this project for approval for use of{" "}
              {project.licensable_properties?.map((lp) => lp.name).join(", ")} for{" "}
              {projectLicensePrice(project)}.
            </p>
          </div>
        </div>
      ),
      actions: [
        <Button key="cancel-button" variant="outline-negative" loading={submitting} onClick={() => setModalOpen(false)}>
          Cancel
        </Button>,
        <Button
          key="continue-button"
          variant="primary-negative"
          disabled={submitting}
          onClick={() => handleLicenseRequest(PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key)}
        >
          Submit request
        </Button>,
      ],
    },
    download: {
      body: (
          <div className="space-y-6">
            <div className="mt-4">
              <BitBanner/>
            </div>
            <div>
              <Heading as="h3">Download training data</Heading>
              <p>You have not been charged because your license is still pending</p>
            </div>
          </div>
      ),
      actions: [
        <Button
            key="continue-button"
          variant="outline-negative"
          disabled={submitting}
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </Button>,
      ],
    },
    cancel: {
      body: (
          <div className="space-y-6">
            <div className="mt-4">
              <BitBanner/>
            </div>
            <div>
              <Heading as="h3">Cancel license request?</Heading>
              <p>You are about to cancel this license request. Are you sure?</p>
            </div>
          </div>
      ),
      actions: [
        <Button
            key="continue-button"
          variant="outline-negative"
          disabled={submitting}
          onClick={() => setModalOpen(false)}
        >
          Cancel
        </Button>,
        <Button
          key="cancel-button"
          variant="primary-negative"
          loading={submitting}
          onClick={() => handleLicenseRequest(PROJECT_STATUS.DRAFT.key)}
        >
          Yes, cancel request
        </Button>,
      ],
    },
  };

  return (
    <>
      {(project.status === PROJECT_STATUS.DRAFT.key || project.status === PROJECT_STATUS.CHANGES_REQUESTED.key) && (
        <Button variant="secondary" onClick={() => {
          dispatch(resetProjectWizard());
          navigate(`/project/${project.id}/edit`)
        }}>
          Make changes
        </Button>
      )}
      {project.status === PROJECT_STATUS.CHANGES_REQUESTED.key && (
        <Button
          variant="ghost"
          onClick={() => {
            setContent("cancel");
            setModalOpen(!modalOpen);
          }}
        >
          Cancel request
        </Button>
      )}
      {project.status === PROJECT_STATUS.SUBMITTED_FOR_APPROVAL.key && (
        <>
          <Button disabled variant="secondary">
            Pending license approval
          </Button>
          {/*<Button*/}
          {/*  variant="outline"*/}
          {/*  onClick={() => {*/}
          {/*    setContent("download");*/}
          {/*    setModalOpen(!modalOpen);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Select + download training data*/}
          {/*</Button>*/}
          <Button
            variant="ghost"
            onClick={() => {
              setContent("cancel");
              setModalOpen(!modalOpen);
            }}
          >
            Cancel request
          </Button>
        </>
      )}
      {content && modalContent[content] && (
        <DialogModal
          isOpen={modalOpen}
          onOpenChange={setModalOpen}
          onClose={() => setModalOpen(false)}
          actions={modalContent[content].actions}
        >
          {modalContent[content].body}
        </DialogModal>
      )}
    </>
  );
};

export default ProjectActionsCreator;
